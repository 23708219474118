import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ap-ui-inline-alert',
  templateUrl: './ui-inline-alert.component.html',
  styleUrls: ['./ui-inline-alert.component.scss']
})
export class UiInlineAlertComponent {
  @Input() type: 'error' | 'success' | 'warning_blue' | 'warning_yellow' | 'warning_gray' | string | null = 'success';
  @Input() title!: string | null;
  @Input() subTitle!: string | null;
  @Input() description!: string | null | SafeHtml;
  @Input() hide_close_button: boolean = false;
  @Input() hideIcon: boolean = false;
  @Input() less_yAxis_padding: boolean = false;
  @Input() action_on_right: boolean = false;
  @Input() nowrapTitle: boolean = false;
  @Input() actionText: string = '';
  @Input() icon_size: number = 18;
  @Output() close = new EventEmitter<boolean>(false);
  @Output() action = new EventEmitter<boolean>(false);

  get alert_color(): string {
    let color = '';

    switch(this.type) {
      case 'error':
        color = 'var(--scarlet-100)';
      break;

      case 'warning_blue':
        color = 'var(--radiance-100)';
      break;

      case 'warning_yellow':
        color = 'var(--amber-100)';
      break;

      case 'success':
        color = 'var(--malachite-100)';
      break;

      case 'warning_gray':
        color = 'var(--black)';
      break;
    }

    return color;
  }

  get background_alert_color(): string {
    let color = '';

    switch(this.type) {
      case 'error':
        color = 'var(--scarlet-10)';
      break;

      case 'warning_blue':
        color = 'var(--radiance-10)';
      break;

      case 'warning_yellow':
        color = 'var(--amber-10)';
      break;

      case 'success':
        color = 'var(--malachite-10)';
      break;

      case 'warning_gray':
        color = 'var(--black)';
      break;
    }

    return color;
  }

  constructor() { }
}
