import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule} from '@angular/fire/compat'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PlatformModule } from '@angular/cdk/platform';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment.local';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/chronos';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxIndexedDBModule } from 'ngx-indexed-db';

import { SharedModule } from './shared/shared.module';
import { AuthGuardService } from './modules/auth/guards/auth.guard';
import { LoggedGuardService } from './modules/auth/guards/logged.guard';
import { ErrorsInterceptor } from './shared/interceptors/errors.interceptor';
import { AuthInterceptor } from './modules/auth/services/auth.interceptor';
import { WelcomePageGuardService } from './shared/guards/welcome-page.guard';
import { ShipmentsGuard } from './shared/guards/shipments.guard';
import { InvoicesGuard } from './shared/guards/invoices.guard';
import { ManagePermissionsGuard } from './shared/guards/manage-permissions.guard';
import { PriceGroupsGuard } from './shared/guards/price-groups.guard';
import { AdminAndCustomerSupportGuardService } from './modules/auth/guards/customer-support.guard';
import { GraphQLModule } from './graphql/graphql.module';
import { dbConfig } from './shared/const/indexed-db.config';

export let AppInjector: Injector;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

defineLocale('en-gb', enGbLocale);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    BrowserModule,
    PlatformModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(document.URL.includes('app.profrakt.no') ? 'GTM-M288G3C' : 'GTM-PN2GWNC'),
    GraphQLModule,
    NgxIndexedDBModule.forRoot(dbConfig)
  ],
  providers: [
    LoggedGuardService,
    AuthGuardService,
    AdminAndCustomerSupportGuardService,
    WelcomePageGuardService,
    ShipmentsGuard,
    InvoicesGuard,
    ManagePermissionsGuard,
    PriceGroupsGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
