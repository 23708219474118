import { Component, EventEmitter, forwardRef, ViewEncapsulation, OnInit } from '@angular/core';
import { HostBinding, Input, Output } from '@angular/core';

import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ap-ui-slide-toggle',
  templateUrl: './ui-slide-toggle.component.html',
  styleUrls: ['./ui-slide-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSlideToggleComponent),
      multi: true,
    },
  ],
})
export class UiSlideToggleComponent implements OnInit, ControlValueAccessor {
  inputControl: UntypedFormControl = new UntypedFormControl(false);
  id: string = '';

  @Input() label = '';
  @Input() labelPlacement: 'left' | 'right' = 'right';
  @Input() disabled = false;
  @Input() set value(value: boolean) {
    this.inputControl.setValue(value);
  };

  @Output() slideToggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.slide-toggle--disabled')
  get disabledClass(): boolean {
    return this.disabled;
  }

  @HostBinding('class.slide-toggle--without-label')
  get withoutLabelsClass(): boolean {
    return !this.label
  }

  ngOnInit(): void {
    this.id = this.uuidv4();
  }

  onChange: any = () => {};

  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  toggleSlider(): void {
    this.onChange(!this.inputControl.value);
    this.slideToggleChange.emit(!this.inputControl.value);
  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
