import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ErrorsService {
  _authError: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  _existenceError: BehaviorSubject<HttpErrorResponse | null> = new BehaviorSubject<HttpErrorResponse | null>(null);

  constructor() {}

  setAuthError(error: string | null): void {
    this._authError.next(error);
  }

  authError(): any {
    return this._authError.asObservable();
  }

  setIntegrationError(error: HttpErrorResponse | null): void {
    this._existenceError.next(error);
  }

  existenceError(): Observable<HttpErrorResponse | null> {
    return this._existenceError.asObservable();
  }
}
