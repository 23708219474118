import { DBConfig } from 'ngx-indexed-db';
import { TranslateSectionsEnum } from 'src/app/modules/superadmin/manage-translates/enums/translates-section.enum';

export const dbConfig: DBConfig  = {
  name: 'AdminPanelDB',
  version: 1,
  objectStoresMeta: [{
    store: 'translates',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: TranslateSectionsEnum.APP, keypath: TranslateSectionsEnum.APP, options: { unique: false } },
      { name: TranslateSectionsEnum.AUTH, keypath: TranslateSectionsEnum.AUTH, options: { unique: false } },
      { name: TranslateSectionsEnum.CONTACT_BOOK, keypath: TranslateSectionsEnum.CONTACT_BOOK, options: { unique: false } },
      { name: TranslateSectionsEnum.CUSTOMER_SERVICE, keypath: TranslateSectionsEnum.CUSTOMER_SERVICE, options: { unique: false } },
      { name: TranslateSectionsEnum.DASHBOARD, keypath: TranslateSectionsEnum.DASHBOARD, options: { unique: false } },
      { name: TranslateSectionsEnum.INVOICES, keypath: TranslateSectionsEnum.INVOICES, options: { unique: false } },
      { name: TranslateSectionsEnum.ITEM_INVENTORY, keypath: TranslateSectionsEnum.ITEM_INVENTORY, options: { unique: false } },
      { name: TranslateSectionsEnum.MANAGE_CLIENTS, keypath: TranslateSectionsEnum.MANAGE_CLIENTS, options: { unique: false } },
      { name: TranslateSectionsEnum.MANAGE_PERMISSIONS, keypath: TranslateSectionsEnum.MANAGE_PERMISSIONS, options: { unique: false } },
      { name: TranslateSectionsEnum.MANAGE_PRINTERS, keypath: TranslateSectionsEnum.MANAGE_PRINTERS, options: { unique: false } },
      { name: TranslateSectionsEnum.MANAGE_PROFILE, keypath: TranslateSectionsEnum.MANAGE_PROFILE, options: { unique: false } },
      { name: TranslateSectionsEnum.MANAGE_USERS, keypath: TranslateSectionsEnum.MANAGE_USERS, options: { unique: false } },
      { name: TranslateSectionsEnum.ONBOARDING, keypath: TranslateSectionsEnum.ONBOARDING, options: { unique: false } },
      { name: TranslateSectionsEnum.ORDERS, keypath: TranslateSectionsEnum.ORDERS, options: { unique: false } },
      { name: TranslateSectionsEnum.PRODUCTS, keypath: TranslateSectionsEnum.PRODUCTS, options: { unique: false } },
      { name: TranslateSectionsEnum.SALES_ORDERS, keypath: TranslateSectionsEnum.SALES_ORDERS, options: { unique: false } },
      { name: TranslateSectionsEnum.SETTINGS, keypath: TranslateSectionsEnum.SETTINGS, options: { unique: false } },
      { name: TranslateSectionsEnum.SHIPMENTS, keypath: TranslateSectionsEnum.SHIPMENTS, options: { unique: false } }
    ]
  }]
};