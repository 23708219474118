import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { NgSelectComponent } from '@ng-select/ng-select';

import { TableItemInterface } from '../../interfaces/table-item.interface';
import { TableStatusInterface } from '../../interfaces/table-status.interface';
import { TableItemActionInterface } from '../../interfaces/table-item-action.interface';
import { CellActionEmitDataInterface } from '../../interfaces/cell-action-emit-data.interface';

@Component({
  selector: 'ap-ui-table-cell',
  templateUrl: './ui-table-cell.component.html',
  styleUrls: ['./ui-table-cell.component.scss']
})
export class UiTableCellComponent implements OnInit {

  @Input() tableItem!: TableItemInterface
  @Input() item!: any
  @Input() statuses: TableStatusInterface[] = [];
  @Input() isItemClickHandled: boolean = false;

  @Output() focusOut: EventEmitter<{item: any, databaseName: string, value: any}> = new EventEmitter<{item: any, databaseName: string, value: any}>()
  @Output() cellActionEmitter: EventEmitter<CellActionEmitDataInterface> = new EventEmitter<CellActionEmitDataInterface>()
  @Output() onClick: EventEmitter<{tableItem: TableItemInterface, item: any}> = new EventEmitter<{tableItem: TableItemInterface, item: any}>()

  @ViewChild('input', {static: false}) input!: ElementRef<HTMLInputElement>
  @ViewChild('inputWithCustom', {static: false}) inputWithCustom!: ElementRef<HTMLInputElement>
  @ViewChild('select', {static: false}) select!: NgSelectComponent

  editMode: boolean = false
  cellFormControl: FormControl = new FormControl()
  activeStatus!: TableStatusInterface

  constructor(private cdr: ChangeDetectorRef) {}

  get actionListForTableCell(): TableItemActionInterface[] {
    return this.tableItem.actions ? this.tableItem.actions.filter(action => !(action.hided && action.hided(this.item))) : []
  }

  ngOnInit(): void {
    this.initActiveStatus();
    this.cellFormControl.setValue(this.item[this.tableItem.databaseName]);
    this.cellFormControl.setValidators(this.tableItem.validators ? this.tableItem.validators : []);
  }

  emitFocusOutEvent(): void {
    if (this.cellFormControl.valid) {
      const emitData = {
        item: this.item,
        databaseName: this.tableItem.databaseName,
        value: (this.tableItem.type === 'edit' || this.tableItem.type === 'select') ? this.cellFormControl.value : this.activeStatus.databaseName
      }
      this.focusOut.emit(emitData)
      this.item[this.tableItem.databaseName] = this.cellFormControl.value
    }
    this.editMode = false;
  }

  activateEditMode(event: Event): void {
    event.preventDefault()
    event.stopImmediatePropagation()
    if (this.tableItem.type === 'edit') {
      this.editMode = true
      this.cdr.detectChanges()
      this.tableItem.customTemplate ? this.inputWithCustom.nativeElement.focus() : this.input.nativeElement.focus()
    } else if (this.tableItem.type === 'status-edit') {
      this.editMode = !this.editMode
    } else if (this.tableItem.type === 'select') {
      this.editMode = true
      this.cdr.detectChanges()
      this.select.open()
    } else {
      return
    }
  }

  emitCellAction(action: TableItemActionInterface, event: Event): void {
    event.preventDefault()
    event.stopImmediatePropagation()
    const emitData = {
      item: this.item,
      databaseName: this.tableItem.databaseName,
      action
    }
    this.cellActionEmitter.emit(emitData)
  }

  getActionTooltip(action: TableItemActionInterface): string {
    if (action.tooltip) {
      if (typeof action.tooltip === 'string') {
        return action.tooltip
      } else {
        return action.tooltip(this.item)
      }
    } else {
      return ''
    }
  }

  emitClickEvent($event: MouseEvent) {
    if (($event.target! as any).classList[0] === 'ng-option-label') {
      return
    }
    this.onClick.emit({tableItem: this.tableItem, item: this.item})
  }

  selectActiveStatus(status: TableStatusInterface, $event: Event): void {
    $event.stopPropagation()
    $event.preventDefault()
    this.activeStatus = status
    this.emitFocusOutEvent()
  }

  private initActiveStatus(): void {
    if (this.tableItem.type === 'status' || this.tableItem.type === 'status-edit') {
      this.activeStatus = this.statuses.find(status => {
        return status.databaseName === this.item[this.tableItem.databaseName]
      })!
    }
  }
}
