import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EMPTY, Observable, mergeMap } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'src/environments/environment';
import { fileHeaders, headers } from '../../shared/helpers/headers.helper';
import { TranslateSectionsEnum } from 'src/app/modules/superadmin/manage-translates/enums/translates-section.enum';
import { TranslationLoaderService } from 'src/app/shared/services/translation-loader.service';
import { TranslateResponseInterface } from './interfaces/translate-response.interface';
import { IndexedDbTranslateResponseInterface } from 'src/app/shared/interfaces/indexeddb-translate-response.interface';

@Injectable({
  providedIn: 'root'
})

export class AdminpanelTranslateService {
  private url = environment.baseUrl + '/profrakt';
  private lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'no' ;

  constructor(private http: HttpClient,
              private translationLoaderService: TranslationLoaderService,
              private dbService: NgxIndexedDBService,
              private translateService: TranslateService) {}

  getTranslate(): Observable<TranslateResponseInterface[]> {
    return this.http.get<TranslateResponseInterface[]>(`${this.url}/translate`, { headers: headers()});
  }

  updateTranslate(id: string, body: FormData): Observable<TranslateResponseInterface> {
    return this.http.patch<TranslateResponseInterface>(`${this.url}/translate/${ id }/`, body, { headers: fileHeaders() });
  }

  addTranslate(body: FormData): Observable<TranslateResponseInterface> {
    return this.http.post<TranslateResponseInterface>(`${this.url}/translate/`, body, { headers: fileHeaders() });
  }

  getTranslationByService(section: TranslateSectionsEnum): void {
    this.dbService.getAll('translates').pipe(
      mergeMap((trnslt: IndexedDbTranslateResponseInterface[] | unknown) => {
        let translates = (trnslt as IndexedDbTranslateResponseInterface[])!.find((item: IndexedDbTranslateResponseInterface) => item.name === section) ; 
        
        if (!translates) {
          return this.http.get<TranslateResponseInterface[]>(this.url + `/translate/?section=${ section }`)
        }

        const en_file = translates.value.find((item: TranslateResponseInterface) => item.language === 'en')!.file;
        const no_file = translates.value.find((item: TranslateResponseInterface) => item.language === 'no')!.file;
        
        const en = JSON.parse(en_file);
        const no = JSON.parse(no_file);

        this.translationLoaderService.loadTranslations(en, no);
        this.setUpLanguage();

        return EMPTY;
      }),
      mergeMap((res: TranslateResponseInterface[]) => {
        if (res) {
          const en_file = res.find((item: TranslateResponseInterface) => item.language === 'en')!.file;
          const no_file = res.find((item: TranslateResponseInterface) => item.language === 'no')!.file;
          
          const en = JSON.parse(en_file);
          const no = JSON.parse(no_file);
          this.translationLoaderService.loadTranslations(en, no)
          this.setUpLanguage();
          return this.dbService.add('translates', {name: section, keyframes: section, value: res })
        }

        return EMPTY
      })
    ).subscribe()
  }

  private setUpLanguage(): void  {
    this.translateService.setDefaultLang(this.lang!);
    this.translateService.use(this.lang!);
  }
}