import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationsHelpClass } from '../../helpers/notifications-helper.class';
import { Notification, NotificationType, ShipmentReportMesssages } from '../../models/notifications.model';
import { NotificationsService } from 'src/app/services/notifications-service/notifications.service';

@Component({
  selector: 'ap-header-notification-item',
  templateUrl: './header-notification-item.component.html',
  styleUrls: ['./header-notification-item.component.scss']
})
export class HeaderNotificationItemComponent extends NotificationsHelpClass {
  @Output() onShowNotifications: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() notificationsCounterToEmit: EventEmitter<number> = new EventEmitter<number>();
  @Output() notificationIdToRemove: EventEmitter<string> = new EventEmitter<string>();
  NotificationType = NotificationType;
  ShipmentReportMesssages = ShipmentReportMesssages;
  shipmentReportMesssage!: ShipmentReportMesssages | string;
  notification!: Notification;
  @Input() set notificationData(notification: Notification) {
    this.notification = notification;

    if (this.notification.type === NotificationType.PACKAGES_REPORT) {
      this.shipmentReportMesssage = notification.message;
    }
  };

  constructor(private router: Router,
              notificationsService: NotificationsService) { 
                super(notificationsService);
              }

  navigateToObject(): void {
    this.markNotificationAsRead(this.notification.id);
    this.markNotificationAsReadInList(this.notification.id);
    this.router.navigate([this.navigationRouteFromNotification(this.notification)], { queryParams: { ticket: this.notification.link_id }});
    this.onShowNotifications.emit(false);
  }

  markNotificationAsReadInList(id: string): void {
    this.notificationsCounterToEmit.emit(this.notificationsCounter - 1);
    this.notificationIdToRemove.emit(id);
  }

  

  createTicketForFailedReport(): void {

  }

  notificationClick(): boolean {
    if (this.notification.link_id && this.notification.type !== NotificationType.PACKAGES_REPORT) {
      this.navigateToObject();
      return true;
    }

    if (this.notification.file_url && 
        this.notification.type === NotificationType.PACKAGES_REPORT && 
        this.shipmentReportMesssage === ShipmentReportMesssages.PACKAGES_REPORT) {
      this.downloadFile(this.notification);
      return true;
    }

    return false;
  }
}
