<div *ngFor="let row of block.repeat | iterableNumber; let even = even" class="block" [ngStyle]="{
  'border-bottom': block.border?.bottom?.width + ' ' + block.border?.bottom?.color + ' ' + block.border?.bottom?.style,
  'border-right': block.border?.right?.width + ' ' + block.border?.right?.color + ' ' + block.border?.right?.style,
  'border-left': block.border?.left?.width + ' ' + block.border?.left?.color + ' ' + block.border?.left?.style,
  'border-top': block.border?.top?.width + ' ' + block.border?.top?.color + ' ' + block.border?.top?.style,
  'border-radius':block.borderRadius,

  'justify-content': block.flex?.justifyContent,
  'flex-direction': block.flex?.direction,
  'align-items': block.flex?.alignItems,

  'padding-bottom': block.padding?.bottom,
  'padding-right': block.padding?.right,
  'padding-left': block.padding?.left,
  'padding-top': block.padding?.top,

  'margin-bottom': block.margin?.bottom,
  'margin-right': block.margin?.right,
  'margin-left': block.margin?.left,
  'margin-top': block.margin?.top,

  'max-height': block.height?.max,
  'min-height': block.height?.min,
  'height': block.height?.value,

  'background-color': even ? block.even?.backgroundColor : ''
}">

  <ng-container *ngFor="let item of block.elementList">
    <ap-ui-block *ngIf="item.type === placeholderLoaderTypeEnum.BLOCK" [block]="item"></ap-ui-block>
    <ap-ui-circle *ngIf="item.type === placeholderLoaderTypeEnum.CIRCLE" [circle]="item"></ap-ui-circle>
    <ap-ui-rectangle *ngIf="item.type === placeholderLoaderTypeEnum.RECTANGLE" [rectangle]="item"></ap-ui-rectangle>
  </ng-container>

</div>
