import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';

import { ButtonToggleGroupDirective, CS_BUTTON_TOGGLE_GROUP } from '../../../directives/button-toggle.directive';
import { ButtonToggleSizeEnum } from './enums/button-toggle-size.enum';

@Component({
  selector: 'ap-ui-button-toggle',
  templateUrl: './ui-button-toggle.component.html',
  styleUrls: ['./ui-button-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiButtonToggleComponent implements OnInit {

  name = `cs-button-toggle-${UiButtonToggleComponent.buttonToggleId++}`;
  buttonToggleGroup: ButtonToggleGroupDirective;
  isHovered: boolean = false;

  private static buttonToggleId = 0;

  @Input() value: any;
  @Input() buttonSize: keyof typeof ButtonToggleSizeEnum = 'small';
  @Input() disabled: boolean = false;

  @HostBinding('class.button-toggle__item-hovered')
  get hoveredButtonClass(): boolean {
    return this.isHovered;
  }

  @HostBinding('class.button-toggle__item-active')
  get toggleButtonActive(): boolean {
    return this.isItemActive;
  }

  @HostBinding('class.button-toggle__item-disabled')
  get toggleButtonDisabled(): boolean {
    return this.disabled;
  }

  @HostBinding('class')
  get toggleItemSizeClass(): string {
    return 'button-toggle__item-' + this.buttonSize.toString();
  }

  @HostListener('mouseenter')
  onMouseEnterAction(): void {
    this.isHovered = true;
  }

  @HostListener('mouseleave')
  onMouseLeaveAction(): void {
    this.isHovered = false;
  }

  constructor(
    @Optional() @Inject(CS_BUTTON_TOGGLE_GROUP) toggleGroup: ButtonToggleGroupDirective
  ) {
    this.buttonToggleGroup = toggleGroup;
  }

  get isItemActive(): boolean {
    return this.buttonToggleGroup && this.buttonToggleGroup.value && this.buttonToggleGroup.value === this.value;
  }

  ngOnInit(): void {
  }

  onButtonClick(): void {
    if (!this.isItemActive) {
      this.buttonToggleGroup.updateValueFromItem(this.value);
    }
  }


}
