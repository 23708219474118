import { Component, Input } from '@angular/core';
import { ClientPriceListProduct } from '../../interfaces/client-price-list-product.interface';
import { ClientPriceListProductWeight } from '../../interfaces/client-price-list-product-weight.interface';

@Component({
  selector: 'ap-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent {
  @Input() product: ClientPriceListProduct | null = null;

  getItemsCountTooltip(weights: ClientPriceListProductWeight[]): string {
    let message = '<span style="font-weight: 600">';

    weights.forEach((item: ClientPriceListProductWeight, index: number) => {
      if (index !== weights.length - 1) {
        message += `${ item.weight }  <span style="font-size: 16px; color: var(--white); margin: 0 4px">${ item.price }</span> <span style="color: var(--white)">${ item.price_type === 'fix' ? 'NOK' : '%'}</span>${ index !== weights.length - 1 ? '</br>' : '' }`
      } else {
        message += `${ item.weight }  <span style="font-size: 16px; color: var(--white); margin: 0 4px">${ item.price }</span> <span style="color: var(--white)">${ item.price_type === 'fix' ? 'NOK' : '%'}</span>${ index !== weights.length - 1 ? '</br></span>' : '' }`
      }
    })

    return message
  }
}
