<div *ngIf="item"
     class="ui-table-cell"
     [class.ui-table-cell--editable]="tableItem.type === 'edit' || tableItem.type === 'select'"
     [class.ui-table-cell--editable-status]="tableItem.type === 'status-edit'"
     [class.ui-table-cell--with-actions]="tableItem.actions && tableItem.actions.length"
     [tooltip]="tableItem.tooltip ? tableItem.tooltip(item) : ''"
     (click)="emitClickEvent($event)">
  <ng-container *ngIf="!tableItem.customTemplate">

    <ng-container *ngIf="(tableItem.type === 'info' && !custom.children.length) || (tableItem.type === 'edit' && !editMode) || (tableItem.type === 'select' && !editMode)">
      <div class="ui-table-cell__text" (click)="(tableItem.type === 'edit' || tableItem.type === 'select') ? activateEditMode($event) : null"
           [class.ui-table-cell__text--gray-40]="!item[tableItem.databaseName]">
        {{item[tableItem.databaseName] ? item[tableItem.databaseName] : item[tableItem.databaseName] === 0 ? 0 : '-'}}
      </div>
    </ng-container>
    <ng-container *ngIf="tableItem.type === 'edit' && editMode">
      <div class="form-field form-field--small width-full">
        <div class="input">
          <input type="text"
                 #input
                 class="ui-table-cell__input"
                 [formControl]="cellFormControl"
                 (focusout)="emitFocusOutEvent()">
        </div>
        <div *ngIf="cellFormControl.invalid" class="form-field__error">
          <ng-container *ngIf="cellFormControl.errors!['required']">
            This field is required
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(tableItem.type === 'status-edit' || tableItem.type === 'status') && activeStatus">
      <div class="ui-table-cell__status-container">
        <div class="ui-table-cell__text">
          <div class="status"
               translate
               [style.background-color]="activeStatus.color"
               (click)="activateEditMode($event)">
            {{activeStatus.name}}
          </div>
        </div>
        <div *ngIf="editMode && tableItem.type === 'status-edit'" class="ui-table-cell__statuses">
          <div *ngFor="let status of statuses"
               class="ui-table-cell__status"
               [class.ui-table-cell__status--active]="status.databaseName === activeStatus.databaseName">
            <div class="status"
                 [style.background-color]="status.color"
                 (click)="selectActiveStatus(status, $event)">
              {{status.name}}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tableItem.type === 'select' && editMode">
      <ng-select #select
                 class="dropdown dropdown--height-40 dropdown--outlined-gray width-full"
                 bindValue="value"
                 bindLabel="label"
                 [items]="tableItem.selectOptions!"
                 [formControl]="cellFormControl"
                 [clearable]="false"
                 [searchable]="false"
                 (close)="emitFocusOutEvent()">
      </ng-select>
    </ng-container>
    <div #custom>
      <ng-content></ng-content>
    </div>

  </ng-container>

  <ng-container *ngIf="tableItem.customTemplate">
    <div *ngIf="!(tableItem.type === 'edit' && editMode || tableItem.type === 'select' && editMode)"
         class="ui-table-cell__text"
         [innerHTML]="tableItem.customTemplate(item) | noSanitize"
         (click)="(tableItem.type === 'edit' || tableItem.type === 'select') ? activateEditMode($event) : null"></div>
    <ng-container *ngIf="tableItem.type === 'edit' && editMode">
      <div class="input">
        <input type="text"
               #inputWithCustom
               [formControl]="cellFormControl"
               (focusout)="emitFocusOutEvent()">
      </div>
    </ng-container>
    <ng-container *ngIf="tableItem.type === 'select' && editMode">
      <ng-select #select
                 class="dropdown dropdown--height-40 dropdown--outlined-gray width-full"
                 bindValue="value"
                 bindLabel="label"
                 [items]="tableItem.selectOptions!"
                 [formControl]="cellFormControl"
                 [clearable]="false"
                 [searchable]="false"
                 (close)="emitFocusOutEvent()">
      </ng-select>
    </ng-container>
  </ng-container>

  <div *ngIf="actionListForTableCell.length" class="ui-table-cell__actions">
    <i *ngFor="let action of actionListForTableCell; let i = index"
       class="fs-20"
       [class]="'icon-' + action.icon"
       [tooltip]="getActionTooltip(action) | translate"
       (click)="emitCellAction(action, $event)"></i>
  </div>
</div>
