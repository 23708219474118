import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NotificationsAppService {
  _updateHeaderNotifications: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  updateHeaderNotifications(value: boolean): void {
    this._updateHeaderNotifications.next(value);
  }

  updateHeaderNotificationsListener(): Observable<boolean> {
    return this._updateHeaderNotifications.asObservable()
  }
}