<ap-ui-table [tableItems]="tableItems"
             [list]="list"
             [totalItemsCount]="totalItems"
             [activePage]="activePage"
             [itemsPerPageValues]="itemsPerPageValues"
             [itemsPerPage]="itemsPerPage"
             (pageChangedEmitter)="onChangePage($event)"
             (itemsPerPageChangedEmitter)="onChangeItemsPerPageValue($event)">

</ap-ui-table>

<!--<ap-ui-loader *ngIf="loading"></ap-ui-loader>-->
