<ng-template #generalConfirmTemplate>
  <div class="general-confirm">
    <div class="general-confirm__top">
      <div class="general-confirm__title" translate>
        {{ title.includes('Are you sure you want to delete') ? ('Are you sure you want to delete' | translate) + title.replace('Are you sure you want to delete', ' ') : title }}
      </div>
      <div class="general-confirm__close" (click)="closeModal()">
        <i class="icon-close-stroke pointer fs-28"></i>
      </div>
    </div>
    <div class="general-confirm__body">
      <div class="general-confirm__description" translate>
        {{ description }}
      </div>
      <div class="general-confirm__additional-confirmation-title" 
           *ngIf="additionalCheckboxConfrimationTitle" translate>
        {{ additionalCheckboxConfrimationTitle }}
      </div>
      <div class="general-confirm__checkbox checkbox-container" *ngIf="additionalCheckboxConfrimationTitle">
        <input type="checkbox" id="additional_confirmation_checkbox" [formControl]="additionalConfirmationControl">
        <label for="additional_confirmation_checkbox" class="checkbox-container__label" translate>
          {{ additionalCheckboxConfrimationCheckboxLabel }}
        </label>
      </div>
    </div>
    <div class="general-confirm__footer">
      <button class="button button--contained-gray button--height-40 mr--16"
              (click)="closeModal()" translate>
        Cancel
      </button>
      <button class="button button--contained-black button--height-40" 
              (click)="applyAction()" translate>
        {{ applyButtonText }}
      </button>
    </div>
  </div>
</ng-template>