import { Location } from '@angular/common';
import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ResolveStart, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { EMPTY, mergeMap, of, timer } from 'rxjs';
import { catchError, delay, filter, map, tap } from 'rxjs/operators';

import { ClientAppService } from 'src/app/shared/services/client.app.service';
import { environment } from 'src/environments/environment';
import { ClientInterface } from '../../../modules/superadmin/manage-clients/interfaces/client.interface';
import { NotificationsListResponse } from '../../models/notifications.model';
import { NotificationsHelpClass } from '../../helpers/notifications-helper.class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OnboardingService } from 'src/app/services/onboarding-service/onboarding.service';
import { AuthAppService } from 'src/app/modules/auth/services/auth.app.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ClientService } from 'src/app/services/client-service/client.service';
import { NotificationsService } from 'src/app/services/notifications-service/notifications.service';
import { NotificationsAppService } from 'src/app/modules/notifications/services/notifications.app.service';
import {
  TeamMemberCompaniesResponseInterface,
  TeamMemberCompanyInterface,
  User
} from 'src/app/services/user-service/user.interface';
import { NavCategoriesEnum } from '../../enums/nav-categories.enum';
import { UserService } from '../../../services/user-service/user.service';
import { ManageClientsService } from 'src/app/services/manage-clients-service/manage-clients.service';
import { ClientSettingsInterface } from '../../interfaces/settings/client-settings.interface';
import { LoadingService } from '../../services/loading.service';
import { BreadcrumbsInerface } from '../../interfaces/breadcrumbs.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends NotificationsHelpClass implements OnInit {
  @Input() activeNavCategory!: NavCategoriesEnum | null;
  @Output() clientDisabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('createClientFormModalTemplate') createClientFormModalTemplate!: TemplateRef<any>;
  @ViewChild('searchClientInput') searchClientInput!: ElementRef;
  environment = environment;
  user!: User;
  showUserMenu: boolean = false;
  clients: ClientInterface[] = JSON.parse(localStorage.getItem('clients')!) || [];
  filteredClients: ClientInterface[] = [];
  filteredClientsInitialValue: ClientInterface[] = [];
  teamMemberCompanies: TeamMemberCompanyInterface[] = []
  selectedTeamMemberCompany!: TeamMemberCompanyInterface
  selectedClient!: ClientInterface | undefined;
  mainCategory: BreadcrumbsInerface = { title: 'Welcome', route: '/' };
  breadcrumbs: BreadcrumbsInerface[] = [];
  showBackButton!: boolean;
  noTranslate!: boolean;
  userEmail: string = '';
  notificationSound = new Audio();
  isFirstNotificationLoad: boolean = true;
  isOnProductListPage: boolean = false;
  modalRef!: BsModalRef;
  showClientsList: boolean = false;
  showPriceListSidebar: boolean = false;
  onboardingMode: boolean = false;
  urlAfterRedirects: string = '';
  selectedClientForm!: FormGroup;
  clientSettings: ClientSettingsInterface | null = null;
  clientHaveUnpaidInnvoices: boolean = false;
  clientListIndex: number = 50;
  disableClientsSearch: boolean = false;

  private destroyRef = inject(DestroyRef)

  get userIsReseller(): boolean {
    return this.user?.permissions['1'] === 3 || false;
  }

  get userIsCustomerSupport(): boolean {
    return this.user?.permissions['1'] === 4 || false;
  }

  constructor(private authAppService: AuthAppService,
              private authService: AuthService,
              private router: Router,
              private clientService: ClientService,
              private clientAppService: ClientAppService,
              private location: Location,
              private modalService: BsModalService,
              private onboardingService: OnboardingService,
              private notificationsAppService: NotificationsAppService,
              private userService: UserService,
              private fb: FormBuilder,
              private loadingService: LoadingService,
              private manageClientsService: ManageClientsService,
              notificationsService: NotificationsService) {
                super(notificationsService);
                this.buildSelectedClientForm();
                this.currentUserSubscription();
                this.routerEventsSubscription();
                this.activeClientListener();
                if (!environment.production) {
                  this.addLiveLeaderScript();
                }
              }

  ngOnInit(): void {
    this.notificationsSubscription();
    this.updateHeaderNotificationsListener();
    this.clientModalSubscription();
    this.clientListUpdateSubscription();
    this.selectedClientControlChangeSubscription();

    if (localStorage.getItem('clients'))  {
      this.clientAppService.setClients(JSON.parse(localStorage.getItem('clients')!))
    }

    this.checkClientsList()
  }

  notificationsSubscription(): void {
    this.notificationSound.src = '/assets/sounds/notification.mp3';

    timer(0, 60000).pipe(
      mergeMap(() => this.notificationsService.getNotifications(true)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: NotificationsListResponse) => {
      if (this.notificationsCounter < res.count && !this.isFirstNotificationLoad) {
        this.notificationSound.play();
        this.isFirstNotificationLoad = false;
      }

      this.notificationsCounter = res.count;
      this.notifications = res.results;
    })
  }

  updateHeaderNotificationsListener(): void {
    this.notificationsAppService.updateHeaderNotificationsListener().pipe(
      mergeMap((res: boolean) => {
        if (res) {
          return this.notificationsService.getNotifications(true)
        }
        return EMPTY
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(res => {
        this.notificationsCounter = res.count;
        this.notifications = res.results;
      }
    )
  }

  back(): void {
    this.location.back();
  }

  logout(): void {
    this.authService.logout().pipe(takeUntilDestroyed(this.destroyRef)).subscribe()
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  selectClient(client: ClientInterface, toUpdateClientAppService: boolean = true): void {
    this.selectedClient = this.clients.find((c: ClientInterface) => c.id == client.id);
    this.selectedClientForm.get('name')!.patchValue(this.selectedClient?.name, { emitEvent: false })
    const settings = JSON.parse(localStorage.getItem('clientSettings')!)

    if (this.selectedClient && toUpdateClientAppService) {
      if ((settings && settings.id !== client.id || !settings) && 
          (this.selectedClient?.users_ids?.includes(this.user.id!) || this.userIsCustomerSupport || this.user.is_admin)) {
        this.loadingService.setIsLoading(true)
        this.manageClientsService.getClientsSettings(this.selectedClient.id!)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(res => {
            localStorage.setItem('clientSettings', JSON.stringify(res))
            this.clientAppService.setActiveClient(this.selectedClient);
            this.loadingService.setIsLoading(false)
          })
      } else {
        this.clientAppService.setActiveClient(this.selectedClient);
      }
    }

    this.showClientsList = false;
    this.filteredClients = this.filteredClientsInitialValue;
    this.clientListIndex = 50;
  }

  goToOnboarding(e: Event, client: ClientInterface): void {
    e.stopPropagation();
    this.selectedClient = undefined;
    this.onboardingService.setClientForOnboarding(client);
    this.navigateTo('onboarding');
    this.showClientsList = false;
  }

  clearSearchClientsValue(): void {
    if (this.user.is_admin || this.userIsCustomerSupport) {
      this.selectedClient = undefined;
      this.showClientsList = false;
      this.clientAppService.setActiveClient(this.selectedClient);
      localStorage.removeItem('activeClient');
      this.clientSettings = null;
      this.clientHaveUnpaidInnvoices = false;
      this.clientDisabled.emit(false);
      this.clientAppService.setBlockClient(false);
    }
    this.clientListIndex = 50;
  }

  clientModalSubscription(): void {
    this.clientAppService.showClientForm().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: boolean) => {
      if (res) {
        this.openModal(this.createClientFormModalTemplate)
      }
    })
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-dialog--short', ignoreBackdropClick: true }))
    this.showClientsList = false;
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  // Hided by Romen Requst 27.08.2024
  // updateClientsList(): void {
  //   this.clientAppService.updateClientList(true);
  // }
  // Hided by Romen Requst 27.08.2024

  selectTeamMemberCompany(company: TeamMemberCompanyInterface): void {
    const selectedCompany = this.teamMemberCompanies.find((c: TeamMemberCompanyInterface) => c.id == company.id)
    this.selectedTeamMemberCompany = selectedCompany!
    this.clientAppService.setActiveTeamMemberCompany(this.selectedTeamMemberCompany)
    this.selectedClientForm.get('name')!.patchValue(this.selectedTeamMemberCompany!.name, { emitEvent: false })
    this.showClientsList = false;
  }

  handleClickOutsideSelection(): void {
    if (this.user.platform_id !== 3) {
      if (!this.user.is_admin && !this.userIsCustomerSupport && this.showClientsList) {
        this.selectClient(this.selectedClient!, false)
      } else {
        this.showClientsList = false
      }
    } else {
      this.selectTeamMemberCompany(this.selectedTeamMemberCompany)
    }
  }

  onScrollCLientsList(): void {
    this.clientListIndex += 50;
  }

  openClientsList(): void {
    this.selectedClientForm.get('name')!.disable({ emitEvent: false });
    this.clientService.getClients(undefined, undefined, false).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: ClientInterface[]) => {
      if (res) {
        localStorage.setItem('clients', JSON.stringify(res));
        this.parseClients(res, false);
        this.clients = res;
        this.clientListIndex = 50;
        this.showClientsList = true;
        this.selectedClientForm.get('name')!.enable({ emitEvent: false });
        this.searchClientInput.nativeElement.focus();
      }
    })
  }

  private clientListUpdateSubscription(): void {
    this.clientAppService.updateClientsListener().pipe(
      mergeMap((res: boolean) => {
        if (res) {
          return this.clientService.getClients()
        }
        return EMPTY
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: ClientInterface[]) => {
      if (res) {
        this.parseClients(res, true);
        this.clients = res;
      }
    })
  }

  private parseClients(clients: ClientInterface[], select_client: boolean = false): void {
    let arr: ClientInterface[];
    
    arr = clients.filter((client: ClientInterface) => !client.my_reseller_id || client.is_reseller || !clients.find(reseller => reseller.id === client.my_reseller_id)).map((client: ClientInterface) => {
      if (client.is_reseller) {
        client.reseller_clients = []
      }

      return client
    })

    clients.forEach(c => {
      if (!c.is_reseller && c.my_reseller_id && arr.find(reseller => reseller.id === c.my_reseller_id)) {
        arr.find(reseller => reseller.id === c.my_reseller_id)!['reseller_clients']?.push(c)
      }
    })

    this.filteredClientsInitialValue = this.filteredClients = arr;

    if (!this.user.is_admin && !this.userIsCustomerSupport && select_client) {
      let activeClient;

      if (JSON.parse(localStorage.getItem('activeClient')!) &&
          this.clients.find((client: ClientInterface) => client.id === JSON.parse(localStorage.getItem('activeClient')!).id)) {
          activeClient = this.clients.find((client: ClientInterface) => client.id === JSON.parse(localStorage.getItem('activeClient')!).id)
      } else {
        localStorage.removeItem('activeClient')
        activeClient = this.filteredClients[0];
      }
      this.selectClient(activeClient ? activeClient : this.filteredClients[0]);
    }

    if ((this.user.is_admin || this.userIsCustomerSupport) && localStorage.getItem('activeClient') && select_client) {
      this.selectClient(JSON.parse(localStorage.getItem('activeClient')!));
    }
  }

  private selectedClientControlChangeSubscription(): void {
    this.selectedClientForm.get('name')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: string) => {
      this.parseClients(this.clients);
      if (res.length) {
        this.filteredClients = structuredClone([
          ...this.filteredClientsInitialValue.filter((client: ClientInterface) => {
            if (client.reseller_clients?.length) {
              let filtered_reseller_clients: ClientInterface[] = [];

              client.reseller_clients.forEach((resellerClient: ClientInterface) => {
                if (resellerClient.name.toLowerCase().includes(res.toLowerCase())) {
                  filtered_reseller_clients.push(resellerClient)
                }
              })

              client.reseller_clients = filtered_reseller_clients;

              return client.name.toLowerCase().includes(res.toLowerCase()) ||
                      client.reseller_clients!.some((resellerClient: ClientInterface) => resellerClient.name.toLowerCase().includes(res.toLowerCase()))
            }

            return client.name.toLowerCase().includes(res.toLowerCase())
          })
        ]);
        this.showClientsList = true;
      } 
      this.clientListIndex = 50;
    })
  }

  private currentUserSubscription(): void {
      this.authAppService.currentUser.pipe(
        mergeMap((user: User | null) => {
          if (user) {
            this.user = user;
            this.userEmail = this.user.email;
            return this.user.platform_id !== 3 ? this.clientAppService.allClients() : this.userService.getMembersCompanies()
          } else {
            return EMPTY
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((res: ClientInterface[] | TeamMemberCompaniesResponseInterface) => {
        if (this.user.platform_id !== 3) {
          if (res && (res as ClientInterface[]).length) {
            this.clients = res as ClientInterface[];
            this.parseClients(res as ClientInterface[], true);
          }
        } else {
          this.teamMemberCompanies = (res as TeamMemberCompaniesResponseInterface).data.companies
          let activeTeamMemberCompany = JSON.parse(localStorage.getItem('activeTeamMemberCompany')!)
          this.selectTeamMemberCompany(activeTeamMemberCompany ? activeTeamMemberCompany : this.teamMemberCompanies[0])
        }
      })
  }

  private routerEventsSubscription(): void {
    this.router.events.pipe(
      filter(event => event instanceof ResolveStart),
      map((event: any) => {
        let data;
        let route = event['state'].root;

        while (route) {
          data = route.data || data;
          route = route.firstChild;
        }

        return data;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(({ mainCategory, breadcrumbs, noTranslate, backButton }) => {
      this.mainCategory = mainCategory;
      this.onboardingMode = this.mainCategory?.title === 'Onboarding';
      this.breadcrumbs = breadcrumbs
      this.noTranslate = noTranslate
      this.showBackButton = backButton ? backButton : false;
    })
}

  private buildSelectedClientForm(): void {
    this.selectedClientForm = this.fb.group({
      name: ['']
    })
  }

  private activeClientListener(): void {
    this.clientAppService.activeClient().pipe(
      tap((client: ClientInterface | null) => {
        if (client) {
          this.selectClient(client, false)
        }
      }),
      mergeMap((client: ClientInterface | null) => {
        if (client) {
          return this.clientService.unpaidInvoices(client.id!)
        }
        return EMPTY
      }),
      catchError(() => of(null)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((unpaidInvoices) => {
      this.clientSettings = JSON.parse(localStorage.getItem('clientSettings')!) || null

      if (this.clientSettings && this.clientSettings.is_edi_blocked) {
        this.clientDisabled.emit(true);
        this.clientAppService.setBlockClient(true);
      } else {
        this.clientDisabled.emit(false);
        this.clientAppService.setBlockClient(false);
      }

      if (unpaidInvoices && unpaidInvoices.have_unpaid_invoices) {
        this.clientHaveUnpaidInnvoices = true;
        this.clientDisabled.emit(true);
      } else {
        this.clientHaveUnpaidInnvoices = false;
        this.clientDisabled.emit(false);
      }
    })
  }

  private checkClientsList(): void {
    of([]).pipe(
      delay(1000),
      mergeMap(() => {
        if (!localStorage.getItem('clients')) {
          return this.clientService.getClients()
        }

        return EMPTY
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: ClientInterface[]) => {
      if (res) {
        localStorage.setItem('clients', JSON.stringify(res));
        this.clientAppService.setClients(res)
      }
    })
  }

  private addLiveLeaderScript(): void {
    const liveleaderScript = document.createElement('script');
    liveleaderScript.type = 'text/javascript';
    liveleaderScript.src = 'https://client.liveleader.eu/embed.js';
    liveleaderScript.setAttribute('data-chat-id', 'LIOfmOlv4SR7SGkYAzE5wIDg');

    const head = document.getElementsByTagName('head')[0];

    const liveleaderPrams = document.createElement('script');
    liveleaderPrams.type = 'text/javascript';
    liveleaderPrams.innerHTML = `
      function chat(){
        (window.lvchtarr=window.lvchtarr||[]).push(arguments)
      }

      var previousListener;

      chat("launcher",
        function(data) {
          var launcher = document.getElementById("launcher");
          
          if (launcher) {
            var listener = function() {
              data.openFn(launcher);
            };

            launcher.classList.toggle("clickable", data.canOpen);
            if (data.canOpen) {
              launcher.addEventListener("click", listener);
            }
            if (previousListener) {
              launcher.removeEventListener("click", previousListener);
            }
            launcher.classList.add("visible");
            previousListener = listener;
          }
        },
        true
      );

      chat(
        'visitor', 
        { name: '${ this.user.first_name } ${ this.user.last_name }',
          email: '${ this.user.email }',
          data: {
            client_id: '${ this.selectedClient ? this.selectedClient.id : '' }',
            client_name: '${ this.selectedClient ? this.selectedClient.name : '' }',
        }
      });
    `

    if (head !== null && head !== undefined) {
      document.head.appendChild(liveleaderScript);
      document.head.appendChild(liveleaderPrams);
    }
  }
}
