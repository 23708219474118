import { Component, Input } from '@angular/core';

@Component({
  selector: 'ap-ui-loader',
  templateUrl: './ui-loader.component.html',
  styleUrls: ['./ui-loader.component.scss']
})
export class UiLoaderComponent{
  @Input() type: 'regular' | 'fixed' = 'regular';
}
