import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { PlaceholderLoaderTypeEnum } from '../../enums/placeholder-loader-type.enum';
import { PlaceholderLoaderElementInterface } from '../../interfaces/placeholder-loader-element.interface';

@Component({
  selector: 'ap-ui-block',
  templateUrl: './ui-block.component.html',
  styleUrls: ['./ui-block.component.scss']
})
export class UiBlockComponent implements OnInit {

  placeholderLoaderTypeEnum = PlaceholderLoaderTypeEnum;

  @Input() block!: Partial<PlaceholderLoaderElementInterface>;

  @HostBinding('style.max-width') maxWidth: string | undefined;
  @HostBinding('style.min-width') minWidth: string | undefined;
  @HostBinding('style.width') width: string | undefined;

  ngOnInit(): void {
    this.maxWidth = this.block.width?.max;
    this.minWidth = this.block.width?.min;
    this.width = this.block.width?.value;
  }

}
