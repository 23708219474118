export enum NavCategoriesEnum {
  DASHBOARD = 'DASHBOARD',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  REPORTS = 'REPORTS',
  SHIPMENTS = 'SHIPMENTS',
  INVOICES = 'INVOICES',
  VISMA_INVOICES = 'VISMA_INVOICES',
  PRICE_GROUPS = 'PRICE_GROUPS',
  CLIENTS_LIST = 'CLIENTS_LIST',
  PRODUCT_LIST = 'PRODUCT_LIST',
  ORDERS = 'ORDERS',
  MANAGE_USERS = 'MANAGE_USER',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
  MANAGE_PRINTERS = 'MANAGE_PRINTERS',
  CONTACT_BOOK = 'CONTACT_BOOK',
  ITEM_INVENTORY = 'ITEM_INVENTORY',
  ITEM_MOVEMENTS = 'ITEM_MOVEMENTS',
  SETTINGS = 'SETTINGS',
  PURCHASE_ORDERS = 'PURCHASE_ORDERS',
  MANAGE_TRANSLATES = 'MANAGE_TRANSLATES',
  INTEGRATIONS = 'INTEGRATIONS'
}
