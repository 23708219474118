import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

import { filter, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { NavCategoriesEnum } from '../../enums/nav-categories.enum';
import { User } from '../../../services/user-service/user.interface';
import { AuthAppService } from '../../../modules/auth/services/auth.app.service';
import { HelpService } from '../../services/help.service';
import { ClientAppService } from '../../services/client.app.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClientInterface } from '../../../modules/superadmin/manage-clients/interfaces/client.interface';

@Component({
  selector: 'ap-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Output() isSidebarClosedForEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  environment = environment;
  isSidebarClosed: boolean = false;
  isSidebarClosedAndHovered: boolean = false;
  activeNavCategory: NavCategoriesEnum | null = null;
  currentUser!: User | null;
  openedEcommerce: boolean = false;
  openedLogistics: boolean = false;
  openedSuperadmin: boolean = false;
  openedWarehousing: boolean = false;
  urlAfterRedirects: string = '';
  NavCategoriesEnum = NavCategoriesEnum;
  activeClient: ClientInterface | null = null;

  private subscription$: Subscription = new Subscription();
  private destroyRef = inject(DestroyRef)

  constructor(private router: Router,
              private authAppService: AuthAppService,
              private clientAppService: ClientAppService,
              private helpService: HelpService) {
    this.getUrl();
  }

  get userIsReseller(): boolean {
    return this.currentUser?.permissions['1'] === 3 || false;
  }

  get userIsCustomerSupport(): boolean {
    return this.currentUser?.permissions['1'] === 4 || false;
  }

  get userIsInvitedStaff(): boolean {
    return this.currentUser?.platform_id === 3
  }

  get superadminOrResellerIconClass(): string {
    if (this.currentUser!.is_admin || this.userIsCustomerSupport) {
      return 'icon-superadmin'
    } else if (this.userIsReseller && !this.currentUser!.is_admin && !this.userIsCustomerSupport) {
      return 'icon-reseller'
    } else {
      return 'icon-superadmin'
    }
  }

  ngOnInit(): void {
    this.currentUserSubscription();
    this.activeClientListener();
  }

  navigateTo(route: string): void {
    this.activeNavCategory = null;
    if (route === 'products' || route === 'sales-orders' || route === 'shipments' || route === 'item-movements') {
      this.router.navigate([route], {
        queryParams: {
          activePage: 1
        }
      });
    } else {
      this.router.navigate([route]);
    }
  }

  setActiveNavCategory(category: NavCategoriesEnum): void {
    this.activeNavCategory = category;

    if (this.activeNavCategory === NavCategoriesEnum.SETTINGS) {
      this.navigateTo(environment.production ? 'settings/company' : 'settings/preferences')
    } else {
      this.navigateTo(category.toLocaleLowerCase().replace('_', '-'));
    }
  }

  parseUrl(url: string): void {
    this.activeNavCategory = null;
    if (url.includes('dashboard')) {
      this.activeNavCategory = NavCategoriesEnum.DASHBOARD;
    }

    if (url.includes('orders')) {
      this.openedEcommerce = true;
      this.activeNavCategory = NavCategoriesEnum.ORDERS;
    }

    if (url.includes('products')) {
      this.openedEcommerce = true;
      this.activeNavCategory = NavCategoriesEnum.PRODUCT_LIST;
    }

    if (url.includes('item-movements')) {
      this.openedWarehousing = true;
      this.activeNavCategory = NavCategoriesEnum.ITEM_MOVEMENTS;
    }

    if (url.includes('shipments')) {
      this.openedLogistics = true;
      this.activeNavCategory = NavCategoriesEnum.SHIPMENTS;
    }

    if (url.includes('contact-book')) {
      this.openedLogistics = true;
      this.activeNavCategory = NavCategoriesEnum.CONTACT_BOOK;
    }

    if (url.includes('invoices') && !url.includes('visma')) {
      this.openedLogistics = true;
      this.activeNavCategory = NavCategoriesEnum.INVOICES;
    }

    if (url.includes('visma') && url.includes('invoices')) {
      this.openedLogistics = true;
      this.activeNavCategory = NavCategoriesEnum.VISMA_INVOICES;
    }

    if (url.includes('price-groups')) {
      this.openedSuperadmin = true;
      this.activeNavCategory = NavCategoriesEnum.PRICE_GROUPS;
    }

    if (url.includes('clients-list')) {
      this.openedSuperadmin = true;
      this.activeNavCategory = NavCategoriesEnum.CLIENTS_LIST;
    }

    if (url.includes('manage-users')) {
      this.openedSuperadmin = true;
      this.activeNavCategory = NavCategoriesEnum.MANAGE_USERS;
    }

    if (url.includes('permissions')) {
      this.openedSuperadmin = true;
      this.activeNavCategory = NavCategoriesEnum.MANAGE_PERMISSIONS;
    }

    if (url.includes('manage-printers')) {
      this.openedSuperadmin = true;
      this.activeNavCategory = NavCategoriesEnum.MANAGE_PRINTERS;
    }

    if (url.includes('settings')) {
      this.activeNavCategory = NavCategoriesEnum.SETTINGS;
    }

    if (url.includes('customer-service')) {
      this.activeNavCategory = NavCategoriesEnum.CUSTOMER_SERVICE;
    }

    if (url.includes('reports')) {
      this.activeNavCategory = NavCategoriesEnum.REPORTS;
    }

    if (url.includes('item-inventory')) {
      this.openedWarehousing = true;
      this.activeNavCategory = NavCategoriesEnum.ITEM_INVENTORY;
    }

    if (url.includes('purchase-orders')) {
      this.openedEcommerce = true;
      this.activeNavCategory = NavCategoriesEnum.PURCHASE_ORDERS;
    }

    if (url.includes('notifications')) {
      this.activeNavCategory = null;
    }

    if (url.includes('integrations')) {
      this.activeNavCategory = NavCategoriesEnum.INTEGRATIONS;
    }
  }

  toggleSidebarItem(value: string): void {
    switch (value) {
      case 'logistics':
        if (!this.openedLogistics) {
          this.openedSuperadmin = false
          this.openedEcommerce = false
          this.openedWarehousing = false
        }
        this.openedLogistics = !this.openedLogistics
        break
      case 'superadmin':
        if (!this.openedSuperadmin) {
          this.openedLogistics = false
          this.openedEcommerce = false
          this.openedWarehousing = false
        }
        this.openedSuperadmin = !this.openedSuperadmin
        break
      case 'ecommerce':
        if (!this.openedEcommerce) {
          this.openedLogistics = false
          this.openedSuperadmin = false
          this.openedWarehousing = false
        }
        this.openedEcommerce = !this.openedEcommerce
        break
      case 'warehousing':
        if (!this.openedWarehousing) {
          this.openedLogistics = false
          this.openedSuperadmin = false
          this.openedEcommerce = false
        }
        this.openedWarehousing = !this.openedWarehousing
        break
      default:
        return
    }
  }

  toggleSidebar(): void {
    this.isSidebarClosed = !this.isSidebarClosed;
    this.isSidebarClosedForEmit.emit(this.isSidebarClosed)
    this.helpService.setIsSidebarClosed(this.isSidebarClosed)
  }

  private currentUserSubscription(): void {
    this.subscription$.add(
      this.authAppService.currentUser.subscribe((user: User | null) => {
        this.currentUser = user;
      })
    )
  }

  private getUrl(): void {
    this.subscription$.add(
      this.router.events
        .pipe(filter((e: Event) => e instanceof NavigationEnd))
        .subscribe((res: Event) => {
          this.urlAfterRedirects = (res as NavigationEnd).urlAfterRedirects;
          this.parseUrl(this.urlAfterRedirects);
      })
    )
  }

  private activeClientListener(): void {
    this.clientAppService.activeClient()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(client => {
        this.activeClient = client
      })

  }
}
