export const environment = {
  production: false,
  stage: false,
  baseUrl: 'https://gateway-service-stage-oceo3aw2bq-ez.a.run.app',
  baseEDIUrl: 'https://edi-stage-de777ts5.ew.gateway.dev',
  baseAppUrl: 'https://dev.adminpanel.no',
  firebaseConfig: {

  }
};


// baseUrl: 'https://admin.profrakt.no',

// baseUrl: 'https://dev.profrakt.no',

// baseUrl: 'http://127.0.0.1:8000',

// baseUrl: 'https://gateway-service-stage-oceo3aw2bq-ez.a.run.app/profrakt',
