import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { TableItemInterface } from '../../interfaces/table-item.interface';
import { TableItemActionInterface } from '../../interfaces/table-item-action.interface';
import { TableStatusInterface } from '../../interfaces/table-status.interface';
import { CellActionEmitDataInterface } from '../../interfaces/cell-action-emit-data.interface';
import { TableCommonActionInterface } from '../../interfaces/table-common-action.interface';

@Component({
  selector: 'ap-ui-table-list',
  templateUrl: './ui-table-list.component.html',
  styleUrls: ['./ui-table-list.component.scss']
})
export class UiTableListComponent implements OnChanges {

  @Input() tableItems: TableItemInterface[] = []
  @Input() list: { [key: string]: any }[] = []
  @Input() scrollPosition: {scrollLeft: number, scrollRight: number} = {scrollLeft: 0, scrollRight: 1000};
  @Input() actions: TableItemActionInterface[] = []
  @Input() commonActions: TableCommonActionInterface[] = []
  @Input() withCheckboxes: boolean = false
  @Input() selectAll: boolean = false;
  @Input() statuses: TableStatusInterface[] = [];
  @Input() unselectItems: boolean = false;
  @Input() isItemClickHandled: boolean = false;
  @Input() disableCheckboxes?: boolean = false;
  @Input() isItemHasError?: (item: any) => boolean;

  @Output() actionEmitter: EventEmitter<{ action: TableItemActionInterface, item: any }> = new EventEmitter<{ action: TableItemActionInterface, item: any }>()
  @Output() focusOutEmitter: EventEmitter<{ item: any; databaseName: string, value: any }> = new EventEmitter<{ item: any; databaseName: string, value: any }>()
  @Output() cellActionEmitter: EventEmitter<CellActionEmitDataInterface> = new EventEmitter<CellActionEmitDataInterface>()
  @Output() deselectEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() commonActionEmitter: EventEmitter<{ action: TableCommonActionInterface, items: any[] }> = new EventEmitter<{ action: TableCommonActionInterface, items: any[] }>()
  @Output() onClick: EventEmitter<{tableItem: TableItemInterface, item: any}> = new EventEmitter<{tableItem: TableItemInterface, item: any}>()
  @Output() selectedItemsEmitter: EventEmitter<{ [key: string]: any }[]> = new EventEmitter<{ [key: string]: any }[]>()

  checkedList: { [key: string]: any }[] = []
  itemWithAdditionalItemsExpanded: any = null

  get commonActionListForTableItems(): TableCommonActionInterface[] {
    return this.commonActions.filter(action => !(action.hided && action.hided(this.checkedList)))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectAll']) {
      if (this.selectAll) {
        this.list = this.list.map(item => {
          item['checked'] = true;
          return item;
        });
        this.checkedList = this.list;
      } else {
        this.list = this.list.map(item => {
          item['checked'] = false;
          return item;
        });
        this.checkedList = [];
      }
      this.selectedItemsEmitter.emit(this.checkedList);
    }

    if (changes['unselectItems'] && changes['unselectItems'].currentValue) {
      this.deselect();
    }
  }

  getCellStyles(tableItem: TableItemInterface): {[key: string]: string} {
    if (tableItem.type === "actions") {
      return {
        'minWidth': tableItem.width,
        'maxWidth': 'fit-content',
      }
    } else {
      return {
        'width': tableItem.width,
      }
    }
  }

  getCellClasses(item: TableItemInterface): {[key: string]: boolean} {
    return {
      'table-list__cell--pinned-left': item.pinnedLeft || false,
      'table-list__cell--pinned-right': item.pinnedRight || false,
      'table-list__cell--scrolled-left': (item.pinnedLeft || false) && this.scrollPosition.scrollLeft > 0,
      'table-list__cell--scrolled-right': (item.pinnedRight || false) && this.scrollPosition.scrollRight > 0
    }
  }

  onScroll($event: Event): void {
    this.scrollPosition = {
      scrollLeft: ($event.target as HTMLElement).scrollLeft,
      scrollRight: ($event.target as HTMLElement).scrollWidth - ($event.target as HTMLElement).clientWidth - ($event.target as HTMLElement).scrollLeft
    }
  }

  emitActionItem(action: TableItemActionInterface, item: any, event: Event): void {
    event.preventDefault()
    event.stopImmediatePropagation()
    this.actionEmitter.emit({action, item})
  }

  deselect(): void {
    this.list = this.list.map(item => {
      item['checked'] = false;
      return item;
    });

    if (this.selectAll) {
      this.deselectEmitter.emit();
    }
    this.checkedList = [];

    this.selectedItemsEmitter.emit(this.checkedList);
  }

  checkItem(item: { [p: string]: any }): void {
    if (this.checkedList.find((i: { [p: string]: any }) => item['id'] === i['id'])) {
      this.checkedList = [...this.checkedList].filter((i: { [p: string]: any }) => item['id'] !== i['id'])
    } else {
      this.checkedList.push(item);
    }

    this.selectedItemsEmitter.emit(this.checkedList);
  }

  expandIcons(item: unknown): void {
    this.itemWithAdditionalItemsExpanded = item
  }

  getActionTooltip(action: TableItemActionInterface, item: any): string {
    if (action.tooltip) {
      if (typeof action.tooltip === 'string') {
        return action.tooltip
      } else {
        return action.tooltip(item)
      }
    } else {
      return ''
    }
  }

  getActionTooltipPlacement(action: TableItemActionInterface, item: any): 'top' | 'bottom' | 'left' | 'right' | 'bottom_right' {
    if (action.tooltipPlacement) {
      if (typeof action.tooltipPlacement === 'string') {
        return action.tooltipPlacement
      } else {
        return action.tooltipPlacement(item)
      }
    } else {
      return 'left'
    }
  }

  getActionListForTableItem(item: { [p: string]: any }): TableItemActionInterface[] {
    return this.actions.filter(action => !(action.hided && action.hided(item)))
  }

  emitClickEvent($event: {tableItem: TableItemInterface, item: any}): void {
    this.onClick.emit({tableItem: $event.tableItem, item: $event.item})
  }
}
