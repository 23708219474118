import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationStripeService } from '../../../services/notification-stripe.service';

@Component({
  selector: 'ap-ui-general-notification-stripe',
  templateUrl: './ui-general-notification-stripe.component.html',
  styleUrls: ['./ui-general-notification-stripe.component.scss']
})
export class UiGeneralNotificationStripeComponent {
  private subscriptions$ = new Subscription();
  @Input() content: string | null = '';

  constructor(private notificationStripeService: NotificationStripeService) {
    this.subscriptions$.add(
      this.notificationStripeService.notificationStripeContent().subscribe((res: string | null) => this.content = res)
    )
  }
}
