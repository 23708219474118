export interface NotificationsListResponse {
  count: number,
  next: string,
  previous: string,
  results: Notification[]
}

export interface Notification {
  created: string,
  id: string,
  message: string | ShipmentReportMesssages,
  link_id: string, 
  read: boolean,
  type: NotificationType,
  file_url: string
}

export enum NotificationType {
  SUPPORT_TICKET_COMMENT = "support ticket comment",
  SUPPORT_TICKET = "support ticket",
  PACKAGES_REPORT = "package report"
}

export enum ShipmentReportMesssages {
  USER_START_TO_GENERATE_PACKAGES_REPORT = "USER_START_TO_GENERATE_PACKAGES_REPORT",
  PACKAGES_REPORT = 'PACKAGES_REPORT',
  FAILED_REPORT = 'FAILED_REPORT'
}