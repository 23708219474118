<label [for]="id">

  <div *ngIf="labelPlacement === 'left'" class="slide-toggle__label--left">{{label}}</div>

  <div class="slide-toggle__bar"
       [ngClass]="inputControl.value ? 'slide-toggle-bar-on' : 'slide-toggle-bar-off'">

    <div class="slide-toggle__thumb"></div>

    <input type="checkbox" [id]="id"  (input)="toggleSlider()" [formControl]="inputControl"/>
  </div>

  <div *ngIf="labelPlacement === 'right'" class="slide-toggle__label--right">{{label}}</div>

</label>
