import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdminpanelModulesEnum, PermissionsServicesEnum } from 'src/app/modules/superadmin/manage-permissions/enumns/permissions.enum';
import { UserPermissions } from 'src/app/services/permission-service/permission.interface';
import { User } from 'src/app/services/user-service/user.interface';

@Injectable({
  providedIn: 'root',
})
export class InvoicesGuard  {
  constructor(private router: Router) {}

  canLoad(): boolean {
    const userPermissions = JSON.parse(localStorage.getItem('userPermissions')!);
    const user: User = JSON.parse(localStorage.getItem('user')!);
    const clients = JSON.parse(localStorage.getItem('clients')!);
    const adminPanelPermissions: UserPermissions = userPermissions?.find(
      (permissions: UserPermissions) => permissions.service_id === PermissionsServicesEnum.ADMINPANEL
    )
    
    if (adminPanelPermissions && 
        adminPanelPermissions.resources.includes(AdminpanelModulesEnum.SHIPMENTS) &&
        user.first_name && user.last_name &&
        clients.length || user.is_admin) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
