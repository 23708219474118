import { Component, DestroyRef, EventEmitter, inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { GeneralConfirmService } from '../../services/general-confirm.service';
import { GeneralConfirmData } from '../../models/general-confirm.model';
import { FormControl } from '@angular/forms';
import { interval } from 'rxjs';


@Component({
  selector: 'ap-general-confirm',
  templateUrl: './general-confirm.component.html',
  styleUrls: ['./general-confirm.component.scss']
})
export class GeneralConfirmComponent {
  @ViewChild('generalConfirmTemplate') generalConfirmTemplate!: TemplateRef<any>;
  @Output() applyActionEvent = new EventEmitter<void>();
  @Output() closeModalEvent = new EventEmitter<boolean>(false);
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() applyButtonText: string = '';
  @Input() data!: {
    [key: string]: string | number | boolean | null | undefined;
  } | null;
  @Input() set _openModal(value: boolean) {
    if (value) {
      this.modalRef = this.modalService.show(this.generalConfirmTemplate, { class: 'modal-dialog--short' });
    } else {
      this.modalRef?.hide();
    }
  };

  additionalCheckboxConfrimationTitle: string | undefined = undefined;
  additionalCheckboxConfrimationCheckboxLabel: string | undefined = undefined;
  modalRef!: BsModalRef;
  additionalConfirmationControl = new FormControl<boolean>(false);

  private destroyRef = inject(DestroyRef)

  constructor(private generalConfirmService: GeneralConfirmService,
              private modalService: BsModalService) {
    this.confirmDataSubscription();
  }

  closeModal(): void {
    this.modalRef?.hide();
    this.closeModalEvent.emit();
  }

  applyAction(): void {
    this.generalConfirmService.setApplyActionData(
      this.additionalCheckboxConfrimationTitle &&  this.additionalCheckboxConfrimationCheckboxLabel ? 
      {...this.data, additionalConfirmation: this.additionalConfirmationControl.value } : 
      this.data
    );
    this.closeModal();
    this.generalConfirmService.setGeneralConfirmData(null);

    this.applyActionEvent.emit();
  }
  
  private confirmDataSubscription(): void {
    this.generalConfirmService.getGeneralConfirmData().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: GeneralConfirmData | null) => {
      if (res) {
        this.title = res.title;
        this.description = res.description;
        this.applyButtonText = res.applyButtonText;
        this.data = res.data ? res.data : null;
        this.modalRef = this.modalService.show(this.generalConfirmTemplate, { class: 'modal-dialog--short' }); 
        this.additionalCheckboxConfrimationTitle = res.additionalCheckboxConfrimationTitle;
        this.additionalCheckboxConfrimationCheckboxLabel = res.additionalCheckboxConfrimationCheckboxLabel;
      }
    })
  }
}
