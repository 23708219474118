import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

export interface CookiesSettings {
  analytics: boolean,
  tagManager: boolean,
  hotjar: boolean,
  vwo: boolean
}

@Component({
  selector: 'app-cookies-settings-modal',
  templateUrl: './cookies-settings-modal.component.html',
  styleUrls: ['./cookies-settings-modal.component.scss']
})
export class CookiesSettingsModalComponent implements OnInit {
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cookiesSettings: EventEmitter<CookiesSettings> = new EventEmitter<CookiesSettings>();
  cookiesSettingsForm!: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.buildForm()
  }

  buildForm(): void {
    this.cookiesSettingsForm = this.fb.group({
      analytics: [true],
      tagManager: [true],
      hotjar: [true],
      vwo: [true]
    });

    this.cookiesSettingsForm.get('analytics')?.disable();
    this.cookiesSettingsForm.get('tagManager')?.disable();
  }

  onClose(): void {
    this.closeModal.emit(true)
  }

  saveCookiesSettings(): void {
    this.cookiesSettings.emit(this.cookiesSettingsForm.getRawValue());
    this.onClose();
  }
}
