import { ValidatorFn } from '@angular/forms';

import { TableItemActionInterface } from './table-item-action.interface';

export interface TableItemInterface {
  name: string,
  databaseName: string,
  isSortable: boolean,
  sortOptions: string[],
  width: string,
  type: 'info' | 'edit' | 'actions' | 'status' | 'status-edit' | 'select',
  infoHeaderTooltip?: string,
  orderNumber?: number,
  showInTable?: boolean,
  actions?: TableItemActionInterface[],
  pinnedLeft?: boolean,
  pinnedRight?: boolean,
  customTemplate?: (item: any) => string,
  validators?: ValidatorFn[],
  selectOptions?: {[key: string]: string}[],
  tooltip?: (item: any) => string
}
