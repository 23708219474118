import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iterableNumber'
})
export class IterableNumberPipe implements PipeTransform {

  transform(value: number = 1): number[] {
    return new Array(value).fill(0).map((_: any, i: number) => i);
  }
}
