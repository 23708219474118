<div class="table-list"
     [class.table-list--with-checkboxes]="withCheckboxes"
     (scroll)="onScroll($event)">
  <ng-container *ngFor="let item of list">
    <div *ngIf="tableItems.length"
         class="table-list__item"
         [class.table-list__item--checked]="item['checked']"
         [class.table-list__item--error]="isItemHasError!(item)">
      <div *ngIf="withCheckboxes" class="table-list__cell">
        <div class="table-list__checkbox-container checkbox-container">
          <input type="checkbox"
                 [(ngModel)]="item['checked']"
                 (click)="checkItem(item)"
                 [disabled]="disableCheckboxes!">
        </div>
      </div>
      <ng-container *ngFor="let tableItem of tableItems">
        <div class="table-list__cell"
             [ngStyle]="getCellStyles(tableItem)"
             [ngClass]="getCellClasses(tableItem)">
          <ap-ui-table-cell [tableItem]="tableItem"
                            [item]="item"
                            [statuses]="statuses"
                            (focusOut)="focusOutEmitter.emit($event)"
                            (cellActionEmitter)="cellActionEmitter.emit($event)"
                            (onClick)="emitClickEvent($event)">
            <ng-container *ngIf="getActionListForTableItem(item).length && getActionListForTableItem(item).length >= 4 && tableItem.name === 'Actions'">
              <div class="flex align-items-center pl--14" (mouseleave)="itemWithAdditionalItemsExpanded = null">
                <ng-container *ngIf="!(itemWithAdditionalItemsExpanded && item['id'] === itemWithAdditionalItemsExpanded.id)">
                  <i class="icon-more-vertical mr--8 fs-20" (mouseenter)="expandIcons(item)"></i>
                  <ng-container *ngFor="let actionItem of getActionListForTableItem(item).slice(getActionListForTableItem(item).length - 2, getActionListForTableItem(item).length)">
                    <i class="mr--8 fs-20"
                       [class]="'icon-' + actionItem.icon + ((actionItem.disabled && actionItem.disabled(item)) ? ' icon--disabled' : '' )"
                       [tooltip]="getActionTooltip(actionItem, item) | translate"
                       [placement]="getActionTooltipPlacement(actionItem, item)"
                       (click)="emitActionItem(actionItem, item, $event)"></i>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="itemWithAdditionalItemsExpanded && item['id'] === itemWithAdditionalItemsExpanded.id">
                  <ng-container *ngFor="let actionItem of getActionListForTableItem(item)">
                    <i class="mr--8 fs-20"
                       [class]="'icon-' + actionItem.icon + ((actionItem.disabled && actionItem.disabled(item)) ? ' icon--disabled' : '' )"
                       [tooltip]="getActionTooltip(actionItem, item) | translate"
                       [placement]="getActionTooltipPlacement(actionItem, item)"
                       (click)="emitActionItem(actionItem, item, $event)"></i>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="getActionListForTableItem(item).length && getActionListForTableItem(item).length < 4 && tableItem.name === 'Actions'">
              <div class="flex align-items-center pl--14">
                <i *ngFor="let actionItem of getActionListForTableItem(item)"
                    class="mr--8 fs-20"
                    [class]="'icon-' + actionItem.icon + ((actionItem.disabled && actionItem.disabled(item)) ? ' icon--disabled' : '' )"
                    [tooltip]="getActionTooltip(actionItem, item) | translate"
                    [placement]="getActionTooltipPlacement(actionItem, item)"
                    (click)="emitActionItem(actionItem, item, $event)">
                </i>
              </div>
            </ng-container>
          </ap-ui-table-cell>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div *ngIf="checkedList.length && commonActionListForTableItems.length" class="table-list__common-actions">
    <div class="table-list__common-actions__section">
      <div class="table-list__common-actions__count">
        {{checkedList.length + ' ' + ('Superadmin.Selected' | translate)}}
      </div>
      <div class="table-list__common-actions__deselect" (click)="deselect()">{{'Deselect' | translate}}</div>
    </div>
    <div *ngFor="let action of commonActionListForTableItems"
         class="table-list__common-actions__section"
         [class.table-list__common-actions__section--disabled]="action.disabled && action.disabled(this.checkedList)"
         (click)="commonActionEmitter.emit({action, items: checkedList})">
      <i [class]="'icon-' + action.icon + ' fs-22'"></i>
      <span>{{action.text | translate}}</span>
    </div>
  </div>
</div>
