import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { headers } from '../../shared/helpers/headers.helper';

@Injectable({
  providedIn: 'root'
})

export class HelpService {
  private url = environment.baseUrl + '/profrakt';
  _isSidebarClosed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  notifyMe(): Observable<any> {
    let data = {};
    return this.http.post(`${this.url}/user/me/notify/`, data, { headers: headers() });
  }

  setIsSidebarClosed(value: boolean): void {
    this._isSidebarClosed.next(value);
  }
}
