import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  private EDIUrl = environment.baseEDIUrl;

  constructor(private http: HttpClient) {}

  sendFile(body: FormData, api_key: string, sender_id: number): Observable<null> {
    const headers = {
      'X-Profrakt-Key': api_key,
      'X-Profrakt-Sender': sender_id.toString()
    }
    
    return this.http.post<null>(`https://edi.no/consignments/csv`, body, { headers: new HttpHeaders(headers) })
  }
}
