import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { User } from '../../services/user-service/user.interface';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    const user: User = JSON.parse(localStorage.getItem('user')!);
    if (user.platform_id == 3 && !user.first_name && !user.last_name) {
      this.router.navigate(['/welcome'])
      return false
    }
    else {
      return true
    }
  }
}
