import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PageChangedEvent } from 'ngx-bootstrap/pagination';

import { TableItemInterface } from './interfaces/table-item.interface';
import { TableItemActionInterface } from './interfaces/table-item-action.interface';
import { TableStatusInterface } from './interfaces/table-status.interface';
import { CellActionEmitDataInterface } from './interfaces/cell-action-emit-data.interface';
import { TableCommonActionInterface } from './interfaces/table-common-action.interface';

@Component({
  selector: 'ap-ui-table',
  templateUrl: './ui-table.component.html',
  styleUrls: ['./ui-table.component.scss']
})
export class UiTableComponent {

  @Input() tableItems: TableItemInterface[] = []
  @Input() set list(list: { [key: string]: any }[]) {
    this._list = list || []
    this.initListWithCheckedValues()
  }
  @Input() actions: TableItemActionInterface[] = []
  @Input() commonActions: TableCommonActionInterface[] = []
  @Input() withCheckboxes: boolean = false
  @Input() totalItemsCount: number = 0
  @Input() activePage: number = 1
  @Input() activeSortType: string = ''
  @Input() itemsPerPageValues: number[] = [];
  @Input() itemsPerPage!: number;
  @Input() statuses: TableStatusInterface[] = [];
  @Input() hideHeader: boolean = false;
  @Input() hideFooter: boolean = false;
  @Input() unselectItems: boolean = false;
  @Input() disableCheckboxes?: boolean = false;
  @Input() isItemHasError?: (item: any) => boolean = () => false;
  @Input() itemsLimit?: number;

  @Output() actionEmitter: EventEmitter<{ action: TableItemActionInterface, item: any }> = new EventEmitter<{ action: TableItemActionInterface, item: any }>()
  @Output() focusOutEmitter: EventEmitter<{ item: any; databaseName: string, value: any }> = new EventEmitter<{ item: any; databaseName: string, value: any }>()
  @Output() pageChangedEmitter: EventEmitter<PageChangedEvent> = new EventEmitter<PageChangedEvent>()
  @Output() itemsPerPageChangedEmitter: EventEmitter<number> = new EventEmitter<number>()
  @Output() cellActionEmitter: EventEmitter<CellActionEmitDataInterface> = new EventEmitter<CellActionEmitDataInterface>()
  @Output() commonActionEmitter: EventEmitter<{ action: TableCommonActionInterface, items: any[] }> = new EventEmitter<{ action: TableCommonActionInterface, items: any[] }>()
  @Output() sortOptionEmitter: EventEmitter<string> = new EventEmitter<string>()
  @Output() onClick: EventEmitter<{tableItem: TableItemInterface, item: any}> = new EventEmitter<{tableItem: TableItemInterface, item: any}>()
  @Output() resizeColumnEmitter: EventEmitter<{tableItem: TableItemInterface, width: number}> = new EventEmitter<{tableItem: TableItemInterface, width: number}>()
  @Output() selectedItemsEmitter: EventEmitter<{ [key: string]: any }[]> = new EventEmitter<{ [key: string]: any }[]>()

  scrollPosition: {scrollLeft: number, scrollRight: number} = {scrollLeft: 0, scrollRight: 1000};
  selectAll: boolean = false;
  _list: { [key: string]: any }[] = []

  constructor() {}

  get calculateShowingItems(): string {
    if (this.totalItemsCount > this.itemsPerPage) {
      const start = this.itemsPerPage * (this.activePage - 1) + 1
      const end = this.itemsPerPage * this.activePage
      return (start + '-' + end).toString()
    }

    return '1-' + this.totalItemsCount.toString();
  }

  getHeaderItemClasses(item: TableItemInterface): {[key: string]: boolean} {
    return {
      'ui-table__header-item--sortable': item.isSortable,
      'ui-table__header-item--pinned-left': item.pinnedLeft || false,
      'ui-table__header-item--pinned-right': item.pinnedRight || false,
      'ui-table__header-item--scrolled-left': (item.pinnedLeft || false) && this.scrollPosition.scrollLeft > 0,
      'ui-table__header-item--scrolled-right': (item.pinnedRight || false) && this.scrollPosition.scrollRight > 1
    }
  }

  onScroll($event: Event): void {
    this.scrollPosition = {
      scrollLeft: ($event.target as HTMLElement).scrollLeft,
      scrollRight: ($event.target as HTMLElement).scrollWidth - ($event.target as HTMLElement).clientWidth - ($event.target as HTMLElement).scrollLeft
    }
  }

  onActionEmits($event: { action: TableItemActionInterface; item: any }): void {
    this.actionEmitter.emit($event)
  }

  handlePageChange($event: PageChangedEvent): void {
    if (this.activePage !== $event.page) {
      this.pageChangedEmitter.emit($event)
    }
  }

  setActiveSortType(item: TableItemInterface): void {
    if (item.isSortable) {
      this.activeSortType = this.activeSortType === item.sortOptions[0] ? item.sortOptions[1] : item.sortOptions[0]
      this.sortOptionEmitter.emit(this.activeSortType)
    }
  }

  emitClickEvent($event: {tableItem: TableItemInterface, item: any}): void {
    this.onClick.emit({tableItem: $event.tableItem, item: $event.item})
  }

  onColumnResized($event: number, item: TableItemInterface): void {
    this.resizeColumnEmitter.emit({tableItem: item, width: $event})
  }

  private initListWithCheckedValues(): void {
    this._list = this._list.length ? this._list.map(item => {
      return {...item, checked: false}
    }) : []
  }
}
