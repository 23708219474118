<div class="modal__container">
  <div class="modal__header">
    <div class="modal__title">{{ 'Image gallery' }}</div>
    <i class="icon-close-stroke fs-24 pointer" (click)="close.emit()"></i>
  </div>
  <div class="modal__body">
    <div class="images-gallery" *ngIf="images.length">
      <div class="images-gallery__preview-section">
        <div class="images-gallery__preview-container"
             *ngFor="let image of images"
             [class.images-gallery__preview-container--active]="activeImage === image"
             (click)="activeImage = image">
          <img [src]="image" alt="Image">
        </div>
      </div>
      <div class="images-gallery__active-image" *ngIf="activeImage">
        <img [src]="activeImage" alt="Active image">
        <div class="images-gallery__active-image-left" (click)="prevImage()">
          <i class="icon-arrow-left-stroke fs-24"></i>
        </div>
        <div class="images-gallery__active-image-right" (click)="nextImage()">
          <i class="icon-arrow-right-stroke fs-24"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="modal__footer">
    <button class="button button--height-40" (click)="close.emit()">Close</button>
  </div>
</div>
