<form class="upload-form" [formGroup]="fileUploadForm" *ngIf="fileUploadForm">
  <ng-container *ngIf="activeClient">
    <div class="upload-form__title">
      Upload File
    </div>
    <div class="upload-form__upload-section">
      <div class="upload-form__upload-title">
        Drag and drop your files here
      </div>
      <input type="file" #fileInput (change)="fileChanged($event)"
             accept=".csv, text/csv">
    </div>
    <div class="upload-form__attachments" *ngIf="attachment">
      <div class="upload-form__attachment">
        <img [src]="attachment.url" *ngIf="attachment.type.includes('image')">
        <div class="upload-form__attachment-type" *ngIf="!attachment.type.includes('image')">
          {{ attachment.name }}
        </div>
      </div>
    </div>
    <div class="upload-form__section">
      <div class="upload-form__section-title">
        Address
      </div>
      <ng-select class="dropdown dropdown--outlined-gray upload-form__select"
                 [items]="clientAddresses" bindValue="managership_id"
                 [clearable]="false" [searchable]="false"
                 formControlName="address" placeholder="Choose address">
        <ng-template ng-label-tmp let-item="item">
          <ng-container *ngIf="item">
            {{ (item.name || activeClient.name) + ' - ' + item.address_line1 + ', ' + item.postal_code + (item.city ? (' ' + item.city) : '')}}
          </ng-container>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div [title]="item.name + ' - ' + item.address_line1 + ', ' + item.postal_code + (item.city ? (', ' + item.city) : '')">
            {{ (item.name || activeClient.name) + ' - ' + item.address_line1 + ', ' + item.postal_code + (item.city ? (' ' + item.city) : '')}}
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="upload-form__section">
      <div class="upload-form__section-title">
        Agreement
      </div>
      <ng-select class="dropdown dropdown--outlined-gray upload-form__select"
                 [items]="agreements" bindValue="id"
                 [clearable]="false" [searchable]="false"
                 formControlName="agreement" placeholder="Choose agreement">
        <ng-template ng-label-tmp let-item="item">
          <ng-container *ngIf="item">
            {{ item.carrier.name + ' | ID: ' + item.id }}
          </ng-container>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div [title]="item.carrier.name + ' | ID: ' + item.id">
            {{ item.carrier.name + ' | ID: ' + item.id }}
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="upload-form__section">
      <div class="upload-form__section-title">
        Email
      </div>
      <div class="ticket-form__input input">
        <input type="text" formControlName="email" placeholder="Enter email">
      </div>
    </div>
    <div class="upload-form__section">
      <div class="upload-form__section-title">
        Printer (optional)
      </div>
      <ng-select class="dropdown dropdown--outlined-gray upload-form__select"
                 [items]="printers" bindLabel="alias" bindValue="id"
                 [clearable]="true" [searchable]="false"
                 formControlName="printer" placeholder="Choose printer">
      </ng-select>
    </div>
    <div [tooltip]="(!attachment || !fileUploadForm.valid) ? 'Select file & enter valid email' : ''">
      <button class="button button--contained-black"
              (click)="attachment ? sendFile() : null"
              [disabled]="!attachment || !fileUploadForm.valid">
        Send
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!activeClient">
    Please select client in Header
  </ng-container>
</form>
