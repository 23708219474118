export enum TranslateSectionsEnum {
  APP = 'APP',
  AUTH = 'AUTH',
  CONTACT_BOOK = 'CONTACT_BOOK',
  DASHBOARD = 'DASHBOARD',
  ITEM_INVENTORY = 'ITEM_INVENTORY',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
  MANAGE_PRINTERS = 'MANAGE_PRINTERS',
  MANAGE_CLIENTS = 'MANAGE_CLIENTS',
  MANAGE_USERS = 'MANAGE_USERS',
  ONBOARDING = 'ONBOARDING',
  PRODUCTS = 'PRODUCTS',
  SETTINGS = 'SETTINGS',
  SHIPMENTS = 'SHIPMENTS',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  ORDERS = 'ORDERS',
  MANAGE_PROFILE = 'MANAGE_PROFILE',
  INVOICES = 'INVOICES',
  SALES_ORDERS = 'SALES_ORDERS',
  PICKUP_BOOKING = 'PICKUP_BOOKING',
}
