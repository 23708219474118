<div class="ui-table"
     [class.ui-table--with-checkboxes]="withCheckboxes"
     [class.ui-table--without-header]="hideHeader"
     [class.ui-table--without-footer]="hideFooter">
  <div class="ui-table__content"
       (scroll)="onScroll($event)">
    <div *ngIf="!hideHeader" class="ui-table__header">
      <div *ngIf="withCheckboxes" class="ui-table__header-item">
        <div class="checkbox-container">
          <input type="checkbox" [(ngModel)]="selectAll" [disabled]="disableCheckboxes!">
        </div>
      </div>
      <ng-container *ngFor="let item of tableItems">
        <div class="ui-table__header-item elipsis"
             resizeColumn
             [ngClass]="getHeaderItemClasses(item)"
             [style]="'width: ' + item.width + ';'"
             (resize)="onColumnResized($event, item)"
             (click)="setActiveSortType(item)">
          <div class="width-full elipsis" translate>
            {{item.name}}
          </div>
          <i *ngIf="item.infoHeaderTooltip"
             class="icon-info-circle ui-table__info-icon"
             [tooltip]="item.infoHeaderTooltip"></i>
          <i *ngIf="item.isSortable"
             [class.rotate-180deg]="activeSortType === item.sortOptions[1]"
             class="icon-arrow-down-stroke ui-table__arrow-icon"></i>
          <div class="ui-table__header-drag"></div>
        </div>
      </ng-container>
    </div>
    <ap-ui-table-list class="ui-table__list"
                      [list]="_list"
                      [tableItems]="tableItems"
                      [scrollPosition]="scrollPosition"
                      [actions]="actions"
                      [commonActions]="commonActions"
                      [withCheckboxes]="withCheckboxes"
                      [selectAll]="selectAll"
                      [statuses]="statuses"
                      [isItemHasError]="isItemHasError"
                      [unselectItems]="unselectItems"
                      [disableCheckboxes]="disableCheckboxes"
                      (actionEmitter)="onActionEmits($event)"
                      (commonActionEmitter)="commonActionEmitter.emit($event)"
                      (focusOutEmitter)="focusOutEmitter.emit($event)"
                      (cellActionEmitter)="cellActionEmitter.emit($event)"
                      (deselectEmitter)="selectAll = false"
                      (onClick)="emitClickEvent($event)"
                      (selectedItemsEmitter)="selectedItemsEmitter.emit($event)">
      </ap-ui-table-list>
  </div>

  <div *ngIf="!hideFooter" class="ui-table__footer">
    <div class="flex align-items-center">
      <div class="ui-table__footer-results mr--16">
        <span class="ui-table__footer-results--black">
          {{ 'Showing' | translate }}
          {{ calculateShowingItems }}
        </span>
        {{ 'of' | translate}}
        {{ totalItemsCount.toLocaleString('de-DE') }} {{ 'results' | translate}}
      </div>
      <ng-select *ngIf="totalItemsCount > itemsPerPageValues[0]" class="dropdown dropdown--outlined-gray dropdown--height-36"
                 appendTo="body"
                 [items]="itemsPerPageValues"
                 [(ngModel)]="itemsPerPage"
                 [clearable]="false"
                 [searchable]="false"
                 (ngModelChange)="itemsPerPageChangedEmitter.emit($event)">
        <ng-template ng-label-tmp let-item="item">
          {{ item }}
          <span class="table-footer__selection-text">{{ 'per page' | translate}}</span>
        </ng-template>
      </ng-select>
    </div>
    <pagination *ngIf="totalItemsCount > itemsPerPage"
                [totalItems]="itemsLimit ? itemsLimit : totalItemsCount"
                [itemsPerPage]="itemsPerPage"
                [(ngModel)]="activePage"
                [maxSize]="10"
                [boundaryLinks]="true"
                (pageChanged)="handlePageChange($event)"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;">
    </pagination>
  </div>
</div>
