import { Directive, Input, ElementRef, HostListener, Renderer2, OnInit, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { finalize, Observable, Subject } from 'rxjs';

@Directive({
  selector: '[tooltip]',
})

export class TooltipDirective implements OnInit {
  private _clicked$ = new Subject<MouseEvent>();
  @Input('tooltip') tooltipTitle!: string;
  @Input() placement: 'top' | 'bottom' | 'left' | 'right' | 'bottom_right' = 'top';
  @Input() delay: string | number = 1;
  @Input() offset: string | number = 0;
  @Input() hideOnClick: boolean = false;
  @Input() nowrap: boolean = false;
  @Input() tooltipWidth: number = 0;
  tooltip: HTMLElement | any;
  triangle: HTMLElement | any;
  private destroyRef = inject(DestroyRef);
  private clickTarget!: HTMLElement;

  constructor(private el: ElementRef,
              private renderer: Renderer2,) {}

  @HostListener('click', ['$event']) onClicked(event: Event) {
    this.clickTarget = event.target as any;
    this.hide();
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip)
      this.show();
  }

  @HostListener('mousedown') onMouseDown() {
    this.hide();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hide();
    // for test: to be sure tooltip is hided
    this.hide();
    // to test: to be sure tooltip is hided
  }

  public get clicked$(): Observable<MouseEvent> {
    return this._clicked$.asObservable();
  }

  public onClick(event: MouseEvent): void {
    this._clicked$.next(event);
  }

  ngOnInit(): void {
    this.clicked$.pipe(
      finalize(() => this.hide()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(event => {
      const target = event.target as HTMLElement;
      if (target === this.clickTarget) {
        return;
      }
      if (!target.contains(this.el.nativeElement)) {
        this.hide();
      }
    })
  }

  show() {
    if (!this.tooltip) {
      this.create();
    }

    this.setPosition();

  }

  hide() {
    if (!this.tooltip) {
      return;
    }

    this.renderer.removeClass(this.tooltip, 'tooltip-show');
    this.renderer.removeChild(document.body, this.tooltip);
    this.tooltip = null;
  }

  create() {
    this.tooltip = this.renderer.createElement('div');
    this.tooltip.innerHTML = this.tooltipTitle;

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'tooltip');

    if (this.tooltipWidth) {
      this.renderer.addClass(this.tooltip, 'w--' + this.tooltipWidth);
    }

    this.tooltip.innerHTML = this.tooltipTitle;
    this.renderer.addClass(this.tooltip, `tooltip-${this.placement}`);
    this.triangle = this.renderer.createElement('div');
    this.renderer.appendChild(this.tooltip, this.triangle);
    this.renderer.addClass(this.triangle, 'tooltip__triangle');
    this.renderer.addClass(this.triangle, `tooltip__triangle--${this.placement}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ease-in-out ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ease-in-out ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ease-in-out ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ease-in-out ${this.delay}ms`);

    if (this.nowrap)  {
      this.renderer.addClass(this.tooltip, 'tooltip--nowrap');
    }

    if (!this.tooltipTitle?.length) {
      this.renderer.addClass(this.tooltip, 'd-none');
      this.hide();
    }
  }

  setPosition() {
    if (this.tooltip) {
      const hostPos = this.el.nativeElement.getBoundingClientRect();
      const tooltipPos = this.tooltip.getBoundingClientRect();
      const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      let top, left, triangleRight, triangleBottom;

      switch (this.placement) {
        case 'top':
          top = hostPos.top - tooltipPos.height - 9 - Number(this.offset);
          left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
          triangleRight = (tooltipPos.width / 2) - 7;
        break;

        case 'bottom':
          top = hostPos.bottom + 9 + Number(this.offset);
          left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
          triangleRight = (tooltipPos.width / 2) - 7;
        break;

        case 'bottom_right':
          top = hostPos.bottom + 9 + Number(this.offset);
          left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
          triangleRight = (tooltipPos.width / 2) - (tooltipPos.width / 3);
        break;

        case 'left':
          top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
          left = hostPos.left - tooltipPos.width - 9 - Number(this.offset);
          triangleBottom = (tooltipPos.height / 2) - 7;
        break;

        case 'right':
          top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
          left = hostPos.right + 9 + Number(this.offset);
          triangleBottom = (tooltipPos.height / 2) - 7;
        break;

        default:
          this.hide();
      }

      this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
      this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
      this.renderer.setStyle(this.triangle, 'right', `${triangleRight}px`);
      this.renderer.setStyle(this.triangle, 'bottom', `${triangleBottom}px`);

      this.renderer.addClass(this.tooltip, 'tooltip-show');
    }
  }
}
