import { Component } from '@angular/core';

@Component({
  selector: 'ap-access-restricted',
  templateUrl: './access-restricted.component.html',
  styleUrls: ['./access-restricted.component.scss']
})

export class AccessRestrictedComponent {

}