import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { AvatarSizesEnum } from './enums/avatar-sizes.enum';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'ap-ui-avatar',
  templateUrl: './ui-avatar.component.html',
  styleUrls: ['./ui-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiAvatarComponent {

  @Input() size: keyof typeof AvatarSizesEnum = 'medium';
  @Input() src: string = '';
  @Input() placeholderLetters: string = '';

  @HostBinding('class')
  get avatarClass(): string {
    return 'avatar--' + this.size;
  }

  constructor(private authService: AuthService) { }

  get avatarIconSize(): string {
    switch (this.size) {
      case 'small':
        return '10'
      case 'medium':
        return '16'
      case 'large':
        return '24'
      case 'extraLarge':
        return '32'
    }
  }

  // TEMPORARY
  getMe() {
    this.authService.getMe().subscribe()
  }
  // TEMPORARY
}
