import { Notification, NotificationType } from "../models/notifications.model";
import { NotificationsService } from "src/app/services/notifications-service/notifications.service";

export class NotificationsHelpClass {
  notificationsCounter!: number;
  showNotifications!: boolean;
  notifications: Notification[] = [];
  constructor(public notificationsService: NotificationsService) {}

  getNotificationIcon(type: NotificationType): string {
    let icon_name = '';
    switch(type) {
      case NotificationType.SUPPORT_TICKET:
        icon_name = 'customer-service'
      break;
      
      case NotificationType.SUPPORT_TICKET_COMMENT:
        icon_name = 'chat'
      break;

      case NotificationType.PACKAGES_REPORT:
        icon_name = 'logistics'
      break;
    }

    return icon_name;
  }

  markNotificationAsRead(id: string): void {
    let subscription = this.notificationsService.markNotificationAsRead(id).subscribe(() => subscription.unsubscribe)
  }

  markAllAsRead(): void {
    let subscription = this.notificationsService.markAllAsRead().subscribe(() => {
      this.notificationsCounter = 0;
      this.showNotifications = false;
      this.notifications = [];
      subscription.unsubscribe;
    })
  }

  onRemoveNotification(id: string): void {
    this.notifications = this.notifications.filter(n => n.id !== id);
  }

  navigationRouteFromNotification(notification: Notification): string {
    let route = '';

    switch(notification.type) {
      case NotificationType.SUPPORT_TICKET:
      case NotificationType.SUPPORT_TICKET_COMMENT:
      route = 'customer-service'
      break;
    }
    return route;
  }

  downloadFile(notification: Notification, e?: Event): void {
    e?.preventDefault();
    e?.stopPropagation();

    window.open(notification.file_url);
    this.markNotificationAsRead(notification.id);
  }
}