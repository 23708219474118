<div class="main-container" *ngIf="!isMobilePlatform">
  <div class="main-container__general-notification-stripe" *ngIf="currentUser">
    <ap-ui-general-notification-stripe></ap-ui-general-notification-stripe>
  </div>
  <div class="main">
    <ap-sidebar *ngIf="!hidePanel && currentUser && (isLoggedIn$ | async) && sidebarVisibleCondition"
                (isSidebarClosedForEmit)="checkSidebarOpenedState($event)"></ap-sidebar>
    <div class="main__container"
         [class.ml--72]="isSidebarClosed"
         [class.main__container--no-scroll]="urlAfterRedirects.includes('shipments') ||
                                             urlAfterRedirects.includes('products') ||
                                             urlAfterRedirects.includes('manage-users') ||
                                             urlAfterRedirects.includes('manage-clients') ||
                                             urlAfterRedirects.includes('contact-book') ||
                                             urlAfterRedirects.includes('dashboard') ||
                                             urlAfterRedirects.includes('manage-translates')"
         [class.main__container--p-0]="(!currentUser || urlAfterRedirects.includes('page-not-found') || urlAfterRedirects.includes('auth')) && !(isLoggedIn$ | async)">
      <div class="main__head" *ngIf="!hidePanel && currentUser && (isLoggedIn$ | async)">
        <app-header (clientDisabled)="clientDisabled = $event"></app-header>
      </div>
      <div class="main__content" [class.main__content--less-height]="clientDisabled">
        <ap-ui-loader *ngIf="isLoading"></ap-ui-loader>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <!-- Hide by Stein request 11.03.2024 -->
  <!-- <div class="main__cookies-section cookies" *ngIf="showCookiesBanner && environment.production">
    <div class="cookies__info">
      <i class="icon-cookies fs-32 fc-white mr--8"></i>
      <div class="cookies__description">
        {{'Cookies.Note' | translate}}
        <span *ngIf="translate.currentLang === 'no'" class="cookies__link" routerLink="legals/cookies-policy">Informasjonskapselinformasjon.</span>
        <span *ngIf="translate.currentLang === 'en'" class="cookies__link" routerLink="legals/cookies-policy">Cookie Notice & Privacy.</span>
      </div>
    </div>
    <div class="cookies__buttons">
      <ap-ui-button-link [text]="'Cookies.Settings' | translate"
                         (click)="openModal(cookiesSettingsModal)"
                         class="mr--16 cookies__btn-link">
      </ap-ui-button-link>
      <button class="cookies__ok-button button button--contained-white button--height-40" (click)="allowCookies()">
        {{'Cookies.Allow cookies' | translate}}
      </button>
    </div>
  </div> -->
  <!-- Hide by Stein request 11.03.2024 -->
</div>

<!-- Hide by Stein request 11.03.2024 -->
<!-- <ng-template #cookiesSettingsModal>
  <app-cookies-settings-modal (closeModal)="onCloseModal()" (cookiesSettings)="onSaveCookiesSettings($event)"></app-cookies-settings-modal>
</ng-template> -->
<!-- Hide by Stein request 11.03.2024 -->

<ng-container *ngIf="isMobilePlatform">
  <div class="mobile-notification pr--10 pl--10">
    <div class="mobile-notification__image" [class.mobile-notification__email-confirmed-image]="justPassedEmailVerification"></div>
    <span class="fs-38 bold mb--20" *ngIf="!justPassedEmailVerification">
      {{ 'Mobile.Upps' | translate }}...
    </span>
    <span class="fs-38 bold mb--20" *ngIf="justPassedEmailVerification">
      {{ 'Mobile.You successfully confirmed Email, but' | translate }}...
    </span>
    <div class="mobile-notification__description mb--20">
      {{ 'Mobile.Adminpanel service is currently available on desktop only. Mobile version coming soon.' | translate }}
    </div>
    <button class="button button--contained-black"
            [disabled]="disableNotifyButton" (click)="notify()">
      {{ 'Mobile.Notify me' | translate }}
    </button>
  </div>
</ng-container>

<ap-ticket-form *ngIf="currentUser"></ap-ticket-form>
