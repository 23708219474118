<div class="cookies">
  <div class="cookies__title">
    Cookies settings
  </div>
  <form [formGroup]="cookiesSettingsForm">
    <div class="cookies__section checkbox-container">
      <input type="checkbox" formControlName="analytics" id="analytics">
      <label for="analytics">Google Analytics</label>
    </div>
    <div class="cookies__section checkbox-container">
      <input type="checkbox" formControlName="tagManager" id="tagManager">
      <label for="tagManager">Google Tag Manager</label>
    </div>
    <div class="cookies__section checkbox-container">
      <input type="checkbox" formControlName="hotjar" id="hotjar">
      <label for="hotjar">Hotjar</label>
    </div>
    <div class="cookies__section checkbox-container">
      <input type="checkbox" formControlName="vwo" id="vwo">
      <label for="vwo">VWO</label>
    </div>
  </form>
  <div class="cookies__footer">
    <button class="cookies__cancel-button button button--contained-gray button--height-40"
            (click)="onClose()" translate>
      Cancel
    </button>
    <button class="cookies__save-button button button--contained-black button--height-40"
            (click)="saveCookiesSettings()" translate>
      Save
    </button>
  </div>
</div>