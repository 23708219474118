import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { PlaceholderLoaderElementInterface } from '../../interfaces/placeholder-loader-element.interface';

@Component({
  selector: 'ap-ui-rectangle',
  templateUrl: './ui-rectangle.component.html',
  styleUrls: ['./ui-rectangle.component.scss']
})
export class UiRectangleComponent implements OnInit {

  @Input() rectangle!: Partial<PlaceholderLoaderElementInterface>;

  @HostBinding('style.width') width!: string;
  @HostBinding('style.margin-left') marginLeft!: string;

  ngOnInit(): void {
    this.width = (this.rectangle?.width?.value as string) ?? '100%';
    this.marginLeft = this.rectangle?.margin?.left ?? '';
  }

}
