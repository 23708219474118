import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ClientAdditionalInfo, OnboardingFormDataInterface } from '../../modules/onboarding/interfaces/onboarding-form-data.interface';
import { headers } from '../../shared/helpers/headers.helper';
import { LeadSources } from 'src/app/modules/superadmin/manage-clients/enums/edit-client-leads.enum';
import { ClientInterface } from 'src/app/modules/superadmin/manage-clients/interfaces/client.interface';
import { CarrierOnboardingPackageChoices, CarrierOnboardingSalesPersons } from 'src/app/modules/onboarding/enums/carrier-onboarding.enum';
import { CarrierPackagePlan } from 'src/app/modules/onboarding/interfaces/carrier-onboarding.interface';
import { DefaultResponse } from '../shared/interfaces/responses.interface';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private url = environment.baseUrl + '/profrakt';
  _clientForOnboarding: BehaviorSubject<ClientInterface | null> = new BehaviorSubject<ClientInterface | null>(null)

  constructor(private http: HttpClient) {}

  sendOnboardingFormData(formValue: OnboardingFormDataInterface, clientID: string | number, lead_source: LeadSources): Observable<any> {
    let onboarding_params = {
      service: formValue.service,
      pickupLocation: formValue.pickupLocation,
      mailbox: formValue.mailbox,
      deliveredHome: formValue.deliveredHome,
      business: formValue.business,
      generalCargoBusiness: formValue.generalCargoBusiness
    }

    const body = {
      onboarding_params : JSON.stringify(onboarding_params),
      lead_source,
      edi: formValue.edi,
      ecommerce_systems: formValue.ecomersSystem,
      inviter_number: parseInt(formValue.inviter_number)
    };

    return this.http.post(`${this.url}/clients/${clientID}/onboarding/`, body, { headers: headers()});
  }

  getOnboardingFormData(clientID: string | number): Observable<ClientAdditionalInfo> {
    return this.http.get<ClientAdditionalInfo>(`${this.url}/clients/${clientID}/onboarding/`,  { headers: headers()});
  }

  setClientForOnboarding(client: ClientInterface | null): void {
    this._clientForOnboarding.next(client)
  }

  clientForOnboarding(): Observable<ClientInterface | null> {
    return this._clientForOnboarding.asObservable()
  }

  // ---------  new onbloarding flow  ------------

  getCarrierOnboardingPlans(): Observable<CarrierPackagePlan[]> {
    return this.http.get<CarrierPackagePlan[]>(`${this.url}/carrier-onboarding-price/`, { headers: headers() });
  }

  sendCarrierOnboardingData(value: { service: string, packages_plan: CarrierOnboardingPackageChoices}, clientID: string | number | undefined): Observable<DefaultResponse> {
    const body = {
      onboarding_params : JSON.stringify(value)
    };

    return this.http.post<DefaultResponse>(`${this.url}/clients/${clientID}/onboarding/`, body, { headers: headers() });
  }

  contactWithSalesPerson(client_id: string | number, body: { sales_person: { name: CarrierOnboardingSalesPersons, id: number }}): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.url}/clients/${ client_id }/contact-with-sales-person/`, body, { headers: headers() });
  }
}
