<div class="avatar">

   <!-- TEMPORARY ERROR & GET ME -->
  <img *ngIf="src" [src]="src" alt="" (error)="getMe()">
  <!-- TEMPORARY ERROR & GET ME -->

  <div *ngIf="!src" class="avatar__placeholder">

    <div *ngIf="placeholderLetters" class="avatar__placeholder-letters">{{placeholderLetters}}</div>

    <i *ngIf="!placeholderLetters" class="icon-user" [ngClass]="'fs-' + avatarIconSize"></i>

  </div>

</div>
