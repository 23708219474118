import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { headers, logout_headers, refresh_token_headers } from '../../shared/helpers/headers.helper';
import { Observable, tap } from 'rxjs';
import { AuthAppService } from 'src/app/modules/auth/services/auth.app.service';
import { User } from 'src/app/services/user-service/user.interface';
import { 
  AuthLoginResponse,
  DefaultAuthResponse,
  RefreshTokenResponse,
  ResetPasswordServerResponse,
  SignUpEmailResponse,
  GetMeResponse
} from './auth.interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private url = environment.baseUrl + '/auth';

  constructor(private http: HttpClient,
              private router: Router,
              private authAppService: AuthAppService,
              private deviceService: DeviceDetectorService,
              private dbService: NgxIndexedDBService) {}

  registerUser(email: string): Observable<SignUpEmailResponse> {
    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'no';
    const body = { email, is_admin: false }
    return this.http.post<SignUpEmailResponse>(`${this.url}/user/sign-up/email?lang=${ lang }`, body);
  }

  setPassword(password: string, registration_id: string): Observable<DefaultAuthResponse> {
    const body = { password }
    return this.http.post<DefaultAuthResponse>(`${this.url}/user/sign-up/email/confirm/${registration_id}`, body)
  }

  login(email: string, password: string): Observable<AuthLoginResponse> {
    const body = { 
      email, 
      password: password, 
      device_type: 'web',
      device_id: this.deviceService.getDeviceInfo().os + '-' + this.deviceService.getDeviceInfo().browser
    }
    
    return this.http.post<AuthLoginResponse>(`${this.url}/user/login`, body).pipe(
      tap((res: AuthLoginResponse) => {
        let user: User = res.data;
        this.authAppService.setToken(res.data.token);
        this.authAppService.setCurrentUser(user);
      })
    )
  }

  logout(): Observable<DefaultAuthResponse> {
    return this.http.post<DefaultAuthResponse>(`${this.url}/user/logout`, {}, { headers: logout_headers() }).pipe(
      tap(() => {
        this.clearLocalStorageAndIndexedDB();
      })
    );
  }

  getMe(): Observable<GetMeResponse> {
    return this.http.get<GetMeResponse>(`${ environment.baseUrl }/user/profile`, { headers: headers() }).pipe(
      tap((res: GetMeResponse) => {
        this.authAppService.setCurrentUser(res.data);
      })
    );
  }

  refreshToken(): Observable<RefreshTokenResponse> {
    return this.http.post<RefreshTokenResponse>(`${this.url}/user/refresh-tokens`, {}, { headers: refresh_token_headers() }).pipe(
      tap((res: RefreshTokenResponse) => {
        localStorage.setItem('token', JSON.stringify(res.data));
        this.authAppService.setToken(res.data)
        return res.data;
      }
    ));
  }
  
  sendEmailToResetPassword(data: { email: string }): Observable<ResetPasswordServerResponse> {
    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'no';
    return this.http.post<ResetPasswordServerResponse>(`${this.url}/user/password/reset?lang=${ lang }`, data)
  }

  resetPassword(data: { password: string }, confirm_id: string): Observable<DefaultAuthResponse> {
    return this.http.patch<DefaultAuthResponse>(`${this.url}/user/password/reset/${ confirm_id }`, data)
  }

  changeEmail(body: { email: string }): Observable<DefaultAuthResponse> {
    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'no';
    return this.http.post<DefaultAuthResponse>(`${this.url}/user/email/change?lang=${ lang }`, body, { headers: headers() })
  }

  confirmChangeEmail(confirmation_id: string): Observable<DefaultAuthResponse> {
    return this.http.patch<DefaultAuthResponse>(`${this.url}/user/email/change/${ confirmation_id }`, { headers: headers() })
  }

  changePassword(body: { password: string }): Observable<DefaultAuthResponse> {
    return this.http.patch<DefaultAuthResponse>(`${this.url}/user/password/change`, body, {headers: headers() })
  }

  // superadmin add new user

  addNewUser(body: { email: string, first_name: string, last_name: string, phone_number: string, language: 'no' | 'en' }): Observable<DefaultAuthResponse> {
    return this.http.post<DefaultAuthResponse>(`${this.url}/support/user/registration?lang=${ body.language }`, body, { headers: headers() })
  }
  
  // superadmin add new user

  loginWithMagicID(magicID: string): Observable<AuthLoginResponse> {
    const body = { 
      device_type: 'web',
      device_id: this.deviceService.getDeviceInfo().userAgent
    }
    
    return this.http.post<AuthLoginResponse>(`${this.url}/user/login/magic-link?magic-id=${ magicID }`, body).pipe(
      tap((res: AuthLoginResponse) => {
        let user: User = res.data;
        this.authAppService.setToken(res.data.token);
        this.authAppService.setCurrentUser(user);
      })
    )
  }

  sendEmailToInviteMember(lang: string, body: {email: string, companies: {id: number, name: string}[]}): Observable<DefaultAuthResponse> {
    return this.http.post<DefaultAuthResponse>(`${this.url}/user/invite/email?lang=${ lang }`, body, { headers: headers() })
  }

  setPasswordForMember(body: {password: string}, registration_id: string, invited: string): Observable<DefaultAuthResponse> {
    return this.http.post<DefaultAuthResponse>(`${this.url}/user/invite/confirm/${registration_id}?invited=${invited}`, body, { headers: headers() })
  }

  clearLocalStorageAndIndexedDB(): void {
    let subscription$ = this.dbService.clear('translates').subscribe();
    subscription$.unsubscribe();

    localStorage.removeItem('userPermissions');
    localStorage.removeItem('clients');
    localStorage.removeItem('store_id');
    // localStorage.removeItem('activeClient');
    localStorage.removeItem('activeTeamMemberCompany');
    localStorage.removeItem('clientSettings');

    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.authAppService.setToken(null!);
    this.authAppService.setCurrentUser(null);
  }
}
