import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/services/user-service/user.interface';

@Injectable({
  providedIn: 'root',
})
export class ManagePermissionsGuard  {
  constructor(private router: Router) {}

  canLoad(): boolean {
    const user: User = JSON.parse(localStorage.getItem('user')!);
    
    if (user.is_admin) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
