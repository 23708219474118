import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableItemInterface } from '../ui/ui-table';
import { DropResult } from '@libertymp/ngx-smooth-dnd';

@Component({
  selector: 'ap-customize-table',
  templateUrl: './customize-table.component.html',
  styleUrls: ['./customize-table.component.scss']
})
export class CustomizeTableComponent {
  @Output() closeCustomizeTableSidebar = new EventEmitter<boolean>(false);
  @Output() userSettings = new EventEmitter<TableItemInterface[]>(false);
  @Output() resetTableView = new EventEmitter<boolean>(false);
  @Input() tableType: 'shipments' | 'products' | 'manage_users' = 'shipments';
  @Input() set tableItems(list: TableItemInterface[]) {
    this.notPinnedList = [...list.filter(i => !i.pinnedLeft && !i.pinnedRight).sort((a: TableItemInterface, b: TableItemInterface) => a.orderNumber! - b.orderNumber!).map(i => ({...i}))].map((item, index) => {
      item['orderNumber'] = index
      return item
    });

    this.pinnedLeftList = [...list.filter(i => i.pinnedLeft).sort((a: TableItemInterface, b: TableItemInterface) => a.orderNumber! - b.orderNumber!).map(i => ({...i}))].map((item, index) => {
      item['orderNumber'] = index
      return item
    });

    this.pinnedRightList = [...list.filter(i => i.pinnedRight).sort((a: TableItemInterface, b: TableItemInterface) => a.orderNumber! - b.orderNumber!).map(i => ({...i}))].map((item, index) => {
      item['orderNumber'] = index
      return item
    });
  }
  @Input() defaultTableItems: TableItemInterface[] = [];
  list: TableItemInterface[] = [];
  notPinnedList: TableItemInterface[] = [];
  pinnedLeftList: TableItemInterface[] = [];
  pinnedRightList: TableItemInterface[] = [];

  constructor() { }

  onDrop(dropResult: DropResult): void {
    this.notPinnedList = this.notPinnedList.map(item => {
      if (dropResult.to! > dropResult.from! && item.orderNumber! > dropResult.from! && item.orderNumber! <= dropResult.to!) {
        item['orderNumber'] = item.orderNumber! - 1;
      } else if (dropResult.to! < dropResult.from! && item.orderNumber! < dropResult.from! && item.orderNumber! >= dropResult.to!) {
        item['orderNumber'] = item.orderNumber! + 1;
      } else if (item.orderNumber! === dropResult.from) {
        item.orderNumber! = dropResult.to!;
      }
      return item;
    }).sort((a: TableItemInterface, b: TableItemInterface) => a.orderNumber!! - b.orderNumber!!);
  }

  toggleElement(i: number, e: Event): void {
    e.stopPropagation();
    this.notPinnedList[i].showInTable = !this.notPinnedList[i].showInTable;
  }

  saveTableSettings(): void {
    this.userSettings.emit([...this.pinnedLeftList, ...this.notPinnedList, ...this.pinnedRightList]);
  }

  resetView(): void {
    this.notPinnedList = [...this.defaultTableItems.filter(i => !i.pinnedLeft && !i.pinnedRight).map(i => ({...i}))].map((item, index) => {
      item['orderNumber'] = index;
      item['showInTable'] = true;
      return item
    }).sort((a: TableItemInterface, b: TableItemInterface) => a.orderNumber! - b.orderNumber!);


    this.pinnedLeftList = [...this.defaultTableItems.filter(i => i.pinnedLeft).map(i => ({...i}))].map((item, index) => {
      item['orderNumber'] = index;
      item['showInTable'] = true;
      return item
    }).sort((a: TableItemInterface, b: TableItemInterface) => a.orderNumber! - b.orderNumber!);

    this.pinnedRightList = [...this.defaultTableItems.filter(i => i.pinnedRight).map(i => ({...i}))].map((item, index) => {
      item['orderNumber'] = index;
      item['showInTable'] = true;
      return item
    }).sort((a: TableItemInterface, b: TableItemInterface) => a.orderNumber! - b.orderNumber!);
  }
}
