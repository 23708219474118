import { ChangeDetectorRef, Component, DestroyRef, inject, OnInit, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, debounceTime, filter, Observable, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { PlatformService } from './shared/services/platform.service';
import { HelpService } from './shared/services/help.service';
import { CookiesSettings, CookiesSettingsModalComponent } from './shared/components/cookies-settings-modal/cookies-settings-modal.component';
import { AuthAppService } from './modules/auth/services/auth.app.service';
import { User } from './services/user-service/user.interface';
import { LoadingService } from './shared/services/loading.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  environment = environment;
  hidePanel: boolean = false;
  isMobilePlatform = false;
  urlAfterRedirects: string = '';
  disableNotifyButton: boolean = false;
  isLoggedIn$: Observable<boolean | unknown>;
  currentUser!: User | null;
  modalRef!: BsModalRef;
  justPassedEmailVerification: boolean = false;
  isSidebarClosed: boolean = false;
  isLoading: boolean = false
  clientDisabled: boolean = false;

  // Hide by Stein request 11.03.2024
  // showCookiesBanner: boolean = false;
  // Hide by Stein request 11.03.2024

  private destroyRef = inject(DestroyRef);

  get userIsReseller(): boolean {
    return this.currentUser?.permissions['1'] === 3 || false;
  }

  get sidebarVisibleCondition(): boolean {
    return !!(this.urlAfterRedirects && !this.urlAfterRedirects.includes('welcome'))
  }

  constructor(public translate: TranslateService,
              private router: Router,
              private platformService: PlatformService,
              private authAppService: AuthAppService,
              private helpService: HelpService,
              private modalService: BsModalService,
              private loadingService: LoadingService,
              private cdr: ChangeDetectorRef) {
    translate.addLangs(['en', 'no']);
    this.checkPlatform();

    this.authAppService.init();
    this.isLoggedIn$ = this.authAppService.isLoggedIn;

    // Hide by Stein request 11.03.2024
    // this.checkCookies();
    // if (!environment.production) {
    //   this.loadCookiesScripts();
    // }
    // Hide by Stein request 11.03.2024
  }

  ngOnInit(): void {
    this.isLoadingSubscription();
    this.currentUserSubscription();
  }

  notify(): void {
    this.helpService.notifyMe().subscribe(res => {
      if (res?.success) {
        this.disableNotifyButton = true;
      }
    })
  }

  openModal(template: TemplateRef<CookiesSettingsModalComponent>): void {
    this.modalRef = this.modalService.show(template, Object.assign({class: 'modal-dialog--short'}));
  }

  onCloseModal(): void {
    this.modalRef.hide();
  }

  onSaveCookiesSettings(settings: CookiesSettings): void {
    localStorage.setItem('cookies', JSON.stringify(settings));
  }

  checkSidebarOpenedState(event: boolean): void {
    this.isSidebarClosed = event;
  }

  private checkPlatform(): void {
    combineLatest([this.router.events, this.platformService.isMobilePlatform$]).pipe(
      filter(e => e[0] instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(([routerEvents, isModilePlatform]) => {
      this.urlAfterRedirects = (routerEvents as NavigationEnd).urlAfterRedirects;

      if (isModilePlatform && !this.urlAfterRedirects.includes('auth') &&
                    !this.urlAfterRedirects.includes('page-not-found') &&
                    !this.urlAfterRedirects.includes('onboarding')) {
        this.hidePanel = true;
        this.isMobilePlatform = true;
      }
    })
  }

  private currentUserSubscription(): void {
    this.authAppService.currentUser.pipe(
      tap((user: User | null) => {
        this.currentUser = user;
      }),
      debounceTime(600),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe()
  }

  private isLoadingSubscription(): void {
    this.loadingService.isLoading().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      (res: boolean) => {this.isLoading = res; this.cdr.detectChanges()}
    )
  }

  // Hide by Stein request 11.03.2024

  // allowCookies(): void {
  //   localStorage.setItem('cookies', JSON.stringify({ analytics: true, tagManager: true, hotjar: true, vwo: true }));
  //   this.showCookiesBanner = false;
  // }

  // private checkCookies(): void {
  //   this.showCookiesBanner = !localStorage.getItem('cookies')
  // }

  // private loadCookiesScripts(): void {
  //   const bannerScript = document.createElement('script');
  //   bannerScript.type = 'text/javascript';
  //   bannerScript.src = '//cdn.cookie-script.com/s/32a88e1773edb48ad3333f39d49a08b9.js';
  //   bannerScript.setAttribute('data-cs-lang', localStorage.getItem('lang')! || 'no');

  //   const reportScript = document.createElement('script');
  //   reportScript.type = 'text/javascript';
  //   reportScript.src = '//report.cookie-script.com/r/32a88e1773edb48ad3333f39d49a08b9.js';
  //   reportScript.setAttribute('data-cookiescriptreport', 'report');
  //   const head = document.getElementsByTagName('head')[0];
  //   if (head !== null && head !== undefined) {
  //     document.head.appendChild(bannerScript);
  //     document.head.appendChild(reportScript);
  //   }
  // }
  // Hide by Stein request 11.03.2024
}
