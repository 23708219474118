import { HttpEvent, HttpHandler, HttpInterceptor,HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorsService } from '../../../shared/services/errors.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private errorsService: ErrorsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((requestError) => {
        if (requestError.error?.message?.detail) {
          this.errorsService.setAuthError(requestError.error?.message.detail)
        }

        if (requestError.error?.message?.email) {
          this.errorsService.setAuthError('Email exist');
        }

        return throwError(() => requestError);
      })
    );
  }
}
