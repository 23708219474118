import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ap-ui-button-link',
  templateUrl: './ui-button-link.component.html',
  styleUrls: ['./ui-button-link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiButtonLinkComponent implements OnInit {

  @Input() text: string = '';
  @Input() isSmall: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isSecondary: boolean = false;
  @Input() isDestructive: boolean = false;
  @Input() iconClass: string = '';
  @Input() iconPosition: 'left' | 'right' = 'right';
  @Input() color: string = 'black';

  @HostBinding('class')
  get buttonLinkClass(): string {
    return 'button-link ' +
      `${this.disabled ? 'button-link--disabled' : ''}` +
      `${this.isSmall ? ' button-link--small' : ''}` +
      `${this.iconClass && this.iconPosition === 'right' ? ' button-link--icon-right' : ''}` +
      `${this.iconClass && this.iconPosition === 'left' ? ' button-link--icon-left' : ''}` +
      `${this.isSecondary ? ' button-link--secondary' : ''}` +
      `${this.isDestructive ? ' button-link--destructive' : ''}`
      ;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
