import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { headers } from '../../shared/helpers/headers.helper';
import { NotificationsListResponse } from '../../shared/models/notifications.model';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {
  private url = environment.baseUrl + '/profrakt';

  constructor(private http: HttpClient) {}

  getNotifications(unread_only?: boolean, page?: number, page_size?: number): Observable<NotificationsListResponse> {
    return this.http.get<NotificationsListResponse>(`${this.url}/notifications/?page_size=${ page_size ? page_size : 10}${ page ? '&page=' + page : ''}${ unread_only ? '&read=false' : ''}`, { headers: headers()});
  }

  markNotificationAsRead(id: string): Observable<any> {
    return this.http.post<any>(`${this.url}/notifications/${ id }/read/`, {}, { headers: headers()});
  }

  markAllAsRead(): Observable<any> {
    return this.http.post<any>(`${this.url}/notifications/mark_all_as_read/`, {}, { headers: headers()});
  }

  removeNotification(id: string): Observable<null> {
    return this.http.delete<null>(`${this.url}/notifications/${ id }/`, { headers: headers()});
  }
}