import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[textLimit]',
  exportAs: 'textLimit'
})
export class TextLimitationDirective {
  @Input('textLimit') textLimit: number = 0;

  currentLength: number = 0;

  private element : ElementRef;

  constructor(element: ElementRef) {
    this.element = element;
  }

  @HostListener('keydown', ['$event'])
  handleKeyPress(event : KeyboardEvent) {
    this.currentLength = this.element.nativeElement.value.length;
    if (this.element.nativeElement.value.length >= this.textLimit) {
      if (event.keyCode !== 8) {
        event.preventDefault();
      }
    }
  }
}
