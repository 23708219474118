<div class="price-list-backdrop" (click)="closeSidebar.emit(true)"></div>
<div class="price-list">
  <div class="price-list__head">
    <div class="price-list__title" translate>
      Company shipping prices
    </div>
    <div class="price-list__close" (click)="closeSidebar.emit(true)">
      <i class="icon-close-stroke pointer fs-28"></i>
    </div>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="price-list__filters-section">
      <div class="price-list__input input ap-filters__input">
        <i class="icon-search"></i>
        <input type="text" [formControl]="searchFormControl"
               placeholder="{{ 'Search by delivery method...' | translate }}">
      </div>
    </div>
    <div class="price-list__prices-section" *ngIf="filteredBringProducts.length || filteredHelthjemProducts || filteredPostnordProducts">
      <div class="price-list__warning-container">
        <ap-ui-inline-alert [type]="'warning_blue'"
                            [description]="'Displayed prices do not include carrier surcharges for toll taxes, services, next day express, and fuel supplements for Svalbard. Additional costs may apply to your shipment.' | translate"
                            [title]="' Important Notice!' | translate"
                            [hideIcon]="true"
                            (close)="showPriceListWarning = false"
                            *ngIf="showPriceListWarning">
        </ap-ui-inline-alert>
      </div>
      <ng-container *ngFor="let product of filteredBringProducts">
        <ap-product-price [product]="product"></ap-product-price>
      </ng-container>
  
      <ng-container *ngFor="let product of filteredHelthjemProducts">
        <ap-product-price [product]="product"></ap-product-price>
      </ng-container>
        
      <ng-container *ngFor="let product of filteredInstaboxProducts">
        <ap-product-price [product]="product"></ap-product-price>
      </ng-container>

      <ng-container *ngFor="let product of filteredPorterbuddyProducts">
        <ap-product-price [product]="product"></ap-product-price>
      </ng-container>

      <ng-container *ngFor="let product of filteredPostnordProducts">
        <ap-product-price [product]="product"></ap-product-price>
      </ng-container>
      
      <div class="price-list__empty-list" *ngIf="!filteredBringProducts.length && 
                                                 !filteredHelthjemProducts && 
                                                 !filteredPostnordProducts &&
                                                 !filteredInstaboxProducts &&
                                                 !filteredPorterbuddyProducts">
        <div class="empty-state empty-state--no-height">
          <div class="empty-state__content">
            <div class="empty-state__image empty-state__image--small">
              <img src="assets/images/empty/empty-search.svg" alt="Empty list image">
            </div>
            <div class="empty-state__title" translate>
              Delivery methods not found...
            </div>
            <div class="empty-state__description" translate>
              We couldn't find any results that match your search criteria. Please adjust your search and try again
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="price-list__loader">
      <ap-ui-loader></ap-ui-loader>
    </div>
  </ng-container>

  <div class="price-list__footer">
    <ap-ui-button-link [text]="allPricesIsOpen ? 'Close all prices' : 'Open all prices'"
                       (click)="openAllPrices()"
                       class="me-auto"></ap-ui-button-link>
    <button class="button button--contained-black button--height-40"
            (click)="closeSidebar.emit(true)" translate>
      Close
    </button>
  </div>
</div>
