<div class="ap-filters">
  <div class="ap-filters__input-section">
    <div class="input input--height-36 ap-filters__input">
      <i class="icon-search fs-20"></i>
      <input type="text"
             [placeholder]="placeholder"
             [formControl]="searchFormControl">
    </div>
    <div class="ap-filters__btn-container">
      <div *ngIf="addQuickStatusFilter" class="ap-filters__quick">
        <div *ngIf="activeStatuses.length" class="ap-filters__counter">{{ activeStatuses.length }}</div>
        <div class="button button--height-36 button--outlined"
             [class.ap-filters__button-active]="isQuickFilterShowed"
             (click)="isQuickFilterShowed = true">
          <i class="icon-status fs-20 mr--6"></i>
          {{ 'Status' | translate }}
          <i class="icon-arrow-down-stroke fs-20 ml--6"></i>
        </div>
        <div *ngIf="isQuickFilterShowed" class="ap-filters__quick-wrapper">
          <div class="ap-filters__quick-body">
            <div class="fs-18 fw-600 mb--8">{{quickStatusFilterTitle || 'Quick filter'}}</div>
            <div class="flex flex-wrap">
              <div *ngFor="let status of statuses; let i=index"
                   class="status status--simple mt--8"
                   [class.mr--8]="i < statuses.length - 1"
                   [class.status--simple-selected]="isStatusAlreadySelected(status)"
                   (click)="selectStatus(status)">
                {{status.name}}
              </div>
            </div>
          </div>
          <div class="ap-filters__quick-footer">
            <button class="button button--height-40 mr--16" (click)="isQuickFilterShowed = false">Cancel</button>
            <button class="button button--height-40 button--contained-black" (click)="applyStatuses()">Apply filters</button>
          </div>
        </div>
      </div>

      <button *ngIf="!hideFiltersButton" class="button button--height-36 button--outlined" (click)="openFilters.emit()">
        <i class="icon-filter fs-20 mr--6"></i>
        {{ 'Filters' | translate }}
      </button>
      <div *ngIf="filtersArray.length" class="ap-filters__counter">{{ filtersArray.length }}</div>
    </div>
  </div>
  <div *ngIf="filtersArray.length" class="ap-filters__list">
    <div class="ap-filters__item" *ngFor="let filter of filtersArray; let i = index">
      {{filter.label}}
      <div class="ap-filters__icon-container" (click)="clearFilter(i)">
        <i class="icon-close-stroke fs-12"></i>
      </div>
    </div>
  </div>
</div>
