import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef, HostBinding,
  InjectionToken,
  Input, Output,
  QueryList
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UiButtonToggleComponent } from '../components/ui/ui-button-toggle/ui-button-toggle.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ButtonToggleSizeEnum } from '../components/ui/ui-button-toggle/enums/button-toggle-size.enum';

export const CS_BUTTON_TOGGLE_GROUP = new InjectionToken<ButtonToggleGroupDirective>(
  'ButtonToggleGroupDirective'
);

@Directive({
  selector: '[buttonToggleGroup]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonToggleGroupDirective),
      multi: true
    },
    {
      provide: CS_BUTTON_TOGGLE_GROUP,
      useExisting: ButtonToggleGroupDirective
    }
  ]
})
export class ButtonToggleGroupDirective implements ControlValueAccessor, AfterViewInit {

  @ContentChildren(forwardRef(() => UiButtonToggleComponent)) toggleButtons!: QueryList<UiButtonToggleComponent>;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input()
  set value(value: any) {
    this.updateValue(value);
    // COMMENTED 27.09.2024 - NEED TO TEST MORE
    // this.cdr.detectChanges();
    // COMMENTED 27.09.2024 - NEED TO TEST MORE
  }

  get value(): any {
    return this._value;
  }

  @Input() buttonSize: keyof typeof ButtonToggleSizeEnum = 'small';

  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.button-toggle-group__container')
  get toggleButtonGroupContainer(): boolean {
    return true;
  }

  @HostBinding('class.button-toggle-group--disabled')
  get toggleGroupDisabledClass(): boolean {
    return this.disabled;
  }

  @HostBinding('class')
  get toggleContainerSizeClass(): string {
    return 'button-toggle__container-' + this.buttonSize.toString();
  }

  private _disabled = false;
  private _value: any;

  constructor(private cdr: ChangeDetectorRef) {}

  get toggleButtonsArray(): UiButtonToggleComponent[] {
    return this.toggleButtons?.toArray() || [];
  }

  ngAfterViewInit(): void {
    if (this.toggleButtons) {
      this.toggleButtonsArray.forEach(button => button.buttonSize = this.buttonSize);
    }
  }

  onChangeFn: any = () => {};
  onTouchFn: any = () => {};

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  onChange(value: any): void {
    this.onChangeFn(value);
  }

  updateValue(value: any): void {
    this._value = value;
    this.onChange(this._value);
  }

  updateValueFromItem(value: any): void {
    if (this.disabled) return;
    this.value = value;
    this.emitChange();
  }

  private emitChange(): void {
    this.change.emit(this._value);
  }
}
