import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientInterface } from '../../modules/superadmin/manage-clients/interfaces/client.interface';
import { TeamMemberCompanyInterface } from '../../services/user-service/user.interface';

@Injectable({
  providedIn: 'root'
})

export class ClientAppService {
  _activeClient: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _showClientForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _updateClientList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _clients: BehaviorSubject<ClientInterface[]> = new BehaviorSubject<ClientInterface[]>([]);
  _teamMemberCompanies: BehaviorSubject<TeamMemberCompanyInterface[]> = new BehaviorSubject<TeamMemberCompanyInterface[]>([]);
  _activeTeamMemberCompany: BehaviorSubject<TeamMemberCompanyInterface | null> = new BehaviorSubject<TeamMemberCompanyInterface | null>(null);
  _isClientBlocked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  toggleClientForm(value: boolean): void {
    this._showClientForm.next(value);
  }

  showClientForm(): Observable<boolean> {
    return this._showClientForm.asObservable();
  }

  setClients(value: ClientInterface[]): void {
    localStorage.setItem('clients', JSON.stringify(value));
    this._clients.next(value);
  }

  allClients(): Observable<ClientInterface[]> {
    return this._clients.asObservable();
  }

  teamMemberCompanies(): Observable<TeamMemberCompanyInterface[]> {
    return this._teamMemberCompanies.asObservable()
  }

  setTeamMemberCompanies(companies: TeamMemberCompanyInterface[]): void {
    this._teamMemberCompanies.next(companies)
  }

  activeTeamMemberCompany(): Observable<TeamMemberCompanyInterface | null> {
    return this._activeTeamMemberCompany.asObservable()
  }

  setActiveTeamMemberCompany(company: TeamMemberCompanyInterface): void {
    if (company) {
      localStorage.setItem('activeTeamMemberCompany', JSON.stringify(company));
    } else {
      localStorage.removeItem('activeTeamMemberCompany');
    }
    this._activeTeamMemberCompany.next(company)
  }

  setActiveClient(client: ClientInterface | undefined): void {
    if (client) {
      localStorage.setItem('activeClient', JSON.stringify(client));
    } else {
      localStorage.removeItem('activeClient');
    }
    this._activeClient.next(client);
  }

  activeClient(): Observable<ClientInterface | null> {
    return this._activeClient.asObservable();
  }

  updateClientList(value: boolean): void {
    this._updateClientList.next(value);
  }

  updateClientsListener(): Observable<boolean> {
    return this._updateClientList.asObservable();
  }

  setBlockClient(value: boolean): void {
    this._isClientBlocked$.next(value);
  }

  isClientBlocked(): Observable<boolean> {
    return this._isClientBlocked$.asObservable();
  }
}
