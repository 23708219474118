import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  constructor(private deviceService: DeviceDetectorService) {
    this.checkCurrentPlatform();
  }

  isMobilePlatform$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private checkCurrentPlatform(): void {
    
    if (this.deviceService.isMobile()) {
      this.isMobilePlatform$.next(true);
    }
  }
}
