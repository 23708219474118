<ng-container *ngIf="product">
  <div class="price">
    <div class="price__top" (click)="product.isOpen = !product.isOpen">
      <div class="price__title" translate>
        {{ product.name }}
      </div>
      <div class="price__logo">
        <ng-container *ngIf="product.service !== 'bring' && product.service !== 'postnord'">
          <img [src]="'assets/images/shipments/' + product.service + '.svg'" [alt]="(product.service | titlecase) + ' logo'">
        </ng-container>

        <ng-container *ngIf="product.service === 'bring' || product.service === 'postnord'">
          <img [src]="'assets/images/shipments/' + product.service + '.png'" [alt]="(product.service | titlecase) + ' logo'">
        </ng-container>
      </div>
      <div class="price__items-count"
           [tooltip]="getItemsCountTooltip(product.weights)">
        {{ product.weights.length }}
      </div>
      <i class="icon-arrow-up-stroke ms-auto fs-20 pointer" [class.rotate-180deg]="!product.isOpen"></i>
    </div>
    <div class="price__details" *ngIf="product.isOpen">
      <div class="price__items">
        <div class="price-item" *ngFor="let weight of product.weights">
          <i class="price-item__icon icon-prices fs-20 mr--8"></i>
          <div class="price-item__column">
            <div class="price-item__title">{{ weight.weight }}</div>
            <div class="price-item__value">{{ weight.price + (weight.price_type === 'fix' ? ' NOK' : ' %')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>