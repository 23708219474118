import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription, combineLatest } from 'rxjs';

import { ClientAppService } from '../../services/client.app.service';
import { AuthAppService } from 'src/app/modules/auth/services/auth.app.service';
import { User } from 'src/app/services/user-service/user.interface';
import { ClientInterface } from 'src/app/modules/superadmin/manage-clients/interfaces/client.interface';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'ap-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnDestroy {
  private subscription$ = new Subscription();
  loading: boolean = false;
  currentUser: User | null = null;
  clients: ClientInterface[] = [];

  constructor(private authAppService: AuthAppService,
              private clientAppService: ClientAppService,
              private loadingService: LoadingService,
              private router: Router) {
                this.loadingService.setIsLoading(true)
                this.userAndClientsDataSubscription();
              }

  userAndClientsDataSubscription(): void {
    this.subscription$.add(
      combineLatest([this.authAppService.currentUser, this.clientAppService.allClients()]).subscribe(([user, clients]) => {
        this.currentUser = user;
        this.clients = clients;
        this.loadingService.setIsLoading(false)
      })
    )
  }

  openCompanyForm(): void {
    this.clientAppService.toggleClientForm(true);
  }

  navigateTo(route: string):void {
    this.router.navigate([route]);
  }

  get allClientsIsOnboarded(): boolean {
    return this.clients.every((client: ClientInterface) => client.is_onboarded);
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
