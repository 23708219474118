<div class="ui-inline-alert" *ngIf="type"
     [class.ui-inline-alert--align-center]="!title || !description"
     [class.ui-inline-alert--less-yAxis-padding]="less_yAxis_padding">
  <div class="ui-inline-alert__background"
       [style.background-color]="background_alert_color"
       [class.ui-inline-alert__background--gray]="type === 'warning_gray'"></div>
  <div class="ui-inline-alert__icon" *ngIf="!hideIcon">
    <i [class]="'icon-' + (type !== 'success' ? 'info-circle' : 'check-mark-filled') + ' fs-' + icon_size" [style.color]="alert_color"></i>
    <i class="check-mark-filled"></i>
  </div>
  <div class="ui-inline-alert__info">
    <div class="ui-inline-alert__title" *ngIf="title"
         [class.ui-inline-alert__title--nowrap]="nowrapTitle">
      {{ title }}
      <span class="ui-inline-alert__title--sub" *ngIf="subTitle">
        {{ subTitle }}
      </span>
    </div>
    <div class="ui-inline-alert__description"
         *ngIf="description"
         [class.mt-0]="!title" 
         [innerHTML]="description"></div>

    <div class="ui-inline-alert__action" *ngIf="actionText && !action_on_right">
      <ap-ui-button-link [text]="actionText" (click)="action.emit()"></ap-ui-button-link>
    </div>
  </div>
  <div class="ui-inline-alert__action--right" *ngIf="actionText && action_on_right">
    <ap-ui-button-link [text]="actionText" (click)="action.emit()"></ap-ui-button-link>
  </div>
  <div class="ui-inline-alert__close" *ngIf="!hide_close_button" (click)="close.emit(true)">
    <i class="icon-close-stroke fs-18 pointer"></i>
  </div>
</div>
