

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { fileHeaders, headers, xuserdataHeaders } from '../../shared/helpers/headers.helper';
import {
  CustomerSupportTicketsCounter,
  SuperAdminClientsListResponse,
  TicketComment,
  TicketCommentsListResponse,
  TicketInterface } from 'src/app/modules/customer-service/interfaces/ticket.interface';
import { GetCustomerSupportUsersResponse } from './interfaces/get-cutomer-support-users-response.interface';

@Injectable({
  providedIn: 'root'
})

export class CustomerServiceService {
  private url = environment.baseUrl + '/profrakt';
  private userServiceURL = environment.baseUrl + '/user';
  local = 'http://127.0.0.1:8000';

  constructor(private http: HttpClient) {}

  getTicketsList(ordering: string,
                 itemsPerPage: number,
                 activePage: number,
                 search: string,
                 user_id: number,
                 client: string | number,
                 assigned_for: number,
                 status?: 'IN_PROGRESS' | 'COMPLETED' | 'CREATED' | null): Observable<SuperAdminClientsListResponse> {
    return this.http.get<SuperAdminClientsListResponse>(`${this.url}/customer-service/?ordering=${ ordering }&page_size=${ itemsPerPage }&page=${ activePage }${
                                                                                        search ? `&search=` + search : ``}${
                                                                                        user_id ? `&user_id=` + user_id : ``}${
                                                                                        client ? `&client=` + client : ``}${
                                                                                        assigned_for ? '&assigned_for=' + assigned_for : ''}${
                                                                                        status ? `&status=` + status : ``}`, { headers: headers()});
  }

  getArchivedTicketsList(itemsPerPage: number, activePage: number, search: string, user_id: number, client: string | number): Observable<SuperAdminClientsListResponse> {
    return this.http.get<SuperAdminClientsListResponse>(`${this.url}/customer-service/archived/?page_size=${ itemsPerPage }&page=${ activePage }${
                                                                                        search ? `&search=` + search : ``}${
                                                                                        user_id ? `&user_id=` + user_id : ``}${
                                                                                        client ? `&client=` + client : ``}`, { headers: headers()});
  }

  getTicketDetails(id: string | undefined): Observable<TicketInterface> {
    return this.http.get<TicketInterface>(`${this.url}/customer-service/${ id }/`, { headers: headers()});
  }

  createTicket(body: FormData): Observable<TicketInterface> {
    return this.http.post<TicketInterface>(`${this.url}/customer-service`, body, { headers: fileHeaders() });
  }

  removeTicket(id: string | undefined): Observable<any> {
    return this.http.delete(`${this.url}/customer-service/${ id }/`, { headers: headers()});
  }

  updateTicket(id: string | undefined, body: Partial<TicketInterface>): Observable<TicketInterface> {
    return this.http.put<TicketInterface>(`${this.url}/customer-service/${ id }/`, body, { headers: headers()});  
  }

  ticketsCount(client: string | number): Observable<CustomerSupportTicketsCounter> {
    return this.http.get<CustomerSupportTicketsCounter>(`${this.url}/customer-service/tickets-counter/${ client ? '?client=' + client : ''}`, { headers: headers()});
  }

  getPlatrormCustomerSupportUsers(): Observable<GetCustomerSupportUsersResponse> {
    return this.http.get<GetCustomerSupportUsersResponse>(`${this.userServiceURL}/profiles/?is-support=true`, { headers: headers()})
  }

  getTicketComments(ticket_id: any, activePage?: number): Observable<TicketCommentsListResponse> {
    return this.http.get<TicketCommentsListResponse>(`${this.url}/customer-service/${ ticket_id }/comments/?page_size=5${ 
                               activePage ? '&page=' + activePage : ''}`, { headers: headers()})
  }
  
  postComment(ticket_id: string, body: FormData): Observable<TicketComment> {
    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'no'
    return this.http.post<TicketComment>(`${this.url}/customer-service/${ ticket_id }/comments?lang=${ lang }`, body, { headers: fileHeaders()})
  }
}
