import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { tap } from 'rxjs/operators';
import { switchMap } from 'rxjs';

import { UiTableModule } from '../ui/ui-table';
import { ShipmentsService } from '../../../services/shipments-service/shipments.service';
import { SharedModule } from '../../shared.module';
import { testTableItemsConst } from './test-table-items.const';

@Component({
  selector: 'ap-bigquery-test',
  templateUrl: './bigquery-test.component.html',
  styleUrls: ['./bigquery-test.component.scss'],
  standalone: true,
  imports: [UiTableModule, SharedModule, CommonModule]
})
export class BigqueryTestComponent implements OnInit {
  tableItems = testTableItemsConst
  activePage: number = 1
  itemsPerPageValues = [9, 12, 24, 48, 64, 96, 192]
  itemsPerPage = this.itemsPerPageValues[0]
  loading: boolean = false
  totalItems: number = 0
  list: any[] = []

  private destroyRef = inject(DestroyRef)

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private shipmentsService: ShipmentsService) {}

  ngOnInit(): void {
    this.queryParamsSubscription()
  }

  onChangePage(value: PageChangedEvent): void {
    this.activePage = value.page;

    this.router.navigate([], {
      queryParams: {
        activePage: this.activePage
      }
    });
  }

  onChangeItemsPerPageValue(value: number): void {
    this.itemsPerPage = value;
    if (this.activePage !== 1) {
      this.router.navigate([], {
        queryParams: {
          activePage: 1
        }
      })
    } else {
      this.getShipments();
    }
  }

  private queryParamsSubscription(): void {
    this.activatedRoute.queryParams.pipe(
      tap((params) => {
        this.loading = true;
        this.activePage = +params['activePage'] || 1;
      }),
      switchMap(() =>
        this.shipmentsService.testBigQueryGetShipments(
          this.activePage,
          this.itemsPerPage)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: any) => {
      this.totalItems = res.totalElements;
      this.list = res.content;
      this.loading = false;
    })
  }

  private getShipments(): void {
    this.loading = true
    this.shipmentsService.testBigQueryGetShipments(this.activePage, this.itemsPerPage)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: any) => {
        this.totalItems = res.totalElements;
        this.list = res.content;
        this.loading = false;
      })
  }
}
