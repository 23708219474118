import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NotificationStripeService {
  _notificationStripeContent: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  setNotificationStripeContent(value: string | null): void {
    this._notificationStripeContent.next(value)
  }

  notificationStripeContent(): Observable<string | null> {
    return this._notificationStripeContent.asObservable()
  }
}
