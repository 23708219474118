<div *ngFor="let row of rectangle.repeat | iterableNumber" class="rectangle" [ngStyle]="{
  'max-height': rectangle.height?.max,
  'min-height': rectangle.height?.min,
  'height': rectangle.height?.value,

  'max-width': rectangle.width?.max,
  'min-width': rectangle.width?.min,
  'width': rectangle.width?.value,

  'border-radius': rectangle.borderRadius
}"></div>
