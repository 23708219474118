export enum PermissionsServicesEnum {
  ADMINPANEL = 1,
  ECOMMERCE = 2,
  EDI = 3
} 

export enum AdminpanelModulesEnum {
  ADMIN_CLIENTS_LIST = 1,
  ADMIN_MANAGE_USERS = 6,
  SHIPMENTS = 7,
  INVOICES = 4,
  DASHBOARD = 3,
  PRICE_GROUPS = 5,
  // PRODUCTS = 1000 
}