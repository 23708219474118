import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthAppService } from '../services/auth.app.service';
import { User } from 'src/app/services/user-service/user.interface';

@Injectable({
  providedIn: 'root'
})

export class AdminGuardService  {

  constructor(private router: Router,
              private authAppService: AuthAppService) {}

  canActivate(): Observable<boolean> | boolean {
    let value!: boolean;
    this.authAppService.currentUser.subscribe((user: User | null) => {
      if (user?.is_admin) {
        value = true
      } else {
        value = false;
        this.router.navigate(['/']);
      }
    })

    return value;
  }
}
