import {
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { distinctUntilChanged, fromEvent } from 'rxjs';

@Directive({
  selector: '[resizeColumn]'
})
export class ResizeColumnDirective implements OnInit {
  @Input() minWidth: string = '52px'
  @Output() resize: EventEmitter<number> = new EventEmitter<number>()

  private startX!: number
  private initialWidth!: number
  private columnIndex!: number
  private table: HTMLElement | null = null
  private isResizing = false
  private tableWidth: number | null = null
  private destroyRef: DestroyRef = inject(DestroyRef)

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setEventListenersToDragElement()
  }

  private onMouseDown(event: MouseEvent): void {
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    this.startX = event.pageX
    this.isResizing = true
    this.initialWidth = this.elementRef?.nativeElement?.offsetWidth
    const row = this.elementRef?.nativeElement?.parentElement
    const cells = Array.from(row?.children)
    this.columnIndex = cells.indexOf(this.elementRef?.nativeElement)
    this.table = this.findParentTable(this.elementRef.nativeElement)
    if (this.table) {
      const onMouseMove = (moveEvent: MouseEvent): void => {
        if (this.isResizing) {
          const deltaX = moveEvent?.pageX - this.startX
          const newWidth = this.initialWidth + deltaX
          this.tableWidth = !this.tableWidth && this.table?.offsetWidth ? this.table?.offsetWidth / 2 : this.tableWidth

          if (newWidth >= parseInt(this.minWidth) && newWidth <= (this.tableWidth || 350)) {
            this.renderer.setStyle(this.elementRef?.nativeElement, 'width', `${newWidth}px`);
            const rows = this.table?.querySelectorAll('.table-list__item')
            rows?.forEach((row) => {
              const cells = row.querySelectorAll('.table-list__cell');
              if (cells[this.columnIndex]) {
                this.renderer.setStyle(cells[this.columnIndex], 'width', `${newWidth}px`);
                this.renderer.addClass(cells[this.columnIndex], 'table-list__cell--onresize');
              }
            })
          }
        }
      }
      const onMouseUp = () => {
        this.isResizing = false;
        this.renderer.removeClass(this.elementRef?.nativeElement, 'resizing-col');
        this.resize.emit(this.elementRef?.nativeElement.offsetWidth)
        const rows = this.table?.querySelectorAll('.table-list__item')
        rows?.forEach((row) => {
          const cells = row.querySelectorAll('.table-list__cell');
          if (cells[this.columnIndex]) {
            this.renderer.removeClass(cells[this.columnIndex], 'table-list__cell--onresize');
          }
        })
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      }
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }
  }

  private findParentTable(element: HTMLElement): HTMLElement | null {
    while (element) {
      if (element.tagName === 'AP-UI-TABLE') {
        return element;
      }
      if (element?.parentElement) element = element?.parentElement;
    }
    return null;
  }

  private setEventListenersToDragElement(): void {
    const nativeElement: HTMLElement = this.elementRef?.nativeElement as HTMLElement
    const drag = nativeElement.getElementsByClassName('ui-table__header-drag')[0]
    const mousedown = fromEvent<MouseEvent>(drag, 'mousedown')
    mousedown.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe(event => this.onMouseDown(event));
    fromEvent<MouseEvent>(drag, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe(event => {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
      });
  }
}
