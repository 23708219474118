<div class="customize-table-backdrop" (click)="closeCustomizeTableSidebar.emit(true)"></div>
<div class="customize-table">
  <div class="customize-table__head">
    <div class="customize-table__title">
      {{ 'Customise your table view:' | translate }}
    </div>
    <div class="customize-table__close" (click)="closeCustomizeTableSidebar.emit(true)">
      <i class="icon-close-stroke pointer fs-24"></i>
    </div>
  </div>
  <div class="customize-table__list">
    <div class="customize-table__item"
         [class.customize-table__item--disabled]="item.pinnedLeft"
         *ngFor="let item of pinnedLeftList; let i = index">
      <i class="customize-table__drag-icon icon-drag-dots fs-20"></i>
      <i class="icon-lock fs-20 mr--8" [tooltip]="'This column can’t be hidden or moved' | translate"></i>
      {{ tableType === 'shipments' ? 
          (( item.name + (item.databaseName === 'client' || item.databaseName === 'company_name' ? ' special' : '')) | translate) : 
          (item.name | translate) }}
    </div>
    <smooth-dnd-container [dragHandleSelector]="'.customize-table__item'" 
                          [dragClass]="'customize-table__item--drag-state'"
                          [behaviour]="'move'"
                          (drop)="onDrop($event)">
      <smooth-dnd-draggable *ngFor="let item of notPinnedList; let i = index" >
        <div class="customize-table__item" [class.customize-table__item--selected]="item.showInTable">
          <i class="customize-table__drag-icon icon-drag-dots fs-20"></i>
          <div class="checkbox-container mr--8">
            <input type="checkbox" [checked]="item.showInTable" (click)="toggleElement(i, $event)">
          </div>
          {{ (tableType === 'shipments' ? 
              (( item.name + (item.databaseName === 'client' || item.databaseName === 'company_name' ? ' special' : '')) | translate) : 
              item.name | translate) }}
        </div>
      </smooth-dnd-draggable>
    </smooth-dnd-container>
    <div class="customize-table__item mb-0"
         [class.customize-table__item--disabled]="item.pinnedRight"
         *ngFor="let item of pinnedRightList; let i = index">
      <i class="customize-table__drag-icon icon-drag-dots fs-20"></i>
      <i class="icon-lock fs-20 mr--8" [tooltip]="'This column can’t be hidden or moved' | translate"></i>
      {{ tableType === 'shipments' ? 
          (( item.name + (item.databaseName === 'client' || item.databaseName === 'company_name' ? ' special' : '')) | translate) : 
          (item.name | translate) }}
    </div>
  </div>
  <div class="customize-table__footer">
    <ap-ui-button-link [isSecondary]="true"
                       [text]="'Reset view' | translate"
                       [isSmall]="true"
                       (click)="resetView()">
    </ap-ui-button-link>
    <div class="flex">
      <button class="button button--contained-gray button--height-40 mr--16"
              (click)="closeCustomizeTableSidebar.emit(true)">
        {{ 'Cancel' | translate }}
      </button>
      <button class="customize-table__save-button button button--contained-black button--height-40"
              translate
              (click)="saveTableSettings()">
        Save view
      </button>
  </div>
</div>
