import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoadingService {
  _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoading(): Observable<boolean> {
    return this._isLoading.asObservable()
  }

  setIsLoading(value: boolean): void {
    this._isLoading.next(value)
  }
}
