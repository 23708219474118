<div class="welcome">
  <div class="welcome__container">
    <div class="welcome__image"></div>
    <div class="welcome__title">
      {{ 'Welcome.Welcome to Adminpanel.no' | translate }}
    </div>
    <div class="welcome__description">
      {{ "Welcome.Thank you for choosing our service! Let's get started with a few simple" | translate}}<br>
      {{ "Welcome.steps to set up your work for the best experience ever." | translate }}
    </div>
    <div class="welcome__steps">
      <div class="welcome__step step">
        <div class="step__checked" [class.step__checked--success]="currentUser?.first_name && currentUser?.last_name">
          <i class="icon icon-checkmark-stroke fc-white fs-8" *ngIf="currentUser?.first_name && currentUser?.last_name"></i>
        </div>
        <div class="step__center-section">
          <div class="step__title">
            {{(currentUser?.platform_id !==3 ? '1. ' : '') + ('Welcome.Complete your profile setup' | translate)}}
            <i class="icon-info-circle ml--10 fs-20 pointer" *ngIf="currentUser?.first_name && currentUser?.last_name"
               [tooltip]="currentUser?.first_name && currentUser?.last_name ? ('Welcome.Easily update your data in Profile Settings' | translate) : ''"></i>
          </div>
        </div>

        <button  class="step__button button button--contained-black button--height-40"
                (click)="navigateTo('manage-profile')" *ngIf="!(currentUser?.first_name && currentUser?.last_name)"
                translate>
          Welcome.Set up profile
        </button>
      </div>
      <ng-container *ngIf="!(currentUser?.platform_id === 3)">
        <div class="welcome__step step">
          <div class="step__checked" [class.step__checked--success]="clients.length">
            <i class="icon icon-checkmark-stroke fc-white fs-8"></i>
          </div>
          <div class="step__title" translate>
            Welcome.2. Add your first company
          </div>
          <div class="step__button">
            <button class="button button--contained-black button--height-40 ms-auto pl--20 pr--20"
                    (click)="currentUser?.first_name && currentUser?.last_name ? openCompanyForm() : null"
                    [disabled]="!(currentUser?.first_name && currentUser?.last_name)" *ngIf="!clients.length"
                    translate [tooltipWidth]="192"
                    [tooltip]="!(currentUser?.first_name && currentUser?.last_name) ?
                              ('Welcome.Complete your profile setup before proceeding' | translate) : ''">
              Welcome.Add company
            </button>
          </div>
        </div>
        <div class="welcome__step step">
          <div class="step__checked" [class.step__checked--success]="clients.length && allClientsIsOnboarded">
            <i class="icon icon-checkmark-stroke fc-white fs-8"></i>
          </div>
          <div class="step__title" translate>
            Welcome.3. Complete quick onboarding
          </div>
          <div class="step__buttons">
            <ap-ui-button-link [text]="'Skip step'" [color]="'gray-60'" (click)="navigateTo('/shipments')"
                               class="mr--16" *ngIf="clients.length"></ap-ui-button-link>
            <div [tooltip]="!(currentUser?.first_name && currentUser?.last_name && clients.length) ?
                          ('Welcome.Complete your company setup before proceeding' | translate) : ''"
                 placement="top" [tooltipWidth]="212" class="step__button">
              <button class="button button--contained-black button--height-40 pl--20 pr--20"
                      (click)="clients.length ? navigateTo('onboarding') : null"
                      [disabled]="!(currentUser?.first_name && currentUser?.last_name && clients.length)"
                      translate>
                Welcome.Proceed onboarding
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
