<div class="notification"
    (click)="notificationClick()"
    [class.notification--clickable]="true">
  <div class="notification__top">
    <i class="notification__icon icon icon-{{ getNotificationIcon(notification.type) }} fs-24"></i>
    <div class="notification__type">
      <ng-container *ngIf="notification.type === NotificationType.SUPPORT_TICKET">
        <span class="notification__type--bold mr--2">{{ 'Notifications.New ticket' | translate }}</span>
        {{ 'Notifications.created' | translate }}
        <span class="notification__type--bold notification__type--elipsis ml--2">{{ notification.link_id }}</span>
      </ng-container>
  
      <ng-container *ngIf="notification.type === NotificationType.SUPPORT_TICKET_COMMENT">
        <span class="notification__type--bold mr--2">{{ 'Notifications.New Comment' | translate }}</span> 
        {{ 'Notifications.on ticket' | translate }}
        <span class="notification__type--bold notification__type--elipsis ml--2">{{ notification.link_id }}</span>
      </ng-container>

      <ng-container *ngIf="notification.type === NotificationType.PACKAGES_REPORT">
        <ng-container *ngIf="shipmentReportMesssage === ShipmentReportMesssages.PACKAGES_REPORT">
          <span class="notification__type--bold mr--2">Shimpent Report</span> ready to download
        </ng-container>

        <ng-container *ngIf="shipmentReportMesssage === ShipmentReportMesssages.USER_START_TO_GENERATE_PACKAGES_REPORT">
          <span class="notification__type--bold mr--2">Shimpent Report</span> is in the process of creation...
        </ng-container>

        <ng-container *ngIf="shipmentReportMesssage === ShipmentReportMesssages.FAILED_REPORT">
          <span class="notification__type--bold mr--2">Shimpent Report</span> failed
        </ng-container>
        
        <i class="notification__download-icon icon icon-file-download fs-24" 
           *ngIf="shipmentReportMesssage === ShipmentReportMesssages.PACKAGES_REPORT"></i>

        <i class="notification__failed-icon icon icon-customer-service fs-24" (click)="createTicketForFailedReport()"
           *ngIf="shipmentReportMesssage === ShipmentReportMesssages.FAILED_REPORT"></i>

        <i class="notification__loading-icon icon-loader fs-24" 
           *ngIf="shipmentReportMesssage === ShipmentReportMesssages.USER_START_TO_GENERATE_PACKAGES_REPORT"></i>
      </ng-container>
    </div>
  </div>
  
  <div class="notification__time">
    {{ notification.created | adminpanelCustomTime }}
  </div>
</div>