import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralConfirmData, ApplyActionData } from '../models/general-confirm.model';

@Injectable({
  providedIn: 'root'
})

export class GeneralConfirmService {
  _generalConfirmData: BehaviorSubject<GeneralConfirmData | null> = new BehaviorSubject<GeneralConfirmData | null>(null);
  _applyActionData: BehaviorSubject<ApplyActionData | null> = new BehaviorSubject<ApplyActionData | null>(null);

  setGeneralConfirmData(data: GeneralConfirmData | null): void {
    this._generalConfirmData.next(data);
  }

  getGeneralConfirmData(): Observable<GeneralConfirmData | null> {
    return this._generalConfirmData.asObservable();
  }

  setApplyActionData(data: ApplyActionData | null): void {
    this._applyActionData.next(data);
  }

  applyActionData(): Observable<ApplyActionData | null> {
    return this._applyActionData.asObservable();
  }
}
