<div class="head">
  <div class="head__breadcrumbs-section" *ngIf="!onboardingMode">
    <div class="head__breadcrumb head__breadcrumb--main" *ngIf="mainCategory" 
         (click)="mainCategory.route ? navigateTo(mainCategory.route) : null"
         [class.head__breadcrumb--clickable]="breadcrumbs && breadcrumbs!.length">
      {{ 'Breadcrumbs.' + mainCategory.title | translate }}
    </div>
    <div class="head__breadcrumbs" *ngIf="breadcrumbs && breadcrumbs!.length">
      <div class="head__breadcrumb"
           [class.head__breadcrumb--last]="i === breadcrumbs.length - 1"
           *ngFor="let item of breadcrumbs; let i = index"
           (click)="item.route ? navigateTo(item.route) : null">
        <div class="ml--6 mr--6">/</div>
        <span *ngIf="!noTranslate || noTranslate && i !== breadcrumbs.length - 1">
          {{  'Breadcrumbs.' + (item.title | titlecase) | translate }}
        </span>
        <span *ngIf="noTranslate && i === breadcrumbs.length - 1">
          {{ item.title | titlecase }}
        </span>
      </div>
    </div>
    <!-- Temporary hide -->
    <!-- <div class="head__back-button" *ngIf="showBackButton" (click)="back()">
      <i class="icon-arrow-left-stroke fs-12 mr--2"></i>
      {{ "Back" | translate }}
    </div> -->
    <!-- Temporary hide -->
  </div>
  <div class="head__onboarding-title" *ngIf="onboardingMode">
    {{ "Hello" | translate }}, {{ user.first_name + ' ' + user.last_name }}
  </div>

  <div *ngIf="(clients?.length || teamMemberCompanies.length || breadcrumbs?.length) && 
               !urlAfterRedirects.includes('welcome') && 
               selectedClientForm" 
        class="head__selection">
    <div class="head__selection-container selection" *ngIf="!isOnProductListPage"
         clickOutside (clickedOutside)="handleClickOutsideSelection()">
      <div class="selection__input-container">
        <div class="selection__input input input--outlined-gray" 
             (click)="!showClientsList ? openClientsList() : null"
             [class.selection__input--admin-with-value]="user.is_admin || userIsCustomerSupport"
             [class.selection__input--user-with-value]="!user.is_admin && !userIsCustomerSupport">
          <span class="selection__arrow">
            <i class="icon-arrow-up-stroke fs-20" [class.rotate-180deg]="showClientsList"></i>
          </span>
          <ng-container [formGroup]="selectedClientForm">
            <input type="text" placeholder="{{ 'Select company' | translate}}" #searchClientInput
                    formControlName="name" name="clients_selector" role="clients" autocomplete="off">
          </ng-container>
          <i class="selection__clear icon-close-stroke" *ngIf="searchClientInput.value!.length && (user.is_admin || userIsCustomerSupport)"
             (click)="clearSearchClientsValue(); searchClientInput.value = ''"></i>
        </div>
        <div class="selection__price-lists-container"
             [class.selection__price-lists-container--disabled]="!selectedClient"
             (click)="selectedClient ? showPriceListSidebar = true : null">
          <i class="icon-prices fs-20 mr--6"></i>
          {{'Superadmin.Prices' | translate}}
        </div>
      </div>
      <div class="selection__list-container" *ngIf="showClientsList">
        <div class="selection__list"
             infiniteScroll (scrolled)="onScrollCLientsList()" [scrollWindow]="false"
             [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50">
          <ng-container *ngIf="user.platform_id !== 3">
            <ng-container *ngFor="let client of filteredClients.slice(0, clientListIndex)">
              <div class="selection__item" [class.selection__item--selected]="selectedClient?.id === client.id"
                   (click)="selectClient(client)">
                <div class="selection__cient-icon mr--8">
                  <i class="icon-reseller fs-20" [tooltip]="'Reseller'" *ngIf="client.is_reseller"></i>
                  <i class="icon-business fs-20" [tooltip]="'Company'" *ngIf="!client.is_reseller"></i>
                </div>
                <span>
                {{ client!.name }}
                  <span class="selection__item--gray"
                        *ngIf="client.is_reseller && client.reseller_number && (user.is_admin || userIsCustomerSupport)">
                      | Reseller ID: {{ client.reseller_number }}
                </span>
              </span>
                <ng-container *ngIf="client.status === 'draft' && (user.is_admin || userIsCustomerSupport)">
                  <span class="status status--height-20 status--gray-30 ml--8">Draft</span>
                </ng-container>
                <button class="button-icon button-icon--small button-icon--tertiary ms-auto"
                        [tooltip]="'Complete quick onboarding' | translate" (click)="goToOnboarding($event, client)"
                        *ngIf="!client.is_onboarded && !user.is_admin && !userIsCustomerSupport || !client.is_onboarded  && (!client.my_reseller_id && userIsReseller)">
                  <i class="icon-onboarding"></i>
                </button>
              </div>
              <ng-container *ngIf="client.reseller_clients?.length">
                <ng-container *ngFor="let subclient of client.reseller_clients">
                  <div class="selection__item selection__item--child" (click)="selectClient(subclient)"
                       [class.selection__item--selected]="selectedClient?.id === subclient.id">
                    <span class="selection__subclient-icon" [tooltip]="'Reseller client'"></span>
                    {{ subclient!.name }}
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="user.platform_id === 3">
            <ng-container *ngFor="let company of teamMemberCompanies">
              <div class="selection__item"
                   [class.selection__item--selected]="selectedClient?.id === company.id"
                   (click)="selectTeamMemberCompany(company)">
                <span>
                {{ company!.name }}
              </span>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="selection__add-company-section">
          <button class="button button--outlined button--height-30"
                  (click)="openModal(createClientFormModalTemplate)">
            <i class="icon-add-stroke mr--4"></i>
            <span translate>New company</span>
          </button>
          <!-- Hided by Romen Requst 27.08.2024 -->
          <!-- <button class="button-icon button-icon--small button-icon--tertiary ms-auto"
                  (click)="updateClientsList()"
                  *ngIf="user.is_admin || userIsCustomerSupport"
                  [tooltip]="'Update list'">
            <i class="icon-refresh fs-16"></i>
          </button> -->
          <!-- Hided by Romen Requst 27.08.2024 -->
        </div>
      </div>
    </div>
  </div>

  <div class="head__actions">
    <ng-container *ngIf="!environment.production">
      <div class="head__action">
        <i class="icon-chat-open fs-20"></i>
        <div class="head__liveleader-chat-launcher" id="launcher"></div>
      </div>
    </ng-container>
    
    <div class="head__action" [class.ml--16]="!environment.production">
      <div class="head__notifications-container notifications"
            clickOutside (clickedOutside)="showNotifications = false"
            *ngIf="!urlAfterRedirects.includes('welcome') && (clients && clients.length || user.is_admin || userIsCustomerSupport)">
        <div (click)="showNotifications = !showNotifications">
          <i class=" icon-notifications fs-20"></i>
          <div class="notifications__counter" *ngIf="notificationsCounter">{{ notificationsCounter < 99 ? notificationsCounter : '99+' }}</div>
        </div>
        <div *ngIf="showNotifications" class="notifications__list-container">
          <div class="notifications__title">
            {{ 'Notifications.Notifications' | translate }}
            <ap-ui-button-link *ngIf="notifications.length"
                              [text]="'Notifications.Mark all as read' | translate"
                              (click)="markAllAsRead()"
                              class="notifications__mark-all-as-read"
                              [isSmall]="true">
            </ap-ui-button-link>
          </div>
          <div class="notifications__list">
            <ap-header-notification-item *ngFor="let notification of notifications | slice:0:5"
                                        [notificationData]="notification"
                                        (notificationIdToRemove)="onRemoveNotification($event)"
                                        (onShowNotifications)="showNotifications = $event"
                                        (notificationsCounterToEmit)="notificationsCounter = $event"></ap-header-notification-item>
            <div class="notifications__empty-container" *ngIf="!notifications?.length">
              <div class="empty-state empty-state--no-height">
                <div class="empty-state__content">
                  <div class="empty-state__image empty-state__image--xs">
                    <img src="assets/images/empty/empty-notifications.svg" alt="Empty notifications image">
                  </div>
                  <div class="empty-state__title fs-16" translate>
                    Notification list is empty
                  </div>
                  <div class="empty-state__description" translate>
                    New notifications will appear here for any activity or changes in your profile
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="notifications__bottom">
            <button class="ticket-details__close button button--contained-black button--height-30 ms-auto"
                    (click)="navigateTo('notifications'); showNotifications = false">
              {{ 'Notifications.View all notifications' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="head__action ml--16">
      <app-language-toggle class="ml--8 mr--8"></app-language-toggle>
    </div>
  </div>

  <div class="head__user-container"
       clickOutside (clickedOutside)="showUserMenu = false">
    <ap-ui-avatar size="medium"
                  [src]="user.avatar_link ? user.avatar_link : ''"
                  (click)="showUserMenu = !showUserMenu"
                  [placeholderLetters]="user.first_name && user.first_name ? (user.first_name[0] + (user && user.last_name ? user.last_name[0] : '')).toUpperCase() : ''">
    </ap-ui-avatar>
    <div *ngIf="showUserMenu" class="head__user-menu user-menu">
      <div class="user-menu__main">
        <ap-ui-avatar size="extraLarge"
                      [src]="user.avatar_link ? user.avatar_link : ''"
                      [placeholderLetters]="user.first_name && user.first_name ? (user.first_name[0] + (user && user.last_name ? user.last_name[0] : '')).toUpperCase() : ''">
        </ap-ui-avatar>
        <div class="user-menu__name">{{ user.first_name && user.last_name ? user.first_name + ' ' + user.last_name : 'User' }}</div>
        <div class="user-menu__email" [title]="user.email">{{ userEmail }}</div>
      </div>

      <div class="user-menu__divider"></div>
      <div class="user-menu__item"
           (click)="navigateTo('manage-profile'); showUserMenu = false">
        {{ 'Manage profile' | translate }}
      </div>
      <div class="user-menu__divider"></div>
      <div class="user-menu__item user-menu__item--logout" (click)="logout()" translate>
        Log out
      </div>

    </div>
  </div>
</div>

<!-- Inprogress -->
<ng-container *ngIf="!urlAfterRedirects.includes('item-movements')">
  <ng-container *ngIf="clientSettings && clientSettings?.is_edi_blocked">
    <div class="warning-section">
      <ap-ui-inline-alert [type]="'error'" [title]="'Company has been blocked!' | translate" [hide_close_button]="true"
                          [description]="'Your company\'s access to our services has been temporarily suspended. Please ensure all invoices are paid. If issues persist, contact our support team for assistance.' | translate"></ap-ui-inline-alert>
    </div>
  </ng-container>
  <ng-container *ngIf="clientHaveUnpaidInnvoices && clientSettings && !clientSettings?.is_edi_blocked">
    <div class="warning-section">
      <ap-ui-inline-alert [type]="'error'" [title]="'Unpaid invoices have been detected!' | translate" [hide_close_button]="true"
                          [description]="'You are at risk of account suspension due to overdue invoices. Check your invoice status in the menu on the left. Please contact our support team if you have any questions or recently paid the invoices.' | translate"></ap-ui-inline-alert>
    </div>
  </ng-container>
</ng-container>

<!-- Inprogress -->

<ng-template #createClientFormModalTemplate>
  <div class="modal__container">
    <div class="modal__header">
      <div class="modal__title">{{ 'Add a new company' | translate }}</div>
      <i class="icon-close-stroke fs-24 pointer" (click)="closeModal()"></i>
    </div>

    <div class="modal__body">
      <ap-create-client-form (closeModal)="closeModal()" (setClient)="selectClient($event)"></ap-create-client-form>
    </div>
  </div>
</ng-template>

<ap-price-list-sidebar *ngIf="showPriceListSidebar"
                       (closeSidebar)="showPriceListSidebar = false"></ap-price-list-sidebar>
