import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { PlaceholderLoaderConfigInterface } from './interfaces/placeholder-loading-config.interface';

@Component({
  selector: 'ap-ui-placeholder-loading',
  templateUrl: './ui-placeholder-loading.component.html',
  styleUrls: ['./ui-placeholder-loading.component.scss']
})
export class UiPlaceholderLoadingComponent implements OnInit {

  @Input() config!: PlaceholderLoaderConfigInterface;

  @HostBinding('style.padding-bottom') paddingBottom: string | undefined;
  @HostBinding('style.padding-right') paddingRight: string | undefined;
  @HostBinding('style.padding-left') paddingLeft: string | undefined;
  @HostBinding('style.padding-top') paddingTop: string | undefined;
  @HostBinding('style.margin-top') marginTop: string | undefined;

  constructor() { }

  ngOnInit(): void {
    this.paddingBottom = this.config?.padding?.bottom || '0px';
    this.paddingRight = this.config?.padding?.right || '0px';
    this.paddingLeft = this.config?.padding?.left || '0px';
    this.paddingTop = this.config?.padding?.top || '0px';
    this.marginTop = this.config?.margin?.top || '0px';
  }

}
