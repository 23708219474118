export enum TicketTypes {
  GENERAL_REQUEST = 'GENERAL_REQUEST',
  BUG_REPORT = 'BUG_REPORT',
  FEATURE_REQUEST = 'FEATURE_REQUEST'
}

export enum TicketSpecificationsEnum {
  SHIPMENTS = 'SHIPMENTS',
  INVOICES = 'INVOICES',
  PRICE_GROUPS = 'PRICE_GROUPS',
  PRODUCTS = 'PRODUCTS',
  ORDERS = 'ORDERS',
  TEAMS = 'TEAMS',
  USER_SETTINGS = 'USER_SETTINGS',
  PLATFORM_SETTINGS = 'PLATFORM_SETTINGS',
  DASHBOARD = 'DASHBOARD',
  OTHER = 'OTHER'
}

export enum TicketSubSpecificationEnum {
  WRONG_DISCOUNT = 'WRONG_DISCOUNT',
  NO_DISCOUNT = 'NO_DISCOUNT',
  NO_RECEIVED_INVOICE = 'NO_RECEIVED_INVOICE',
  WRONG_COMPANY = 'WRONG_COMPANY',
  DAMAGED = 'DAMAGED',
  LOST = 'LOST',
  DELAYED = 'DELAYED',
  WRONG_ADDRESS = 'WRONG_ADDRESS',
  TRACKING_NOT_UPDATING = 'TRACKING_NOT_UPDATING'
}

export enum TicketStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED'
}

export enum TicketPostServices {
  BRING = 'BRING',
  POSTNORD = 'POSTNORD'
}

export const ticketTypes = [
  { name: 'General Request', value: TicketTypes.GENERAL_REQUEST },
  { name: 'Bug Report', value: TicketTypes.BUG_REPORT },
  { name: 'Feature Request', value: TicketTypes.FEATURE_REQUEST },
];

export const ticketSpecifications = [
  { 
    name: 'Shipments', 
    value: TicketSpecificationsEnum.SHIPMENTS,
    subSpecifications: [
      {
        name: 'Damaged',
        value: TicketSubSpecificationEnum.DAMAGED
      },
      {
        name: 'Lost',
        value: TicketSubSpecificationEnum.LOST
      },
      {
        name: 'Delayed',
        value: TicketSubSpecificationEnum.DELAYED
      },
      {
        name: 'Sent to wrong address',
        value: TicketSubSpecificationEnum.WRONG_ADDRESS
      },
      {
        name: 'Sent, tracking not updating',
        value: TicketSubSpecificationEnum.TRACKING_NOT_UPDATING
      },
    ]
  },
  { 
    name: 'Invoices', 
    value: TicketSpecificationsEnum.INVOICES,
    subSpecifications: [
      {
        name: 'Wrong discount',
        value: TicketSubSpecificationEnum.WRONG_DISCOUNT
      },
      {
        name: 'No discount',
        value: TicketSubSpecificationEnum.NO_DISCOUNT
      },
      {
        name: "Haven't received an invoice",
        value: TicketSubSpecificationEnum.NO_RECEIVED_INVOICE
      },
      {
        name: 'Sent to wrong company',
        value: TicketSubSpecificationEnum.WRONG_COMPANY
      },
    ]
  },
  { name: 'Price Groups', value: TicketSpecificationsEnum.PRICE_GROUPS, subSpecifications: [] },
  { name: 'Products', value: TicketSpecificationsEnum.PRODUCTS, subSpecifications: [] },
  { name: 'Orders', value: TicketSpecificationsEnum.ORDERS, subSpecifications: [] },
  // { name: 'Teams', value: TicketSpecificationsEnum.TEAMS, subSpecifications: [] },
  { name: 'User settings', value: TicketSpecificationsEnum.USER_SETTINGS, subSpecifications: [] },
  { name: 'Platform settings', value: TicketSpecificationsEnum.PLATFORM_SETTINGS, subSpecifications: [] },
  { name: 'Dashboard', value: TicketSpecificationsEnum.DASHBOARD, subSpecifications: [] },
  { name: 'Other', value: TicketSpecificationsEnum.OTHER, subSpecifications: [] }
]

export const ticketPostServices = [
  { name: 'Bring', value: TicketPostServices.BRING },
  { name: 'Postnord', value: TicketPostServices.POSTNORD },
];