import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ap-ui-images-gallery',
  templateUrl: './ui-images-gallery.component.html',
  styleUrls: ['./ui-images-gallery.component.scss']
})
export class UiImagesGalleryComponent implements OnChanges {
  @Input() images: string[] = [];
  @Output() close: EventEmitter<void> = new EventEmitter<void>()
  activeImage!: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images'])  {
      this.activeImage = this.images[0];
    }
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowRight') {
      this.nextImage();
    }

    if (event.key === 'ArrowLeft') {
      this.prevImage();
    }
  }

  prevImage(): void {
    let activeImageIndex = this.images.findIndex(item => item === this.activeImage);
    this.activeImage = activeImageIndex === 0 ? this.images[this.images.length - 1] : this.images[activeImageIndex - 1];
  }

  nextImage(): void {
    let activeImageIndex = this.images.findIndex(item => item === this.activeImage);
    this.activeImage = (activeImageIndex >= this.images.length - 1) ? this.images[0] : this.images[activeImageIndex + 1];
  }

}
