import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Token } from 'src/app/services/auth-service/auth.interface';
import { headers } from 'src/app/shared/helpers/headers.helper';
import { User } from 'src/app/services/user-service/user.interface';

@Injectable({
  providedIn: 'root'
})

export class AuthAppService {
  private url = environment.baseUrl + '/profrakt';
  public isLoggedIn: Observable<boolean | unknown>;
  private _currentUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private _token: BehaviorSubject<Token | null> = new BehaviorSubject<Token | null>(null);

  constructor(private http: HttpClient,
              private router: Router) {
    this.isLoggedIn = combineLatest([this.token, this.currentUser]).pipe(map(([token, user]) => token && user));
  }

  init(): void {    
    if (window.localStorage.getItem('user') && window.localStorage.getItem('token')) {
      let token = JSON.parse(window.localStorage.getItem('token')!);
      let user = JSON.parse(window.localStorage.getItem('user')!);

      if (user && token.access_token) {
        this.setCurrentUser(user);
        this.setToken(token);
        return
      } else {
        this.setCurrentUser(null);
        this.setToken(null!);
      }
    }
  }

  getCompaniesFromBrreg(name: string | null, org_number: string | null) {
    return this.http.get(`https://data.brreg.no/enhetsregisteret/api/enheter?size=20${ name ? '&navn=' + name : '' }${ org_number ? '&organisasjonsnummer=' + org_number : '' }`)
  }

  checkCompanyInDatabase(org_number: number): Observable<{ org_number_exists: boolean }> {
    return this.http.get<{ org_number_exists: boolean }>(`${this.url}/clients/org-number/?value=${ org_number }`, {headers: headers() })
  }

  setToken(token: Token): void {
    token ? localStorage.setItem('token', JSON.stringify(token)) : localStorage.removeItem('token');
    this._token.next(token);
  }

  setCurrentUser(user: User | null): void {
    user ? localStorage.setItem('user', JSON.stringify(user)) : localStorage.removeItem('user');
    this._currentUser.next(user);
  }

  get token(): Observable<Token | null>{
    return this._token.asObservable();
  }

  get currentUser(): Observable<User | null> {
    return this._currentUser.asObservable();
  }
}
