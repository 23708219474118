import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor,HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap, takeWhile, tap } from 'rxjs/operators';

import { ErrorsService } from 'src/app/shared/services/errors.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ResponseStatus } from 'src/app/services/shared/enums/status.enum';
import { CustomerServiceAppService } from 'src/app/modules/customer-service/services/customer-service.app.service';
import { LoadingService } from '../services/loading.service';


export interface ServerError {
  data: {
    details: { details: string }
    type: 'auth' | 'expired' | string,
  },
  error_type: "access" | "existence",
  status : ResponseStatus
}
@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  isRefreshTokenInprogress: boolean = false;
  constructor(private authService: AuthService,
              private errorsService: ErrorsService,
              private toastr: ToastrService,
              private customerAppService: CustomerServiceAppService,
              private loadingService: LoadingService) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // for test empty auth token requests
    if (request.headers.get('Authorization')?.includes('undefined')) {
      return EMPTY;
    }
    // for test empty auth token requests

    if (request.url.includes('auth') ||
        request.url.includes('translate') || 
        request.url.includes('graphql') ||
        request.url.includes('brreg') || 
        request.url.includes('https://edi') ||
        (!request.url.includes('auth') && 
        !request.url.includes('translate') && 
        !request.url.includes('graphql') &&
        !request.url.includes('brreg') && 
        !request.url.includes('https://edi') &&
        request.headers.get('Authorization'))) {
      return next.handle(request).pipe(
        catchError((requestError) => {
          let error_data: ServerError = requestError.error;
  
          if (error_data && error_data.error_type! === 'access' && (error_data.data?.type === 'expired') && !request.url.includes('refresh') && !this.isRefreshTokenInprogress) {
            this.isRefreshTokenInprogress = true;
            return this.authService.refreshToken().pipe(
              switchMap((res) => {
                
                let newReq = request.clone({
                  setHeaders: {
                    Authorization: 'Bearer ' + res.data?.access_token,
                  },
                })
  
                // temporary solution, need to check on prod without this code 22.05.2024
                // location.reload();
                // temporary solution, need to check on prod without this code 22.05.2024
  
                return next.handle(newReq)
              }),
              tap(() => {
                this.isRefreshTokenInprogress = false;
                this.loadingService.setIsLoading(false);
              }), 
              catchError((err: HttpErrorResponse) => {
                if (err.error.data.details === 'the refresh token is not registered') {
                  this.authService.clearLocalStorageAndIndexedDB();
                  this.loadingService.setIsLoading(false);
                }
                return throwError(() => requestError)
              })
            )
          } 
  
          if (error_data && error_data.error_type === 'access' && (error_data.data?.type === 'expired') && !request.url.includes('refresh') && !this.isRefreshTokenInprogress) {
            this.authService.clearLocalStorageAndIndexedDB();
            this.loadingService.setIsLoading(false);
          }
          
          if ((error_data && error_data.data?.type === 'auth' || error_data && error_data.data?.type === 'expired') && 
              error_data && error_data.error_type === 'access' && 
              request.url.includes('refresh')) {
            this.authService.clearLocalStorageAndIndexedDB();
            this.errorsService.setAuthError(null);
            return EMPTY;
          } 
  
          if (requestError.status === 418) {
            this.errorsService.setAuthError(null);
          }
  
          if (requestError.status === 400 && requestError.error.message && 
              requestError.error.message === 'Credit monitoring not enabled.') {
            let lang = localStorage.getItem('lang') || 'no';
            this.toastr.info(lang === 'no' ? 'Kredittovervåking ikke aktivert' : requestError.error.message)
          }
          
          if (requestError?.error?.message && 
              requestError?.error?.message !== 'Credit monitoring not enabled.' && 
              requestError?.error?.message !== 'You already exported some files') {
            if (requestError?.status !== 401 || requestError?.error?.message?.password) {
              this.toastr.error("Something went wrong, click to inform us.", undefined, {
                disableTimeOut: false,
                timeOut: 3000,
              }).onTap.subscribe(() => {
                this.customerAppService.onToggleTicketForm({ toOpen: true, specification: 'OTHER' })
              })
            } else {
              this.toastr.error("Something went wrong, click to inform us.", undefined, {
                disableTimeOut: false,
                timeOut: 3000,
              }).onTap.subscribe(() => {
                this.customerAppService.onToggleTicketForm({ toOpen: true, specification: 'OTHER' })
              })
            }
          }

          if (requestError.status === 422 || (requestError.status === 418 && error_data.error_type === 'existence')) {
            if (requestError.error.data.details[0].msg === 'Store name should contain uppercase and lowercase letters only' ||
              requestError.error.data.details === 'This store name is not available. Please try another') {
              this.errorsService.setIntegrationError(requestError)
              return EMPTY
            }
            return throwError(() => requestError);
          }

          if (requestError.status === 422 || (requestError.status === 418 && error_data.error_type === 'existence')) {
            return throwError(() => requestError);
          }
  
          if (requestError.status === 401) {
            let newRequest = request.clone({
              setHeaders: {
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                "X-Requested-With": '*',
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with',
                'Access-Control-Expose-Headers': '*',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.access_token,
                'F-Token': (Math.floor(Math.random() * 10000) + 1).toString()
              },
            })
            
            return next.handle(newRequest)
          }
  
          return throwError(() => requestError);
        })
      );
    }
    
    this.authService.clearLocalStorageAndIndexedDB();
    return EMPTY
  }
}
