import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { fileHeaders, headers } from '../../shared/helpers/headers.helper';
import { EMPTY, Observable } from 'rxjs';
import {
  User,
  ProfileResponseInterface,
  ProfilesResponseInterface,
  UpdateProfileAvatarResponse,
  TeamMembersResponseInterface, TeamMemberCompaniesResponseInterface
} from './user.interface';
import { DeleteProfilePayloadInterface } from 'src/app/modules/manage-profile/interfaces/delete-profile-payload.interface';
import { DefaultResponse } from '../shared/interfaces/responses.interface';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private url = environment.baseUrl + '/user';

  constructor(private http: HttpClient) {}

  getProfileInfo(): Observable<ProfileResponseInterface> {
    return this.http.get<ProfileResponseInterface>(`${ this.url }/profile`, { headers: headers() })
  }

  getProfileInfoByID(id: number): Observable<ProfileResponseInterface> {
    return this.http.get<ProfileResponseInterface>(`${ this.url }/profiles/${ id }`, { headers: headers() })
  }

  changeProfileInfo(body: User): Observable<ProfileResponseInterface> {
    return this.http.patch<ProfileResponseInterface>(`${ this.url }/profile`, body, { headers: headers() })
  }

  getProfiles(limit: number, page: number, email: string): Observable<ProfilesResponseInterface> {
    return this.http.get<ProfilesResponseInterface>(`${ this.url }/profiles?page=${ page}&limit=${ limit }${ email ? '&email=' + email.toLowerCase().replace('+', '%2B') : ''}`,{  headers: headers() })
  }

  updateProfileAvatar(body: FormData): Observable<UpdateProfileAvatarResponse> {
    return this.http.post<UpdateProfileAvatarResponse>(`${ this.url }/profile/avatar`, body, { headers: fileHeaders() })
  }

  updateUserRole(body: { user_id: number, service_id: number, role_id: number }): Observable<DefaultResponse> {
    return this.http.put<DefaultResponse>(`${ this.url }/role`, body, { headers: headers() })
  }

  deleteProfile(): Observable<null> {
    return this.http.delete<null>(`${this.url}/profile`, { headers: headers() })
  }

  getMembersProfiles(): Observable<TeamMembersResponseInterface> {
    return this.http.get<TeamMembersResponseInterface>(`${ this.url }/members`, {headers: headers()})
  }

  linkCompaniesToMember(memberID: number, body: {company_ids: number[]}): Observable<DefaultResponse> {
    return this.http.patch<DefaultResponse>(`${ this.url }/members/${memberID}/companies/link`, body, {headers: headers()})
  }

  unlinkCompanyFromMember(memberID: number, company_id: number): Observable<DefaultResponse> {
    return this.http.patch<DefaultResponse>(`${ this.url }/members/${memberID}/${company_id}/unlink`,  null,{headers: headers()})
  }

  getMembersCompanies(): Observable<TeamMemberCompaniesResponseInterface> {
    return this.http.get<TeamMemberCompaniesResponseInterface>(`${ this.url }/member/companies`,{headers: headers()})
  }
}
