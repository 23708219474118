import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoggedGuardService  {

  constructor(private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    let value!: boolean;
    let token = JSON.parse(window.localStorage.getItem('token')!);
    let user = JSON.parse(window.localStorage.getItem('user')!);

    if (token && user) {
      value = false;
      this.router.navigate(['/shipments']);        
    } else {
      value = true;
    }

    return value;
  }
}
