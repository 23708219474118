import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerSupportTicketSettings, TicketInterface } from '../interfaces/ticket.interface';
import { User } from 'src/app/services/user-service/user.interface';

@Injectable({
  providedIn: 'root'
})

export class CustomerServiceAppService {
  customerSupportTicketDefaultSettings: CustomerSupportTicketSettings = { toOpen: false };
  _showTicketForm: BehaviorSubject<CustomerSupportTicketSettings> = new BehaviorSubject<CustomerSupportTicketSettings>(this.customerSupportTicketDefaultSettings);
  _activeTicket: BehaviorSubject<TicketInterface | null> = new BehaviorSubject<TicketInterface | null>(null);
  _activeTicketUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  _activeTicketComments: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _resetTicketsList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  showTicketForm(): Observable<CustomerSupportTicketSettings> {
    return this._showTicketForm.asObservable()
  }

  onToggleTicketForm(value: CustomerSupportTicketSettings): void {
    this._showTicketForm.next(value)
  }

  activeTicket(): Observable<TicketInterface | null> {
    return this._activeTicket.asObservable();
  }

  setActiveTicket(ticket: TicketInterface | null): void {
    this._activeTicket.next(ticket);
  }

  activeTicketUser(): Observable<any> {
    return this._activeTicketUser.asObservable();
  }

  setActiveTicketUser(user: User | null): void {
    this._activeTicketUser.next(user);
  }

  activeTicketComments(): Observable<any> {
    return this._activeTicketComments.asObservable()
  }

  setActiveTicketComments(comments: any): void {
    this._activeTicketComments.next(comments);
  }

  toResetTicketsList(value: boolean): void {
    this._resetTicketsList.next(value);
  }
}
