import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ediHeaders, headers, xuserdataHeaders } from '../../shared/helpers/headers.helper';
import { EdiPrinterInterface } from '../../modules/shipments/interfaces/edi-printer.interface';
import {
  AllPrintersResponseInterface
} from '../../modules/superadmin/manage-printers/interfaces/all-printers-response.interface';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {
  private url = environment.baseUrl + '/profrakt';
  private EDIUrl = environment.baseEDIUrl;
  local = 'http://127.0.0.1:8000';

  constructor(private http: HttpClient) {}

  getEDIPrinters(clientID: string | number): Observable<EdiPrinterInterface[]> {
    return this.http.get<EdiPrinterInterface[]>(`${this.url}/client/${clientID}/edi-printers/`, { headers: headers()})
  }

  linkPrinter(apiKey: string, body: {printerId: string}): Observable<{requestId: string}> {
    return this.http.post<{requestId: string}>(`${this.EDIUrl}/printers/link`, body, { headers: ediHeaders(apiKey)})
  }

  confirmLinkPrinter(apiKey: string, body: any): Observable<{id: number}> {
    return this.http.post<{id: number}>(`${this.EDIUrl}/printers/confirm`, body, { headers: ediHeaders(apiKey)})
  }

  editPrinter(apiKey: string, body: any, printerID: number): Observable<null> {
    return this.http.put<null>(`${this.EDIUrl}/printers/${printerID}`, body, { headers: ediHeaders(apiKey)})
  }

  deletePrinter(apiKey: string, printerID: number): Observable<null> {
    return this.http.delete<null>(`${this.EDIUrl}/printers/${printerID}`, { headers: ediHeaders(apiKey)})
  }

  printTestLabel(apiKey: string, printerID: number): Observable<null> {
    return this.http.post<null>(`${this.EDIUrl}/printers/${printerID}/test`, null,{ headers: ediHeaders(apiKey)})
  }

  calibratePrinter(apiKey: string, printerID: number): Observable<null> {
    return this.http.post<null>(`${this.EDIUrl}/printers/${printerID}/calibrate`, null,{ headers: ediHeaders(apiKey)})
  }

  getAllAvailablePrinters(page: number, page_size: number, query?: string): Observable<AllPrintersResponseInterface> {
    return this.http.get<AllPrintersResponseInterface>(`${this.url}/printers?page=${page}&page_size=${page_size}${query ? ('&query='+query) : ''}`, { headers: headers()})
  }

  linkClientsToPrinter(printerSerialNumber: string, body: {apiKeys: string[]}): Observable<null> {
    return this.http.put<null>(`${this.EDIUrl}/superuser/printers/${printerSerialNumber}`, body, { headers: headers()})
  }

  deletePrinterFromSystem(printerSerialNumber: string): Observable<null> {
    return this.http.delete<null>(`${this.EDIUrl}/superuser/printers/${printerSerialNumber}`, { headers: headers()})
  }
}
