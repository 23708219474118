import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'ap-ui-filters',
  templateUrl: './ui-filters.component.html',
  styleUrls: ['./ui-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiFiltersComponent implements OnInit, OnDestroy {

  @Input() placeholder = ''
  @Input() set searchValue(value: string) {
    this.searchFormControl.setValue(value)
  }
  @Input() filtersArray: {name: string, value: string | number, label: string}[] = []
  @Input() addQuickStatusFilter: boolean = false
  @Input() quickStatusFilterTitle: string = ''
  @Input() statuses: {databaseName: string, color: string, name: string}[] = []
  @Input() hideFiltersButton: boolean = false
  @Input() set selectedStatuses(statuses: string[] | null) {
    this.activeStatuses = []
    statuses?.forEach(status => {
      this.activeStatuses.push(this.statuses.find(s => s.databaseName === status)!)
    })
  }

  @Output() searchValueChanged: EventEmitter<string> = new EventEmitter<string>()
  @Output() openFilters: EventEmitter<void> = new EventEmitter<void>()
  @Output() clearFilterEmitter: EventEmitter<{name: string, value: string | number, label: string}>
    = new EventEmitter<{name: string, value: string | number, label: string}>()
  @Output() statusesEmitter: EventEmitter<{databaseName: string, color: string, name: string}[]>
    = new EventEmitter<{databaseName: string, color: string, name: string}[]>()

  searchFormControl: FormControl<string> = this.fb.control('');
  isQuickFilterShowed = false;
  activeStatuses: {databaseName: string, color: string, name: string}[] = []

  private subscription$: Subscription = new Subscription();

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.searchValueSubscription()
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe()
  }

  clearFilter(filterIndexToClear: number): void {
    this.clearFilterEmitter.emit(this.filtersArray[filterIndexToClear])
    this.filtersArray.splice(filterIndexToClear, 1)
  }

  selectStatus(status: { databaseName: string; color: string; name: string }): void {
    const isStatusSelected = !!this.activeStatuses.find(s => s.databaseName === status.databaseName)
    if (isStatusSelected) {
      this.activeStatuses = [...this.activeStatuses].filter(s => s.databaseName !== status.databaseName)
    } else {
      this.activeStatuses.push(status)
    }
  }

  isStatusAlreadySelected(status: { databaseName: string; color: string; name: string }): boolean {
    return !!this.activeStatuses.find(s => s.databaseName === status.databaseName)
  }

  applyStatuses(): void {
    this.statusesEmitter.emit(this.activeStatuses)
    this.isQuickFilterShowed = false
  }

  private searchValueSubscription(): void {
    this.subscription$.add(
      this.searchFormControl.valueChanges.pipe(debounceTime(600)).subscribe(res => {
        this.searchValueChanged.emit(res);
      })
    )
  }
}
