import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthAppService } from '../../modules/auth/services/auth.app.service';
import { ClientAppService } from '../services/client.app.service';
import { User } from 'src/app/services/user-service/user.interface';

@Injectable({
  providedIn: 'root'
})

export class WelcomePageGuardService  {

  constructor(private router: Router,
              private authAppService: AuthAppService,
              private clientAppService: ClientAppService) {}

  canActivate(): Observable<boolean> | boolean {
    let value!: boolean;
    const user: User = JSON.parse(localStorage.getItem('user')!);
    const clients = JSON.parse(localStorage.getItem('clients')!);

    if (user && !(user.first_name && user.last_name) || !clients?.length || clients.length == 1 && !clients[0]?.is_onboarded) {
      value = true
    } else {
      value = false;
      this.router.navigate(['/shipments']);
    }

    return value;
  }
}
