export const environment = {
  production: false,
  stage: false,
  baseUrl: 'https://gateway-service-development-oceo3aw2bq-ez.a.run.app',
  baseEDIUrl: 'https://edi-stage-de777ts5.ew.gateway.dev',
  baseAppUrl: 'https://dev.adminpanel.no',
  firebaseConfig: {
    apiKey: "AIzaSyAkuxh_j8xpXcwFQx6tyWsXbzNCQlg6xxI",
    authDomain: "oriented-e-commerce",
    projectId: "oriented-e-commerce",
    storageBucket: "oriented-e-commerce.appspot.com",
    messagingSenderId: "644947534454",
    appId: "1:644947534454:web:4728639a055a78043ab557",
    measurementId: "G-C29EN196XM"
  }
};
