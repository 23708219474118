import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { PlaceholderLoaderElementInterface } from '../../interfaces/placeholder-loader-element.interface';

@Component({
  selector: 'ap-ui-circle',
  templateUrl: './ui-circle.component.html',
  styleUrls: ['./ui-circle.component.scss']
})
export class UiCircleComponent implements OnInit {

  @Input() circle!: Partial<PlaceholderLoaderElementInterface>;

  @HostBinding('style.height') height: string | undefined;
  @HostBinding('style.width') width: string | undefined;

  ngOnInit(): void {
    this.height = this.circle.diameter;
    this.width = this.circle.diameter;
  }

}
