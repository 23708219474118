import { TableItemInterface } from '../ui/ui-table';

export const testTableItemsConst: TableItemInterface[] = [
  {
    name: 'id',
    width: '150px',
    databaseName: 'id',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'deliveryAddress2',
    width: '150px',
    databaseName: 'deliveryAddress2',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 0
  },
  {
    name: 'deliveryAddressPostCode',
    width: '150px',
    databaseName: 'deliveryAddressPostCode',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 1
  },
  {
    name: 'placeOfDeliveryTown',
    width: '150px',
    databaseName: 'placeOfDeliveryTown',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 2
  },
  {
    name: 'priceZone',
    width: '150px',
    databaseName: 'priceZone',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 3
  },
  {
    name: 'product',
    width: '150px',
    databaseName: 'product',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 4
  },
  {
    name: 'productCode',
    width: '150px',
    databaseName: 'productCode',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 5
  },
  {
    name: 'productCodeMainBook',
    width: '150px',
    databaseName: 'productCodeMainBook',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 6
  },
  {
    name: 'receivedDate',
    width: '150px',
    databaseName: 'receivedDate',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 7
  },
  {
    name: 'recipientAddress',
    width: '150px',
    databaseName: 'recipientAddress',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 8
  },
  {
    name: 'recipientAddress2',
    width: '150px',
    databaseName: 'recipientAddress2',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 9
  },
  {
    name: 'recipientName',
    width: '150px',
    databaseName: 'recipientName',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 10
  },
  {
    name: 'recipientName2',
    width: '150px',
    databaseName: 'recipientName2',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 11
  },
  {
    name: 'recipientReference',
    width: '150px',
    databaseName: 'recipientReference',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 12
  },
  {
    name: 'returned',
    width: '150px',
    databaseName: 'returned',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 13
  },
  {
    name: 'senderCity',
    width: '150px',
    databaseName: 'senderCity',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 14
  },
  {
    name: 'senderPostalCode',
    width: '150px',
    databaseName: 'senderPostalCode',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 15
  },
  {
    name: 'senderReference',
    width: '150px',
    databaseName: 'senderReference',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 16
  },
  {
    name: 'sendersAddress1',
    width: '150px',
    databaseName: 'sendersAddress1',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 17
  },
  {
    name: 'sendersAddress2',
    width: '150px',
    databaseName: 'sendersAddress2',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 18
  },
  {
    name: 'vasCode',
    width: '150px',
    databaseName: 'vasCode',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 19
  },
  {
    name: 'vasDescription',
    width: '150px',
    databaseName: 'vasDescription',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 20
  },
  {
    name: 'weightKg',
    width: '150px',
    databaseName: 'weightKg',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 21
  },
  {
    name: 'clientPrice',
    width: '150px',
    databaseName: 'clientPrice',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 22
  },
  {
    name: 'invoiced',
    width: '150px',
    databaseName: 'invoiced',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 23
  },
  {
    name: 'type',
    width: '150px',
    databaseName: 'type',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 24
  },
  {
    name: 'articleNumber',
    width: '150px',
    databaseName: 'articleNumber',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 25
  },
  {
    name: 'description',
    width: '150px',
    databaseName: 'description',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 26
  },
  {
    name: 'sendToPostalCode',
    width: '150px',
    databaseName: 'sendToPostalCode',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 27
  },
  {
    name: 'volumeWeightKg',
    width: '150px',
    databaseName: 'volumeWeightKg',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 28
  },
  {
    name: 'resellerPrice',
    width: '150px',
    databaseName: 'resellerPrice',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 29
  },
  {
    name: 'packageInfo',
    width: '150px',
    databaseName: 'packageInfo',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 30
  },
  {
    name: 'service',
    width: '150px',
    databaseName: 'service',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 31
  },
  {
    name: 'consignmentPdf',
    width: '150px',
    databaseName: 'consignmentPdf',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'historicalClientPrice',
    width: '150px',
    databaseName: 'historicalClientPrice',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'historicalListPrice',
    width: '150px',
    databaseName: 'historicalListPrice',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'productOptionId',
    width: '150px',
    databaseName: 'productOptionId',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'consignmentId',
    width: '150px',
    databaseName: 'consignmentId',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'senderId',
    width: '150px',
    databaseName: 'senderId',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'vismaInvoiceNumber',
    width: '150px',
    databaseName: 'vismaInvoiceNumber',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'vismaInvoiceStatus',
    width: '150px',
    databaseName: 'vismaInvoiceStatus',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'clientId',
    width: '150px',
    databaseName: 'clientId',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'vismaPrice',
    width: '150px',
    databaseName: 'vismaPrice',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'managershipId',
    width: '150px',
    databaseName: 'managershipId',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'isHidden',
    width: '150px',
    databaseName: 'isHidden',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'parentId',
    width: '150px',
    databaseName: 'parentId',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'consignmentData',
    width: '150px',
    databaseName: 'consignmentData',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'pushedToVisma',
    width: '150px',
    databaseName: 'pushedToVisma',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
  {
    name: 'pushedToVismaDatetime',
    width: '150px',
    databaseName: 'pushedToVismaDatetime',
    type: 'info',
    isSortable: false,
    sortOptions: [],
    orderNumber: 32
  },
]
