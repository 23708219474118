import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthAppService } from '../services/auth.app.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService  {

  constructor(private router: Router,
              private authAppService: AuthAppService) {}

  canActivate(): Observable<boolean> | boolean {
    let value!: boolean;
    this.authAppService.isLoggedIn.subscribe(res => {
      if (res) {
        value = true;
      } else {
        value = false;
        this.router.navigate(['auth/login']);
      }
    })

    return value;
  }
}
