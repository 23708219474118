import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';

import { UiTableComponent } from './ui-table.component';
import { UiTableListComponent } from './components/ui-table-list/ui-table-list.component';
import { UiTableCellComponent } from './components/ui-table-cell/ui-table-cell.component';
import { SharedModule } from '../../../shared.module';
import { ResizeColumnDirective } from './directives/resize-column.directive';

@NgModule({
  declarations: [
    UiTableComponent,
    UiTableListComponent,
    UiTableCellComponent,
    ResizeColumnDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PaginationModule,
    NgSelectModule,
    SharedModule
  ],
  exports: [
    UiTableComponent
  ]
})
export class UiTableModule {}
