import { Component, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AdminpanelTranslateService } from 'src/app/services/translate-service/translate.service';
import { TranslateSectionsEnum } from 'src/app/modules/superadmin/manage-translates/enums/translates-section.enum';
import { IndexedDbTranslateResponseInterface } from '../../interfaces/indexeddb-translate-response.interface';

@Component({
  selector: 'app-language-toggle',
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageToggleComponent {
  lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'no' ;
  translates!: IndexedDbTranslateResponseInterface;

  constructor(private translate: AdminpanelTranslateService,
              private translateService: TranslateService) {
    this.translate.getTranslationByService(TranslateSectionsEnum.APP)
  }

  changeLanguage(value: 'en' | 'no') {
    this.lang = value;
    this.translateService.use(value);
    localStorage.setItem('lang', value);
  }
}