import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuardService } from './modules/auth/guards/admin.guard';
import { AuthGuardService } from './modules/auth/guards/auth.guard';
import { LoggedGuardService } from './modules/auth/guards/logged.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { WelcomePageComponent } from './shared/components/welcome-page/welcome-page.component';
import { WelcomePageGuardService } from './shared/guards/welcome-page.guard';
import { ShipmentsGuard } from './shared/guards/shipments.guard';
import { InvoicesGuard } from './shared/guards/invoices.guard';
import { AdminAndCustomerSupportGuardService } from './modules/auth/guards/customer-support.guard';
import { TeamMemberGuard } from './shared/guards/team-member.guard';
import { AdminCustomerSupportResellerGuardService } from './shared/guards/admin-cs-reseller.guard';
import { FileImportFormComponent } from './shared/components/file-import-form/file-import-form.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    component: WelcomePageComponent,
    canActivate: [
      AuthGuardService,
      WelcomePageGuardService,
    ],
    data: {
      mainCategory: {title: 'Welcome', route: '/'},
      breadcrumbs: []
    },
    title: "Welcome page"
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [LoggedGuardService]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService, AdminCustomerSupportResellerGuardService]
  },
  {
    path: 'shipments',
    loadChildren: () => import('./modules/shipments/shipments.module').then(m => m.ShipmentsModule),
    canActivate: [AuthGuardService, ShipmentsGuard],
  },
  {
    path: 'contact-book',
    loadChildren: () => import('./modules/contact-book/contact-book.module').then(m => m.ContactBookModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'visma-invoices',
    loadChildren: () => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule),
    canLoad: [InvoicesGuard],
    canActivate: [AuthGuardService]
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'customer-service',
    loadChildren: () => import('./modules/customer-service/customer-service.module').then(m => m.CustomerServiceModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'legals',
    loadChildren: () => import('./modules/legals/legals.module').then(m => m.LegalsModule)
  },
  {
    path: 'sales-orders',
    loadChildren: () => import('./modules/ecommerce/sales-orders/sales-orders.module').then(m => m.SalesOrdersModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/ecommerce/products/products.module').then(m => m.ProductsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'manage-profile',
    loadChildren: () => import('./modules/manage-profile/manage-profile.module').then(m => m.ManageProfileModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'price-groups',
    loadChildren: () => import('./modules/superadmin/price-groups/price-groups.module').then(m => m.PriceGroupsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'manage-users',
    loadChildren: () => import('./modules/superadmin/manage-users/manage-users.module').then(m => m.ManageUsersModule),
    canActivate: [AuthGuardService, AdminAndCustomerSupportGuardService]
  },
  {
    path: 'manage-clients',
    loadChildren: () => import('./modules/superadmin/manage-clients/manage-clients.module').then(m => m.ManageClientsModule),
    canActivate: [AuthGuardService, AdminAndCustomerSupportGuardService]
  },
  {
    path: 'manage-permissions',
    loadChildren: () => import('./modules/superadmin/manage-permissions/manage-permissions.module').then(m => m.ManagePermissionsModule),
    canActivate: [AuthGuardService, AdminAndCustomerSupportGuardService]
  },
  {
    path: 'manage-printers',
    loadChildren: () => import('./modules/superadmin/manage-printers/manage-printers.module').then(m => m.ManagePrintersModule),
    canActivate: [AuthGuardService, AdminGuardService]
  },
  {
    path: 'manage-translates',
    loadChildren: () => import('./modules/superadmin/manage-translates/manage-translates.module').then(m => m.ManageTranslatesModule),
    canActivate: [AuthGuardService, AdminGuardService]
  },
  {
    path: 'item-inventory',
    loadChildren: () => import('./modules/warehousing/item-inventory/item-inventory.module').then(m => m.ItemInventoryModule),
    canActivate: [AuthGuardService, AdminGuardService]
  },
  {
    path: 'item-movements',
    loadChildren: () => import('./modules/warehousing/item-movements/item-movements.module').then(m => m.ItemMovementsModule),
    canActivate: [AuthGuardService, TeamMemberGuard]
  },
  {
    path: 'purchase-orders',
    loadChildren: () => import('./modules/ecommerce/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule),
    canActivate: [AuthGuardService, AdminGuardService]
  },
  {
    path: 'integrations',
    loadChildren: () => import('./modules/integrations/integrations.module').then(m => m.IntegrationsModule),
    canActivate: [AuthGuardService, AdminGuardService]
  },
  {
    path: 'file-import',
    component: FileImportFormComponent,
    canActivate: [AuthGuardService],
    data: {
      mainCategory: '',
      breadcrumbs: []
    },
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'page-not-found'
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  // <!-- Hide old ivoices section 21.06.2024. Will remove in future -->
  // {
  //   path: 'invoices',
  //   loadChildren: () => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule),
  //   canLoad: [InvoicesGuard],
  //   canActivate: [AuthGuardService]
  // },
  // <!-- Hide old ivoices section 21.06.2024. Will remove in future -->
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
