<div class="modal__container">
  <div class="modal__header modal__header--border">
    <div class="modal__title">
      {{ 'Image gallery' | translate }}<ng-container *ngIf="additionalTitle">: {{ additionalTitle | translate }}</ng-container>
    </div>
    <i class="icon-close-stroke fs-24 pointer" (click)="close.emit()"></i>
  </div>
  <div class="modal__body">
    <div class="images-gallery" *ngIf="images.length">
      <ng-container *ngIf="!singleImage">
        <div class="images-gallery__preview-section">
          <div class="images-gallery__preview-container"
               *ngFor="let image of images"
               [class.images-gallery__preview-container--active]="activeImage === image"
               (click)="activeImage = image">
            <img [src]="image" alt="Image">
          </div>
        </div>
      </ng-container>
      <div class="images-gallery__active-image" *ngIf="activeImage"
           [class.images-gallery__active-image--full-width]="singleImage">
        <img [src]="activeImage" alt="Active image">
        <ng-container *ngIf="!singleImage">
          <div class="images-gallery__active-image-left" (click)="prevImage()">
            <i class="icon-arrow-left-stroke fs-24"></i>
          </div>
          <div class="images-gallery__active-image-right" (click)="nextImage()">
            <i class="icon-arrow-right-stroke fs-24"></i>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="additionalInfo">
      <div class="mt--16 fw-500" [innerHTML]="additionalInfo"></div>
    </ng-container>
  </div>
  <div class="modal__footer modal__footer--border">
    <button class="button button--height-40" (click)="close.emit()">Close</button>
  </div>
</div>
