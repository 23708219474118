import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { debounceTime, EMPTY, mergeMap, Subscription, tap } from 'rxjs';

import { emailMask, urlMask } from '../../helpers/mask.helper';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { AuthAppService } from 'src/app/modules/auth/services/auth.app.service';
import { Token } from 'src/app/services/auth-service/auth.interface';
import { ClientService } from 'src/app/services/client-service/client.service';
import { ClientInterface } from 'src/app/modules/superadmin/manage-clients/interfaces/client.interface';
import { ClientAppService } from '../../services/client.app.service';
import { CustomerServiceAppService } from 'src/app/modules/customer-service/services/customer-service.app.service';


@Component({
  selector: 'ap-create-client-form',
  templateUrl: './create-client-form.component.html',
  styleUrls: ['./create-client-form.component.scss']
})
export class CreateClientFormComponent implements OnInit, OnDestroy {
  private subscriptions$ = new Subscription();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setClient: EventEmitter<ClientInterface> = new EventEmitter<ClientInterface>();
  companyForm!: FormGroup;
  orgNumberError: boolean = false;
  autocompleteResults: any[] = [];
  selectedCompany!: any;
  loading: boolean = false;
  alreadyExistError: boolean = false;
  submitted: boolean = false;
  token: Token = JSON.parse(localStorage.getItem('token')!);
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  successState: boolean = false;
  
  constructor(private toastr: ToastrService,
              private fb: FormBuilder,
              private authAppService: AuthAppService,
              private router: Router,
              private clientService: ClientService,
              private clientAppService: ClientAppService,
              private customerServiceAppService: CustomerServiceAppService) { }

  ngOnInit(): void {
    this.buildCompanyForm();
  }

  buildCompanyForm(): void {
    this.companyForm = this.fb.group({
      name_org_number_check: [''],
      enterprise_name: ['', Validators.required],
      webshop_url: [''],
      org_number: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(emailMask)]],
      phone_number: ['', Validators.required],
      address: ['', Validators.required],
      postal_code: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
    })

    this.subscriptions$.add(
      this.companyForm.get('webshop_url')?.valueChanges.subscribe(res => {
        if (res?.length) {
          this.companyForm.get('webshop_url')?.setValidators([Validators.pattern(urlMask)])
        } else {
          this.companyForm.get('webshop_url')?.setValidators([]);
        }
      })
    )

    this.subscriptions$.add(
      this.companyForm.get('name_org_number_check')?.valueChanges.pipe(
        debounceTime(500),
        mergeMap(value => {
          this.orgNumberError = false;
          this.alreadyExistError = false;
          this.selectedCompany = null;

          if (!isNaN(value) && value.length !== 9) {
            this.orgNumberError = true;
            this.autocompleteResults = [];
            return EMPTY
          }

          if (value.length || !isNaN(value) && value.length === 9) {
            if (isNaN(value)) {
              return this.authAppService.getCompaniesFromBrreg(value, null)
            }

            return this.authAppService.getCompaniesFromBrreg(null, value)
          } else {
            this.autocompleteResults = [];
            return EMPTY
          }
        })
      ).subscribe((res: any) => {
        if (res && res._embedded) {
          this.autocompleteResults = res._embedded?.enheter;
        }
      })
    )
  }

  checkCompanyInDatabase(company: any): void {
    this.authAppService.checkCompanyInDatabase(company.organisasjonsnummer).subscribe((res: { org_number_exists: boolean }) => {
      if (res && !res.org_number_exists) {
        this.autocompleteResults = [];
        this.selectedCompany = company;
        this.companyForm.get('enterprise_name')!.setValue(company.navn);
        this.companyForm.get('org_number')!.setValue(company.organisasjonsnummer);
        this.companyForm.get('address')!.setValue(company.postadresse?.adresse![0] ? company.postadresse?.adresse![0] : company.forretningsadresse?.adresse![0]);
        this.companyForm.get('postal_code')!.setValue(company.postadresse?.postnummer ? company.postadresse?.postnummer : company.forretningsadresse?.postnummer);
        this.companyForm.get('city')!.setValue(company.postadresse?.poststed ? company.postadresse?.poststed : company.forretningsadresse?.poststed);
        this.companyForm.get('country')!.setValue(company.postadresse?.landkode ? company.postadresse?.landkode : company.forretningsadresse?.landkode);
        this.companyForm.get('org_number')!.disable();
        this.companyForm.updateValueAndValidity();
        this.alreadyExistError = false;
        this.orgNumberError = false;
      } else {
        this.autocompleteResults = [];
        this.alreadyExistError = true;
      }
    })
  }

  registerCompany(): void {
    this.submitted = true;

    if (this.companyForm.invalid) {
      return
    }
    this.loading = true;
    let companyValue = this.companyForm.getRawValue();
    let webshop_url = this.companyForm.get('webshop_url')?.value;

    if (this.companyForm.get('webshop_url')?.value && !this.companyForm.get('webshop_url')?.value.includes('http')) {
      webshop_url = 'https://' + this.companyForm.get('webshop_url')?.value;
    }

    let data = {
      name: companyValue.enterprise_name,
      org_number: companyValue.org_number,
      email: companyValue.email,
      webshop_url,
      phone_number: companyValue.phone_number.e164Number,
      address: [{
        address_line1: companyValue.address,
        postal_code: companyValue.postal_code,
        city: companyValue.city,
        country: companyValue.country
      }],
      registered_from: 3
    };

    if (!this.companyForm.get('webshop_url')!.value) {
      delete data['webshop_url']
    }

    this.subscriptions$.add(
      this.clientService.registerClient(data).pipe(
        tap(() => this.clientAppService.updateClientList(true)),
        mergeMap(() => {
          return this.clientAppService.allClients()
        }),
        debounceTime(1000)
      ).subscribe((res: ClientInterface[]) => {
        let clientToSetup = res.find((client: ClientInterface) => client?.org_number === data.org_number);
        this.setClient.emit(clientToSetup);
        this.closeModal.emit(true);
        this.successState = true;
      }))
  }

  openTicketForm():void {
    this.customerServiceAppService.onToggleTicketForm({ 
      toOpen: true,
      specification: 'OTHER',
      subject: localStorage.getItem('lang') === 'no' ? "Finner du ikke bedriften din?" : "Can't find my company in the list",
    })
    this.closeModal.emit(true);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe()
  }
}
