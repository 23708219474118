import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

@Pipe({name: 'adminpanelCustomTime'})
export class AdminpanelCustomTimePipe implements PipeTransform {
  dayjs = dayjs;
  constructor() { 
    dayjs.extend(duration)
  }

  transform(time: string): string {
    let now = new Date();
    let activity = dayjs(time);
    let duration = dayjs.duration(activity.diff(now));
    let hours = -duration.asHours();    
    let message;
    let lang = localStorage.getItem('lang') || 'no';
    if (lang && lang === 'no' || !lang) {
      message = hours < 1 ? 'Mindre 1 time' : hours > 1 && hours < 2 ? '1 time siden' : hours < 24 ? Math.floor(hours) + ' timer siden' : time ? dayjs(time).format('DD.MM.YYYY') : '-'
    } else {
      message = hours < 1 ? 'Less 1 hour' : hours > 1 && hours < 2 ? '1 hour ago' : hours < 24 ? Math.floor(hours) + ' hours ago' : time ? dayjs(time).format('DD.MM.YYYY') : '-' 
    }
    return message;
  }
}